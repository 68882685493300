
export enum TabsName {
  SharedProjects = 'shared-projects',
  MyProjects = 'my-projects',
  MyFiles = 'my-files',
  SharedFiles = 'shared-files',
  DeletedFiles = 'deleted-files',
  MyData = 'my-data',
  SharedData = 'shared-data',
  DeletedData = 'deleted-data',
  DeletedProjects = 'deleted-projects',
  MyNotifications = 'my-notifications',
  DeletedNotifications = 'deleted-notifications',
  Query = 'query',
  Trends = 'trends'
}