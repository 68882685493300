import { Component } from '@angular/core';
import { availableFeatures, environment } from '../../../environments/environment';
import { ProjectsService } from 'src/app/api/projects.service';
import { LayoutOrganizationService } from 'src/app/api/layout-organization.service';
import { PermissionsHttpService } from 'src/app/shared/permissions-http-service/permissions-http.service';
import { PagesName } from 'src/app/shared/helpers/pagesName';
import { Features } from 'src/environments/features';
import * as _ from 'lodash';
import { PagesPublicName } from 'src/app/shared/helpers/pagesPublicName';
@Component({
  selector: 'app-homepage-saas',
  styleUrls: ['./homepage-saas.component.scss'],
  templateUrl: './homepage-saas.component.html',
})
export class HomepageSaasComponent {
  accessProjectManager = false;
  accessFileManager = false;
  accessLittResearch = false
  PagesPublicName =PagesPublicName;
  accessNotifications = false
  accessGuidedUserFlow = false
  accessDataManager = false
  canCreateProject = false
  canExploreFiles = false
  PagesName = PagesName;
  environment = environment;
  folder = true;
  file = true;
  search = true;
  _=_;
  Features =Features
  availableFeatures = availableFeatures

  solutions = [];

  constructor(
    public l: LayoutOrganizationService,
    private permissions: PermissionsHttpService,
    private projectsService: ProjectsService) {
    this.permissions
      .checkPermission({
        permissions: {
          accessLittResearch: {},
          accessFileManager: {},
          manageFocusGeneLists : {},
          accessProjectManager: {},
          accessNotifications: {},
          accessGuidedUserFlow: {},
          createProject:{},
          exploreFiles:{},
        },
      })
      .subscribe((res) => {
        this.accessProjectManager = res.accessProjectManager;
        this.accessFileManager = res.accessFileManager;
        this.accessLittResearch = res.accessLittResearch;
        this.accessNotifications =res.accessNotifications;
        this.accessGuidedUserFlow = res.accessGuidedUserFlow;
        this.canCreateProject = res.createProject;
        this.canExploreFiles = res.exploreFiles;
        this.accessDataManager = res.manageFocusGeneLists
      });
  }

  changeFolderSrc() {
    this.folder = !this.folder;
  }
  changeSearchSrc() {
    this.search = !this.search;
  }
  changeFileSrc() {
    this.file = !this.file;
  }

  ngOnInit() {
    this.projectsService.getSolutions().subscribe((res) => {
      this.solutions = res
    });
  }

  getSolutions() {
    return this.solutions;
  }


}
