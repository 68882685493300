<div class="page d-flex flex-column">
  <app-header>
    <span class="path h-100 w-100 d-flex align-items-center justify-content-between">
      <span class='path-element font-weight-bold'>
        {{PagesPublicName.KnowledgeSearch}} </span>
      <span class='path-element pr-3'>
        version 1.2 </span>
    </span>

  </app-header>
  <app-horizontal-nav-tab-2 [selectedTab]='knowledgeSearchService.activeTab'
    (valueChanged)="knowledgeSearchService.navigateToTab($event.value);knowledgeSearchService.updateActiveTab($event.value)"
    [dataSource]="knowledgeSearchService.tabsDataSource">
    <span *ngIf=' knowledgeSearchService.results && environment.name !== "prod"' class=' mx-5'>
      <span class='mr-2 '>Debug mode</span>
      <label [class.debugMode]='knowledgeSearchService.debugMode' class="switch">
        <input (click)='knowledgeSearchService.toggleDebugMode()' type="checkbox" />
        <div></div>
      </label>
    </span>
    <span (click)='layout.open("modalQuerySettings")' class='text-primary btn btn-link font-weight-bold'>
      Query settings</span>
  </app-horizontal-nav-tab-2>
  <app-content *ngIf='_.get(knowledgeSearchService, "activeTab.value") ===  _.get(_.first(knowledgeSearchService.tabsDataSource), "value") ' [isTab]='true' [isHeader]='true' class='scrollable'>
    <app-knowledge-search-formulation
      *ngIf='!knowledgeSearchService.selectedFormulation && ! knowledgeSearchService.results'>
    </app-knowledge-search-formulation>
    <app-knowledge-search-keywords
      *ngIf='knowledgeSearchService.selectedFormulation === Formulation.Keywords && ! knowledgeSearchService.results'>
    </app-knowledge-search-keywords>
    <app-knowledge-search-article
      *ngIf='knowledgeSearchService.selectedFormulation === Formulation.Article && ! knowledgeSearchService.results'>
    </app-knowledge-search-article>
    <app-knowledge-search-therapy
      *ngIf='knowledgeSearchService.selectedFormulation === Formulation.Therapy && ! knowledgeSearchService.results'>
    </app-knowledge-search-therapy>
    <app-knowledge-search-results *ngIf=' knowledgeSearchService.results'>
    </app-knowledge-search-results>

    <app-custom-modal modalTitle="Query Settings" idName="modalQuerySettings" [show]="layout.isQuerySettingModalOpen"
      [confirmActions]="[]" cancelTitle='Close' [cancelActions]="[]">
      <div class="custom-modal-content m-5">
        <div class='w-100 h-100 d-flex flex-column'>
          <div class='w-100 d-flex justify-content-between  my-4'>
            <span class='w-25 mr-3'>Content Source</span>
            <span class='text-muted w-75'>
              The database of the 1st version of COVsight is made of LitCovid Open Access articles. More sources will be
              available soon.
            </span>
          </div>
          <div class='w-100 d-flex justify-content-between '>
            <span class='w-25 mr-3'>Search rules</span>
            <span class='text-muted w-75 '>
              COVsight 1.2 list of rules:
              <br>
              <ul>
                <li>
                  Databases: LitCovid 2021-07-13 Open Access-Common Creative license (33 300 articles) </li>
                <li>
                  Date range: unlimited to now </li>
                <li>
                  Journal exclusion: none </li>
                <li>
                  Journal inclusion: none </li>
                <li>
                  Keyword exclusion: none </li>
                <li>
                  Keyword inclusion: none </li>
                <li>
                  Cochrane Handbook rules and best practices included or compatible:
                  <ul>
                    <li>
                      Planning the search</li>
                    <li>
                      Searching trials registers</li>
                    <li>
                      Searching for grey literature</li>
                    <li>
                      Searching within other reviews</li>
                    <li>
                      Searching reference lists</li>
                    <li>
                      Searching for different types of evidence</li>
                    <li>
                      Restricting database searches</li>
                    <li>
                      Examining errata</li>
                    <li>
                      Rerunning searches</li>
                    <li>
                      Incorporating findings from rerun searches</li>
                    <li>
                      Documenting the search process</li>
                  </ul>
                </li>
              </ul>
            </span>
          </div>
        </div>
      </div>

    </app-custom-modal>


  </app-content>

</div>
