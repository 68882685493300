import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class PermissionsHttpService {
  protected basePath = 'XXXX';

  constructor(private http: HttpClient) {}

  checkPermission(permission): Observable<any> {
    return this.http.post<any>(
      `${this.basePath}/mas/auth/is_authorized`,
      permission
    );
  }
}
