<div *ngIf='projectManagerService && shareService' class="page d-flex flex-column">
  <app-header>
    <span class="path h-100 w-100 d-flex align-items-center">
      <span *ngIf="!loading && !canExploreProjects" class='path-element font-weight-bold'>
        {{PagesPublicName.ProjectManager}} </span>
        <span *ngIf="canExploreProjects" class='path-element font-weight-bold'>
          Explore Projects </span>
    </span>
  </app-header>
  <app-horizontal-nav-tab-2 [selectedTab]='projectManagerService.activeTab'  *ngIf="!canExploreProjects"
    (valueChanged)="projectManagerService.actionEvent.emit([Action.ChangeTab,$event])"
    [dataSource]="projectManagerService.tabsDataSource">
    <mims-select *ngIf="_.get(projectManagerService, 'countriesDataSource.length') > 1"
      [selectedOption]="projectManagerService.activeCountry" [dataSource]="projectManagerService.countriesDataSource"
      (valueChanged)="projectManagerService.actionEvent.emit([Action.ChangeCountry,$event])">
    </mims-select>
  </app-horizontal-nav-tab-2>
  <app-content [isTab]='true' [isHeader]='true' class="bg-ivory scrollable">
    <div class='d-flex h-100 pt-3 '>

      <div class='files '>
        <div class="w-100 h-100 pr-2 ">
          <div class='w-100 d-flex mb-3'>
              <button  ngbTooltip="{{projectManagerService.getTooltip(Action.CreateProject)}}"
                *ngIf="projectManagerService.canPerformAction(Action.CreateProject) && !projectManagerService.canPerformAction(Action.AccessGuidedUserFlow)"
                tooltipClass="custom-tooltip" placement="bottom"
                (click)="projectManagerService.canPerformAction(Action.CreateProject) ? projectManagerService.actionEvent.emit([ Action.CreateProject]): ''"
                class="smooth-border button btn text-center  btn-primary text-light">
                <span class="cursor-info">Create project
                </span>
              </button>
    
              <div class="w-100  d-flex">
                <div class="badge bg-light border text-dark ml-auto my-3 p-2"><i class="fa-solid fa-bars-filter"
                    aria-hidden="true"></i>
                  {{projectManagerService._get('sortByType') === SortType.DateModification ? 'Date of modification' : 'Date of trashing'}}
                </div>
              </div>
          </div>
          <!-- <mims-top-utility-bar [canSelect]="false" [sortingData]="[{ sortType: SortType.Title, sortName: SortName.Name },{ sortType: SortType.DateCreation, sortName: SortName.Date }]"
            [sortingDirection]="projectManagerService._get('sortByDirection')"
            (sortingDirectionChange)="projectManagerService._set('sortByDirection', $event); projectManagerService.actionEvent.emit([Action.InitManager])"
            (sortingTypeChange)="projectManagerService._set('sortByType', $event); projectManagerService.actionEvent.emit([Action.InitManager])"
            [sortingType]="projectManagerService._get('sortByType')"></mims-top-utility-bar> -->

          <div class="w-100 filter-separator"> </div>
          <mims-files-2 class='m-5' [itemType]="ItemType.Project" [viewTab]="projectManagerService.tab"
            [displayedFiles]="projectManagerService._getAsync('displayedProjects')"
            [bulkSelection]="projectManagerService._get('bulkSelection')"
            [singleSelection]="projectManagerService._getAsync('activeItem')"
            (itemAction)="projectManagerService.itemActionEvent.emit($event)">
          </mims-files-2>
          <!-- <mims-pagination [page]="projectManagerService._get('page')" [pageSize]="projectManagerService._get('limit')"
            [count]="projectManagerService._getAsync('count')"
            (pageChange)="projectManagerService.actionEvent.emit([Action.ChangePage, $event ])">
          </mims-pagination> -->
        </div>
      </div>

    </div>
    <div id="info-content" [class.active]="l.isProjectInfosModalOpen">
      <div id="modalProjectsInfos" class='m-3'>
        <div class="d-flex justify-content-between my-4">
          <div class="d-flex align-items-center">
            <div class="icon-bg d-flex align-items-center justify-content-center   mr-3 smooth-border">
              <i class="fa-solid  fa-grid-2 " aria-hidden="true"></i>
            </div>
            <div class='font-weight-bold'>Project Info</div>
          </div>
          <button type="button" class="close" aria-label="Close" (click)="l.close('modalProjectsInfos')">
            <span><i class="fa-regular fa-1x  fa-circle-xmark text-primary" aria-hidden="true"></i></span>
          </button>
        </div>
        <mims-loader *ngIf="!projectManagerService._getAsync('activeItem')" class='w-100 pos-relative'
          [enableBackDrop]='false' [isLoading]="!projectManagerService._getAsync('activeItem')">
        </mims-loader>
        <div class="pannel-content">
          <app-info-project *ngIf="projectManagerService._getAsync('activeItem')"
            [item]="projectManagerService._getAsync('activeItem')">
          </app-info-project>
        </div>
      </div>
    </div>

    <div id="share-content" [class.active]="l.isProjectShareModalOpen">
      <div id="modalProjectsShare" class='m-3 '>
        <div class="h-100">
          <div class="d-flex justify-content-between my-4 ">
            <div class="d-flex align-items-center">
              <div class="icon-bg d-flex align-items-center justify-content-center   mr-3 smooth-border">
                <i class="fa-solid  fa-grid-2 " aria-hidden="true"></i>
              </div>
              <div class='font-weight-bold'>Share project</div>
            </div>
            <button type="button" class="close" aria-label="Close" (click)="l.close('modalProjectsShare')">
              <span><i class="fa-regular fa-1x fa-circle-xmark text-primary" aria-hidden="true"></i></span>
            </button>
          </div>
          <mims-loader *ngIf="!projectManagerService._getAsync('activeItem')" class='w-100 pos-relative'
            [enableBackDrop]='false' [isLoading]="!projectManagerService._getAsync('activeItem')">
          </mims-loader>
          <div class="pannel-content d-flex flex-column justify-content-between">
            <app-share-project *ngIf="!!projectManagerService._getAsync('activeItem')"
              class='flex-fill d-flex flex-column pannel-content '
              [item]=" projectManagerService._getAsync('activeItem')">
            </app-share-project>
            <div class="btn-container">
              <div class="d-flex flex-nowrap">
                <button type="button" class="modal-btn btn btn-dark"
                  (click)="l.close();shareService.resetNonSentInvitations()">
                  Cancel
                </button>
                <button type="button" class="modal-btn btn btn-dark" [disabled]="!_.get(shareService, 'usersSelected.length')"
                  (click)="projectManagerService.itemActionEvent.emit([projectManagerService._getAsync('activeItem'),ProjectItemAction.Share, undefined, shareService.usersSelected ])">
                  Confirm
                </button>
              </div>
            </div>
          </div>
          
        </div>
      </div>

    </div>
  </app-content>


</div>
