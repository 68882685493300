import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { PagesName } from 'src/app/shared/helpers/pagesName';
import { PermissionsHttpService } from 'src/app/shared/permissions-http-service/permissions-http.service';

@Component({
  selector: 'cohort-stratification-doc',
  styleUrls: ['./cohort-stratification.component.scss'],
  templateUrl: './cohort-stratification.component.html',
})
export class CohortStratificationDocComponent {
  canCreateProject = false

  constructor(private router: Router, private permissions: PermissionsHttpService) {
    this.permissions
    .checkPermission({
      permissions: {

        createProject:{}
      },
    })
    .subscribe((res) => {
      this.canCreateProject = res.createProject;
    });
  }
  createProject() {
    this.router.navigate([PagesName.ProjectCreation, PagesName.DefineProject,"popseg"]);
  }
}
