<div class='justify-content-between d-flex align-items-center flex-nowrap'>
  <div class='w-100 d-flex align-items-center flex-nowrap align-items-stretch '>
    <div (click)="changeTab(el)" *ngFor="let el of dataSource; let last = last"
      [class.active]='isActive(el)' class='tab-container cursor-pointer d-flex align-items-center'>
      <div class="tab w-100 vertical-bar d-flex align-items-center" [class.active]='isActive(el)' [class.last]='last'>
        <span class="w-100 text-center font-weight-bold nav-link align-bottom"
          [class.active]='isActive(el)'>{{ el.label }}</span>
      </div>
    </div>
    <div class='mx-3 ml-auto p-2 align-self-center'>
      <ng-content></ng-content>
    </div>
  </div>
