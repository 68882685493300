import { Component, OnInit, Input } from '@angular/core';
import * as _ from 'lodash';
import { LayoutOrganizationService } from 'src/app/api/layout-organization.service';

@Component({
  selector: 'app-invitation',
  templateUrl: './invitation.component.html',
  styleUrls: ['../../../../shared/layout/managers.scss', './invitation.component.scss']
})
export class InvitationComponent implements OnInit {
  @Input() team
  constructor(private layout: LayoutOrganizationService) { }

  ngOnInit(): void {
  }
  getName = () => {
    return _.get(this, 'team.name', '');
  }
  getKey = () => {
    return _.get(this, 'team._key', '');
  }
  handleJoin = () => {
    this.layout.toast('<b>Future feature</b>', null, 8000, 'Future feature', 'warning')
  }
}
