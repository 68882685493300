import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Expandable } from 'src/app/shared/expandable/expandable';

@Component({
  selector: 'app-category-filter',
  templateUrl: './category-filter.component.html',
  styleUrls: ['./category-filter.component.scss'],
})
export class CategoryFilterComponent extends Expandable implements OnInit {
  @Input() category: any;
  @Input() selectable: boolean;
  @Input() depth: number;
  @Output() expand = new EventEmitter<boolean>();

  constructor() {
    super();
  }

  ngOnInit(): void {}

  handleExpand() {
    this.toggleExpanded();
    this.expand.emit();
  }
}
