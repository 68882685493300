import { takeUntil } from 'rxjs/operators';
import { RibbonButtonComponent } from './../ribbon-button/ribbon-button.component';
import {
  AfterContentInit,
  Component,
  ContentChildren,
  QueryList,
  OnDestroy,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
} from '@angular/core';
import { Subject } from 'rxjs';

@Component({
  selector: 'mims-ribbon-alt-action',
  templateUrl: './ribbon-alt-action.component.html',
  styleUrls: ['./ribbon-alt-action.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class RibbonAltActionComponent implements AfterContentInit, OnDestroy {
  /** passed in buttons */
  @ContentChildren(RibbonButtonComponent) buttons: QueryList<RibbonButtonComponent>;

  /** Indicate if there are alter actions */
  hasAltActions = false;

  componentDestroyed$: Subject<void> = new Subject();

  constructor(private _cdr: ChangeDetectorRef) {}

  ngAfterContentInit() {
    this.hasAltActions = this.buttons && this.buttons.length > 0 ? true : false;

    this.buttons.changes.pipe(takeUntil(this.componentDestroyed$)).subscribe((evt) => {
      this.hasAltActions = evt.length > 0 ? true : false;
      this._cdr.detectChanges();
    });
  }

  ngOnDestroy() {
    this.componentDestroyed$.next();
    this.componentDestroyed$.complete();
  }
}
