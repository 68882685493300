import { takeUntil } from 'rxjs/operators';
import {
  ChangeDetectionStrategy,
  Component,
  Input,
  ElementRef,
  Output,
  EventEmitter,
  AfterViewInit,
  OnDestroy,
  ChangeDetectorRef,
} from '@angular/core';
import { fromEvent, Subject } from 'rxjs';

@Component({
  selector: 'mims-ribbon-button',
  templateUrl: './ribbon-button.component.html',
  styleUrls: ['./ribbon-button.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class RibbonButtonComponent implements AfterViewInit, OnDestroy {
  /** Icon string */
  @Input() icon = '';

  /** Label */
  @Input() label = '';

  /** If type is dropdown, it can open a dropdown upon click */
  @Input() type: 'button' | 'dropdown' = 'button';

  /** Emit when the button is clicked */
  @Output() clicked: EventEmitter<void> = new EventEmitter();

  dropdownOpen = false;

  private componenentDestroyed$: Subject<void> = new Subject();

  constructor(public elementRef: ElementRef, public _cdr: ChangeDetectorRef) {}

  ngAfterViewInit(): void {
    fromEvent(this.elementRef.nativeElement, 'click')
      .pipe(takeUntil(this.componenentDestroyed$))
      .subscribe((evt: MouseEvent) => {
        evt.stopPropagation();
        this.clicked.emit();
        if (this.type === 'dropdown') {
          this.dropdownOpen = !this.dropdownOpen;
          this._cdr.detectChanges();
        }
      });
  }

  onDropDownToggled(open) {
    this.dropdownOpen = open;
  }

  ngOnDestroy() {
    this.componenentDestroyed$.next();
    this.componenentDestroyed$.complete();
  }
}
