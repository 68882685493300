<div class='h-100 w-100 p-4 '>
  <div class="card mx-auto">
    <div class="card-body">
      <div *ngIf='inputsInWorkflow.value && !getFieldObjectsLength()'>The chosen solution doesn't
        require any further
        configuration.</div>
      <app-spinner *ngIf='inputsInWorkflow.value === null'></app-spinner>

      <form *ngIf='getForm() && getFieldObjectsLength()' class='ml-3' [formGroup]="getForm()">
        <h4>Configure parameters </h4>
        <ul class='mt-4 inputs-form list-unstyled'>
          <li class='my-2'>
            <app-input-field *ngFor='let fieldObject of getFieldObjects(false, true)' [valueUpdate]='fieldObject.retrievedValue'
              [parentForm]='getForm()'
              [allowedValueUpdate]="isBiomarkerInput(fieldObject.formControlName) ? fieldObject.retrievedAllowedValues : ''"
              [allowedValueDescriptionUpdate]="isBiomarkerInput(fieldObject.formControlName) ? fieldObject.retrievedAllowedValuesDescription : ''"
              (valueSelected)="isBiomarkerInput(fieldObject.formControlName) ? onCheckCluster(fieldObject) : ''"
              [inputJson]='fieldObject'
              [writeError]='isBiomarkerInput(fieldObject.formControlName)'
              >
            </app-input-field>
            <hr/>
            <h4>Optional parameters &nbsp;<a *ngIf="isBiomark" target="_blank" href="https://myintmachines.atlassian.net/servicedesk/customer/portal/6/article/3019342122" ngbTooltip="Documentation" ><i class="fas fa-info-circle"></i></a></h4>
           
            <app-input-field *ngFor='let fieldObject of getFieldObjects(true, false)' [valueUpdate]='fieldObject.retrievedValue'
            [parentForm]='getForm()'
            [allowedValueUpdate]="isBiomarkerInput(fieldObject.formControlName) ? fieldObject.retrievedAllowedValues : ''"
            [allowedValueDescriptionUpdate]="isBiomarkerInput(fieldObject.formControlName) ? fieldObject.retrievedAllowedValuesDescription : ''"
            (valueSelected)="isBiomarkerInput(fieldObject.formControlName) ? onCheckCluster(fieldObject) : ''"
            [inputJson]='fieldObject'
            [writeError]='isBiomarkerInput(fieldObject.formControlName)'
            >
          </app-input-field>
          </li>
        </ul>
      </form>
    </div>
    
    <app-spinner  *ngIf='doneUploading.value == false'></app-spinner>

    <div class='btn-container'>
      <div class="btn btn-light border" (click)='previous()' type="submit">
        <span class='text-uppercase small'>Previous</span>
      </div>
      <div *ngIf='getForm()' class="btn btn-dark" ngbTooltip="{{getFormError()}}" tooltipClass="custom-tooltip"
        placement="left" [class.disabled]="!getNextValidity()" (click)='!getNextValidity()? "" : next()' type="submit">
        <span class='text-uppercase small'>Execute</span>
      </div>
      <div *ngIf='!getForm()' class="btn btn-dark" (click)='next()' type="submit">
        <span class='text-uppercase small'>Execute</span>
      </div>
      <mims-loader [isLoading]="isLoading" [enableBackDrop]="true"></mims-loader>
    </div>
  </div>
  <div *ngIf="creditCost > 0" class="card mx-auto">
    <div class="card-body">
    <br />
    <p>For this project you will use <strong>{{creditCost}} credit(s)</strong> from your license.</p>
    </div>
  </div>
</div>
