import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-viz-select',
  templateUrl: './viz-select.component.html',
  styleUrls: ['./viz-select.component.scss'],
})
export class VizSelectComponent implements OnInit {


  ngOnInit(): void {}
}
