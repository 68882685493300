import { EventEmitter, Injectable } from '@angular/core';
import { LocalService } from 'src/app/api/local-storage.service';
import { Tabs } from 'src/app/shared/helpers/tabs';
import { TabsName } from 'src/app/shared/helpers/tabsName';
import { TabsPublicName } from 'src/app/shared/helpers/tabsPublicName';
import _ from 'lodash';
import { FileSystemState } from '../login/login-http-service/login-http.service';
import { GroupsManagerService } from 'src/app/api/groups-manager.service';
import { BehaviorSubject, Observable, Subscription } from 'rxjs';
import { SortType } from 'src/app/shared/helpers/sortType';
import { SortDirection } from 'src/app/shared/helpers/sortDirection';
import { Files2Service } from 'src/app/api/files2.service';
import { ItemType } from 'src/app/shared/helpers/itemType';
import { LayoutOrganizationService } from 'src/app/api/layout-organization.service';
import { FileGroupsService } from 'src/app/api/groups.service';
import { TeamsService } from '../teams-manager/teams.service';
import { ShareService } from 'src/app/api/share.service';
import { Router } from '@angular/router';
import { PagesName } from 'src/app/shared/helpers/pagesName';
export enum Action {
  ChangeCountry,
  ChangeTab,
  InitManager,
  ChangeSortingDirection,
  ChangeSortingType,
  Refresh,
  CreateFocusGeneList,
  CreateCohort,
}
export enum dataItemAction {
  Info,
  SelectShare,
  Share,
  DeletePermanentlySelected,
  RestoreSelected,
  RemovePermission,
}
export function constructObjectByCountry(countriesDataSource, defaultValue) {
  let newVariable = {};
  countriesDataSource.forEach((country) => {
    _.set(newVariable, country.value, defaultValue);
  });
  return newVariable;
}
export function constructObject(defaultValue, defaultValue2?) {
  let newVariable = {};
  _.set(newVariable, TabsName.MyData, defaultValue);
  _.set(newVariable, TabsName.SharedData, defaultValue2 ? defaultValue2 : defaultValue);
  return newVariable;
}
@Injectable({
  providedIn: 'root',
})
export class DataManagerService {
  TabsName = TabsName;
  teamsDataSource = new BehaviorSubject<any>(undefined);
  countriesDataSource;
  tabsDataSource: Array<Tabs> = [
    {
      value: TabsName.MyData,
      label: TabsPublicName.MyData,
    },
    /*{
      value: TabsName.SharedData,
      label: TabsPublicName.SharedData,
    },*/
  ];
  activeTab = this.tabsDataSource[0];
  activeCountry;
  filters = constructObject('ownedByMe=true', 'sharedWithMe=true');
  fileRequest: Subscription;
  displayedCohorts = constructObject(new BehaviorSubject<any>(undefined));
  displayedFiles = constructObject(new BehaviorSubject<any>(undefined));
  activeItem = constructObject(new BehaviorSubject<any>(undefined));
  actionEvent = new EventEmitter(undefined);
  itemActionEvent = new EventEmitter(undefined);
  constructor(
    private localService: LocalService,
    private router: Router,
    private fileGroupsService: FileGroupsService,
    private groupsManagerService: GroupsManagerService,
    private filesService: Files2Service,
    private l: LayoutOrganizationService,
    private teamsService: TeamsService,
    private shareService: ShareService
  ) {
    const cohortSortByType = constructObject(SortType.DateModification, SortType.DateModification);
    const cohortSortByDirection = constructObject(SortDirection.Asc);
    const focusGeneSortByType = constructObject(SortType.DateModification, SortType.DateModification);
    const focusGeneSortByDirection = constructObject(SortDirection.Asc);
    if (!localStorage.getItem('cohortSortByType')) {
      localStorage.setItem('cohortSortByType', JSON.stringify(cohortSortByType));
    }
    if (!localStorage.getItem('cohortSortByDirection')) {
      localStorage.setItem('cohortSortByDirection', JSON.stringify(cohortSortByDirection));
    }
    if (!localStorage.getItem('focusGeneSortByType')) {
      localStorage.setItem('focusGeneSortByType', JSON.stringify(focusGeneSortByType));
    }
    if (!localStorage.getItem('focusGeneSortByDirection')) {
      localStorage.setItem('focusGeneSortByDirection', JSON.stringify(focusGeneSortByDirection));
    }
    this.initCountries();
    this.retrieveTeams().then((teams) => {
      this.teamsDataSource.next(teams);
    });
    this.actionEvent.subscribe((event) => {
      if (event) {
        this.handleManagerAction(event);
      }
    });
    this.itemActionEvent.subscribe((event) => {
      if (event) {
        this.handleItemAction(event);
      }
    });
  }
  //ACTIONS
  retrieveTeams() {
    if (!this.teamsService){return}
    return new Promise((resolve) => {
      return this.teamsService.retrieveTeams(this.activeCountry.value).then((res: Array<any>) => {
        let newRes = [];
        res.forEach((el) => {
          newRes.push({ label: _.get(el, 'name'), value: _.get(el, '_key') });
        });
        resolve(newRes);
      });
    });
  }
  initCountries() {
    let countries = [];
    if (this.localService) {
      let fileSystems = this.localService.getFromLocalStorage('user', 'file_systems');
      fileSystems = _.sortBy(fileSystems, (o) => {
        return o.accessState !== FileSystemState.Local;
      });
      fileSystems.forEach((el) => {
        if (el.accessState !== FileSystemState.Disabled) {
          countries.push({
            label: el.name,
            value: el.value,
          });
        }
      });
    }
    this.countriesDataSource = countries;
    this.activeCountry = _.nth(countries, 0);
  }
  handleManagerAction(event) {
    const action = _.get(event, 'action');
    const destination = _.get(event, 'destination');
    const itemType = _.get(event, 'itemType');
    switch (action) {
      case Action.ChangeCountry:
        this.closePannel();
        this.activeCountry = destination;
        break;
      case Action.ChangeTab:
        this.closePannel();
        this.activeTab = destination;
        break;
      case Action.ChangeSortingType:
        if (itemType === ItemType.Cohort) {
          this.setToLocalStorage('cohortSortByType', destination);
        } else if (itemType === ItemType.FocusGeneList) {
          this.setToLocalStorage('focusGeneSortByType', destination);
        }
        break;
      case Action.ChangeSortingDirection:
        if (itemType === ItemType.Cohort) {
          this.setToLocalStorage('cohortSortByDirection', destination);
        } else if (itemType === ItemType.FocusGeneList) {
          this.setToLocalStorage('focusGeneSortByDirection', destination);
        }
        break;
      case Action.CreateCohort:
        this.l.open('modalGroup');
        break;
      case Action.CreateFocusGeneList:
        this.router.navigate([PagesName.FocusGeneSearch]);
        break;
      case Action.Refresh:
        if (itemType === ItemType.Cohort) {
          this.retrieveCohorts(this.activeCountry);
        } else if (itemType === ItemType.FocusGeneList) {
          if (this.fileRequest) {
            this.fileRequest.unsubscribe();
          }
          this.retrieveFiles();
        }
        break;
      case Action.InitManager:
        if (this.fileRequest) {
          this.fileRequest.unsubscribe();
        }
        this.shareService.listTeamsMembers(this.activeCountry.value);
        this.retrieveCohorts(this.activeCountry);
        this.retrieveFiles();
        break;
      default:
        return false;
    }
  }
  handleItemAction(event) {
    const item = _.get(event, 'item');
    const itemAction = _.get(event, 'itemAction');
    const destination = _.get(event, 'destination');
    const value = _.get(event, 'value');
    const isFocusGene = _.get(item, 'kind') === ItemType.Files;
    const isCohort = _.get(item, 'kind') === ItemType.FileGroups;
    const title = isFocusGene ? item.title : item.name;
    switch (itemAction) {
      case dataItemAction.SelectShare:
        this.retrieveItem(isFocusGene, isCohort, item, title);
        if (!this.l.isFilesShareModalOpen) {
          this.closePannel();
          this.l.open('modalFilesShare');
        }
        break;
      case dataItemAction.Share:
        this.shareService
          .shareToUsers(isCohort ? ItemType.FileGroup : ItemType.File, _.get(item, 'id'), destination, this.activeCountry.value)
          .then(() => {
            this.retrieveItem(isFocusGene, isCohort, item, title, true).subscribe(() => {
              this.l.open('modalFilesShare');
              _.set(this.l.fmPannelIsOpened, this.activeTab.value, true);
            });
          });
        break;
      case dataItemAction.RemovePermission:
        this.handleRemovePermission(item, value, isCohort, isFocusGene).then(() => {
          this.retrieveItem(isFocusGene, isCohort, item, title, true).subscribe();
        });
        break;
      case dataItemAction.Info:
        this.retrieveItem(isFocusGene, isCohort, item, title);
        if (!this.l.isFilesInfosModalOpen) {
          this.closePannel();
          this.l.open('modalFilesInfos');
        }
        break;
      case dataItemAction.DeletePermanentlySelected:
        if (isFocusGene) {
          let text =
            'This focus gene list file <b>' +
            _.get(item, 'title') +
            '</b> will be permanentely deleted. Are you sure you want to do this ?';
          this.l.customConfirm(text, () => {
            this.filesService.permanentlyDelete(_.get(item, 'id'), _.get(this.activeCountry, 'value')).subscribe(
              (res) => {
                this.l.toast('File <b>' + title + '</b> successfully deleted.', null, 5000, '', 'success');
                this.closePannel();
                this.retrieveFiles();
              },
              (error) => {
                this.l.toast('Impossible operation', null, 8000, 'Profile', 'danger');
              }
            );
          });
        } else if (isCohort) {
          let text = 'This cohort <b>' + _.get(item, 'name') + '</b> will be permanentely deleted. Are you sure you want to do this ?';
          this.l.customConfirm(text, () => {
            this.fileGroupsService.deleteGroup(item.id, this.activeCountry.value).subscribe(
              () => {
                this.l.toast('Cohort <b>' + title + '</b> successfully deleted.', null, 5000, '', 'success');
                this.closePannel();
                this.retrieveCohorts(this.activeCountry);
              },
              (error) => {
                this.l.toast('Impossible operation', null, 8000, 'Profile', 'danger');
              }
            );
          });
        }

        break;
    }
  }
  //GETTER

  getAutocompleteData = () => {
    return this.shareService.getAutocompleteData();
  };

  // LIST
  retrieveFiles() {
    this._setAsync('displayedFiles', undefined);
    this.filesService.getFileFromPath('/Focus Gene Lists', this.activeCountry.value).subscribe(result => {
    let id = _.get(result, 'id')
    const sortBy = this.getFromLocalStorage('focusGeneSortByType') + ',' + this.getFromLocalStorage('focusGeneSortByDirection');
    let filters = this._get('filters');
    filters = filters + ',parent="' + id + '"';
    this.fileRequest = this.filesService.listFiles(sortBy, filters, 100, 1, id).subscribe(
      (res) => {
        this._setAsync('displayedFiles', res.data);
      },
      (error) => {
        this._setAsync('displayedFiles', []);
        console.log(error);
      }
    );
   })
  }
  
  retrieveCohorts = (country) => {
    this._setAsync('displayedCohorts', undefined);
    const sortBy = this.getFromLocalStorage('cohortSortByType') + ',' + this.getFromLocalStorage('cohortSortByDirection');
    let filters;
    if (this.activeTab.value === TabsName.MyData) {
      filters = 'ownedByMe=true,sharedWithMe=false';
    } else if (this.activeTab.value === TabsName.SharedData) {
      filters = 'ownedByMe=false,sharedWithMe=true';
    }
    this.groupsManagerService.listGroups(this.activeTab.value, country.value, sortBy, filters).then((res: Array<any>) => {
      this._setAsync('displayedCohorts', _.get(res, 'data'));
    });
  };

  // RETRIEVE ELEMENT
  retrieveItem(isFocusGene, isCohort, item, title, seamless?): Observable<any> {
    if (!seamless) {
      this._setAsync('activeItem', undefined);
    }
    let func;
    if (isFocusGene) {
      func = this.filesService.retrieveInfo(item.id, this.activeCountry.value);
    } else if (isCohort) {
      func = this.fileGroupsService.retrieveGroup(item.id, this.activeCountry.value);
    }
    return new Observable(
      func.subscribe(
        (result) => {
          this._setAsync('activeItem', result);
        },
        () => {
          this.l.toast('<b>Error</b> when retrieving info details <b> ' + title + '</b>', null, 5000, '', 'danger');
        }
      )
    );
  }

  //BUTTONS
  getTooltip(action) {
    switch (action) {
      case Action.CreateCohort:
        return 'Create a new cohort';
      case Action.CreateFocusGeneList:
        return 'Create a new focus gene list file';
    }
  }
  canPerformAction(action) {
    switch (action) {
      case Action.CreateCohort:
        return this.activeTab.value === TabsName.MyData;
      case Action.CreateFocusGeneList:
        return this.activeTab.value === TabsName.MyData;
    }
  }
  //SELECTION
  getUsersSelected() {
    return _.get(this.shareService, 'usersSelected', []);
  }
  // HANDLE

  handleRemovePermission(item, value, isCohort, isFocusGene) {
    let func;
    if (isCohort) {
      func = this.groupsManagerService.removePermissionFromFileGroup(value, _.get(item, 'id'), this.activeCountry.value);
    } else if (isFocusGene) {
      func = this.filesService.removePermissionFromFile(value, _.get(item, 'id'), this.activeCountry.value);
    }
    return new Promise((resolve) => {
      return func.subscribe(
        () => {
          this.l.toast('Permission <b>successfully</b> removed from item.', null, 8000, 'Profile');
          resolve(true);
        },
        (error) => {
          this.l.toast('Impossible operation', null, 8000, 'Profile', 'danger');
        }
      );
    });
  }

  //LAYOUT
  closePannel = () => {
    this.l.close();
    this._setAsync('activeItem', undefined);
  };

  //FUNCTIONNAL
  _setAsync(variableName, value, tab?) {
    _.get(this, [variableName, tab ? tab : this.activeTab.value]).next(value);
  }
  _getAsync(variableName) {
    return _.get(this, [variableName, this.activeTab.value, 'value']);
  }
  setToLocalStorage(name, value) {
    let element = JSON.parse(localStorage.getItem(name));
    _.set(element, this.activeTab.value, value);
    localStorage.setItem(name, JSON.stringify(element));
  }
  getFromLocalStorage(name) {
    const element = JSON.parse(localStorage.getItem(name));
    return _.get(element, this.activeTab.value);
  }
  _get(variableName) {
    return _.get(this, [variableName, this.activeTab.value]);
  }
  _set(variableName, value, tab?) {
    _.set(this, [variableName, tab ? tab : this.activeTab.value], value);
  }
}
