import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import * as _ from 'lodash';
import { LocalService } from './local-storage.service';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { ModelsPostObject } from '../model/modelsPostObject';
import { AdditionalTypes } from '../shared/helpers/formattedInput';
import { MulticountryService } from './multicountry.service';
import { LayoutOrganizationService } from './layout-organization.service';
import { Files2Service } from './files2.service';
import { ActivatedRoute } from '@angular/router';
export function getVisible(value) {
  if (_.get(value, 'tags.invisible') === false) return true;
}
export function isBool(value) {
  if (_.get(value, 'type') === RawInputTypes.Bool) {
    return true;
  }
}
export function isArray(value) {
  if (_.get(value, 'tags.is_array')) {
    return true;
  }
}
export function isMultiple(value) {
  if (_.get(value, 'tags.multiple_selection')) {
    return true;
  }
}
export interface WorkflowInput {
  description: string;
  metadata: Object;
  object_type: string;
  tags: Object | null;
  type: RawInputTypes;
  optional: boolean;
}

export enum RawInputTypes {
  FileGroup = 'filegroups',
  Directory = 'directory',
  File = 'files',
  Bool = 'bool',
  String = 'string',
  Int = 'int',
  Float = 'float',
  CPUNbr = 'cpu_nbr',
}
interface Workflow {
  is_a_match: boolean;
  name: string;
  question: string;
  workflow_id: string;
}

@Injectable({ providedIn: 'root' })
export class ProjectsService {
  session;

  activeStep = 0;
  stepsList = ['Create Project', 'Select solution', 'Configure parameters', 'Workflow in progress'];
  stepsListEdition = ['Update project', 'Select solution', 'Configure parameters', 'Workflow in progress'];
  projectName = '';
  fromDebugger = false;
  projectStorageCountry = null;
  protected basePath = 'XXXX';
  preselectedSolution: string = "";

  constructor(
    private readonly http: HttpClient,
    private localService: LocalService,
    private filesService: Files2Service,
    private layout: LayoutOrganizationService,
    private multicountryService: MulticountryService,
  ) {
 
    this.session = this.localService.getFromLocalStorage('session');
  }

  getClustersNumber(filegroupId) {
    let body = {
      filegroup_id: filegroupId ? filegroupId : '2ea402d4-2903-4401-ab6e-334d51de6e95',
      unique_clusters: true,
      data_type: 'string',
      disease_filters: 'string',
    };
    return this.http.post<any>(`${this.basePath}/mas/fs/files/search`, body);
  }
  associateTeam(projectId: string, teamId: string): Observable<any> {
    return this.http.post<any>(`${this.basePath}/mas/fs/projects/${projectId}/team/${teamId}`, {});
  }
  createEmptyForm = (inputs) => {
    let object = {};
    for (const [key, value] of Object.entries(inputs)) {
      _.set(object, key, new UntypedFormControl(null));
    }
    return new UntypedFormGroup(object);
  };
  filterProjectInputs(inputs, tagName) {
    return _.filter(inputs, (o) => {
      return _.get(o, 'tags.' + tagName);
    });
  }

  setRetrievedValue(response, fieldObject) {
    response.forEach((element) => {
      let el = _.find(fieldObject, (o) => {
        return o.formControlName === element.tags.input_key_value;
      });
      if (el) {
        if (_.get(el, 'type') === AdditionalTypes.Object) {
          el.retrievedValue.next(_.words(_.get(element, 'value'), /[^,]+/g));
        } else if (_.get(el, 'is_array') && !_.get(el, 'multiple_selection')) {
          el.retrievedValue.next(_.nth(_.words(_.get(element, 'value'), /[^,]+/g), 0));
        } else {
          el.retrievedValue.next(_.words(_.get(element, 'value'), /[^,]+/g));
        }
      }
    });
  }
  handleRemoveInput = (projectId, element) =>
    new Promise((resolve, reject) => {
      this.removeInput(projectId, _.get(element, 'id')).subscribe(
        (res) => {
          resolve(res);
        },
        () => {
          reject();
        }
      );
    });
  handleAppendInput = (projectId, form, element, tagName) => {
    return new Promise((resolve, reject) => {
      let sentValue;
      if (element.type === AdditionalTypes.Object) {
        sentValue = _.join(this.getUserValue(form, element), ',');
      } else if (element.type === RawInputTypes.File) {
        sentValue = this.getUserValue(form, element);
      } else {
        sentValue = this.formatValue(this.getUserValue(form, element), this.getAllowedValue(element));
      }
      let tags = { is_array: this.getIsArray(element), input_key_value: this.getFormControlName(element) };
      _.set(tags, tagName, true);
      this.appendInput(projectId, element.type, sentValue, element.originalJson.metadata, tags).subscribe(
        (res) => {
          resolve(res);
        },
        () => {
          reject();
        }
      );
    });
  };
  updateAllInputs = (projectId, fieldObjects, inputsInProjects, form, tagName) => {
    return new Promise((resolve, reject) => {
      let array = [];
      fieldObjects.forEach((element) => {
        if (this.getUserValue(form, element).length) {
          array.push(this.handleAppendInput(projectId, form, element, tagName));
        } else if (element.type == 'files') {
          let defaultFile = _.get(element, 'originalJson.tags.default_file', undefined);
          if (defaultFile) {
            form.controls[element.formControlName].value = defaultFile;
            array.push(this.handleAppendInput(projectId, form, element, tagName));
          }
        }
      });
      inputsInProjects.forEach((element) => {
        array.push(this.handleRemoveInput(projectId, element));
      });
      Promise.all(array).then(
        () => {
          resolve(true);
        },
        () => {
          reject();
        }
      );
    });
  };

  formatValue = (rawValue, allowedValues?) => {
    let sentValue = '';
    if (Array.isArray(rawValue)) {
      if (allowedValues) {
        if (rawValue.length === allowedValues.length) {
          allowedValues.forEach((el, index) => {
            if (rawValue[index]) {
              if (sentValue === '') {
                sentValue = sentValue + _.get(el, 'value', el);
              } else {
                sentValue = sentValue + ',' + _.get(el, 'value', el);
              }
            }
          });
        } else {
          rawValue.forEach((el) => {
            if (sentValue === '') {
              sentValue =
                sentValue +
                _.find(allowedValues, (o) => {
                  return o === el;
                });
            } else {
              sentValue =
                sentValue +
                ',' +
                _.find(allowedValues, (o) => {
                  return o === el;
                });
            }
          });
        }
      }
    } else {
      sentValue = rawValue;
      if (typeof sentValue !== 'string') {
        sentValue = JSON.stringify(sentValue);
      }
    }
    return sentValue;
  };

  getFormControl(form, fieldObject) {
    return _.get(form, 'controls[' + this.getFormControlName(fieldObject) + ']');
  }
  getFormControlByName(form, fieldName) {
    return _.get(form, 'controls[' + fieldName + ']');
  }
  getUserValue(form, fieldObject) {
    return _.get(this.getFormControl(form, fieldObject), 'value');
  }
  getAllowedValue(fieldObject) {
    return _.get(fieldObject, 'allowed_values', []);
  }
  getIsArray(fieldObject) {
    return _.get(fieldObject, 'is_array');
  }
  getFormControlName(fieldObject) {
    return _.get(fieldObject, 'formControlName');
  }

  getProjectStorageCountry() {
    return _.get(this, 'projectStorageCountry');
  }
  getProjectName() {
    return _.get(this, 'projectName', '');
  }
  getActiveStep() {
    return _.get(this, 'activeStep', 0);
  }
  setActiveStep(value) {
    _.set(this, 'activeStep', value);
  }
  setProjectName(value) {
    _.set(this, 'projectName', value);
  }
  getFromDebugger() {
    return _.get(this, 'fromDebugger', false);
  }
  setFromDebugger(value: boolean) {
    _.set(this, 'fromDebugger', value);
  }
  getStepsList() {
    if (this.getFromDebugger() && this.getProjectName().length) {
      return _.get(this, 'stepsListEdition');
    } else {
      return _.get(this, 'stepsList');
    }
  }
  projectPatch(projectId: string, newValue: any, field: string, country?): Observable<any> {
    let body = {};
    body[field] = newValue;
    if (country) {
      let headers = new HttpHeaders({});
      headers = headers.set('country', country);
      return this.http.patch<any>(`${this.basePath}/mas/fs/projects/${projectId}`, body, { headers });
    }
    return this.http.patch<any>(`${this.basePath}/mas/fs/projects/${projectId}`, body);
  }
  projectRemove(projectId: string, country?): Observable<any> {
    if (country) {
      let headers = new HttpHeaders({});
      headers = headers.set('country', country);
      return this.http.delete<any>(`${this.basePath}/mas/fs/projects/${projectId}`, { headers });
    }
    return this.http.delete<any>(`${this.basePath}/mas/fs/projects/${projectId}`);
  }
  projectGet(projectId: string, country?): Observable<any> {
    if (country) {
      let headers = new HttpHeaders({});
      headers = headers.set('country', country);
      return this.http.get<any>(`${this.basePath}/mas/fs/projects/${projectId}`, { headers });
    }
    return this.http.get<any>(`${this.basePath}/mas/fs/projects/${projectId}`);
  }
  searchFiles(body, country?): Observable<any> {
    if (country) {
      let headers = new HttpHeaders({});
      headers = headers.set('country', country);
      return this.http.post<any>(`${this.basePath}/mas/fs/files/search`, body, { headers });
    }
    return this.http.post<any>(`${this.basePath}/mas/fs/files/search`, body);
  }
  restore(projectId: string, country): Observable<any> {
    let headers = new HttpHeaders({});
    headers = headers.set('country', country);
    return this.http.post<any>(`${this.basePath}/mas/fs/projects/${projectId}/restore`, {}, { headers });
  }
  removePermissionFromProject(permId: string, projectId, country): Observable<any> {
    let headers = new HttpHeaders({});
    headers = headers.set('country', country);
    return this.http.delete<any>(`${this.basePath}/mas/fs/projects/${projectId}/permissions/${permId}`, { headers });
  }
  permanentelyDelete(projectId: string, country): Observable<any> {
    let queryParameters = new HttpParams({});
    const notrash = true;
    queryParameters = queryParameters.set('notrash', notrash as any);
    let headers = new HttpHeaders({});
    headers = headers.set('country', country);
    return this.http.delete<any>(`${this.basePath}/mas/fs/projects/${projectId}`, {
      params: queryParameters,
      headers,
    });
  }
  count(filters: string, country): Observable<any> {
    let params = new HttpParams({});
    let headers = new HttpHeaders({});
    params = params.set('filters', filters as any);
    headers = headers.set('country', country);
    return this.http.get<any>(`${this.basePath}/mas/fs/projects/count`, {
      params,
      headers,
    });
  }
  projectList = (sortBy?: string, filters?: string, limit?: number): Observable<any> => {
    return this.multicountryService.asyncListing(
      this._projectList,
      [sortBy ? sortBy : undefined, filters ? filters : undefined, limit ? limit : undefined],
      'all'
    );
  };
  _projectList = (sortBy?: string, filters?: string, limit?: number, country?): Observable<any> => {
    let headers = new HttpHeaders({});
    headers = headers.set('country', country);

    let queryParameters = new HttpParams({});
    if (sortBy !== undefined && sortBy !== null) {
      queryParameters = queryParameters.set('sortBy', sortBy as any);
    }
    if (filters !== undefined && filters !== null) {
      queryParameters = queryParameters.set('filters', filters as any);
    }
    if (limit !== undefined && limit !== null) {
      queryParameters = queryParameters.set('limit', limit as any);
    }
    return this.http.get<any>(`${this.basePath}/mas/fs/projects`, {
      params: queryParameters,
      headers,
    });
  };

  createProject(project, country?) {
    interface Response {
      id: string;
    }
    if (country) {
      let headers = new HttpHeaders({});
      headers = headers.set('country', country);
      return this.http.post<Response>(`${this.basePath}/mas/fs/projects/root`, project, { headers });
    }
    return this.http.post<Response>(`${this.basePath}/mas/fs/projects/root`, project);
  }

  updateProject(project, projectId) {
    return this.http.patch(`${this.basePath}/mas/fs/projects/${projectId}`, project);
  }

  removeInput(projectId, inputId, country?) {
    if (country) {
      let headers = new HttpHeaders({});
      headers = headers.set('country', country);
      return this.http.delete(`${this.basePath}/mas/fs/projects/${projectId}/inputs/${inputId}`, { headers });
    }
    return this.http.delete(`${this.basePath}/mas/fs/projects/${projectId}/inputs/${inputId}`);
  }
  getWorkflow(workflowId, country?): Observable<any> {
    if (country) {
      let headers = new HttpHeaders({});
      headers = headers.set('country', country);
      return this.http.get<any>(`${this.basePath}/mas/workflows/${workflowId}`, { headers });
    }
    return this.http.get<any>(`${this.basePath}/mas/workflows/${workflowId}`);
  }

  getWorkflows(projectId?) {
    if (!projectId) {
      return this.http.get<Array<any>>(`${this.basePath}/mas/workflows`);
    }
    return this.http.get<Array<Workflow>>(`${this.basePath}/mas/fs/projects/${projectId}/workflows`);
  }

  triggerWorkflow(projectId, workflowId,country?) {
    const body = { workflow_id: workflowId, sort: true };
    if (country) {
      let headers = new HttpHeaders({});
      headers = headers.set('country', country);
      return this.http.post(`${this.basePath}/mas/fs/projects/${projectId}/workflows`, body, { headers});
    }
    return this.http.post(`${this.basePath}/mas/fs/projects/${projectId}/workflows`, body);
  }

  cancelRunningWorkflow(projectId, executionId) {
    return this.http.post(`${this.basePath}/mas/fs/projects/cancel/${projectId}/${executionId}`, undefined);
  }

  updateFormData(projectId, instanceId, formKey, fileId, name) {
    const body = {file_id: fileId, form_key: formKey, instance_id: instanceId , name : name};
    return this.http.post(`${this.basePath}/mas/fs/projects/${projectId}/form-data`, body);
  }
  
  uploadSmallFile = (fileToBeUploaded, teamId) => {
    return new Observable<string>((observer) => {
      this.prepareUpload(fileToBeUploaded, teamId).subscribe(
        (res) => {
          let uploadId = this.extractUploadId(res);
          this.proceedUpload(uploadId, fileToBeUploaded).subscribe(
            (res) => {
              observer.next(res.id);
            },
            (error) => {
              observer.next(error);
            }
          );
        },
        (error) => {
          observer.next(error);
        }
      );
    });
  };

  extractUploadId(response) {
    return response.id;
  }

  proceedUpload(uploadId, fileToBeUploaded): Observable<any> {
    let headers = new HttpHeaders({});
    headers = headers.append('accept', 'application/json');
    headers = headers.append('Content-Type', 'application/octet-stream');
    let contentRange = 'bytes ' + 0 + '-' + fileToBeUploaded.size + '/' + fileToBeUploaded.size;
    headers = headers.append('Content-Range', contentRange);
    return this.http.patch<any>(`${this.basePath}/mas/fs/upload/${encodeURIComponent(String(uploadId))}`, fileToBeUploaded, {
      headers,
    });
  }
  prepareUpload = (fileToBeUploaded, teamId) => {
    const newFileObject: ModelsPostObject = {
      description: '',
      is_folder: false,
      metadata: fileToBeUploaded.metadata,
      size: fileToBeUploaded.size,
      title: fileToBeUploaded.title || fileToBeUploaded.name,
      team_id: teamId,
    };
    let parentId = 'root';
    if (!!fileToBeUploaded.parentId) {
      parentId = fileToBeUploaded.parentId;
    }
    return this.filesService.postFile(parentId, newFileObject, undefined, 'response');
  };
  appendInput = (projectId: string, inputType, inputValue, metadata?, tags?, country?): Observable<any> => {
    let body = {};
    _.set(body, 'input_kind', inputType);
    _.set(body, 'input_value', inputValue);
    if (metadata) _.set(body, 'metadata', metadata);
    if (tags) _.set(body, 'tags', tags);
    if (country) {
      let headers = new HttpHeaders({});
      headers = headers.set('country', country);
      return this.http.post<any>(`${this.basePath}/mas/fs/projects/${projectId}/inputs`, body, { headers });
    }
    return this.http.post<any>(`${this.basePath}/mas/fs/projects/${projectId}/inputs`, body);
  };
  addInput(input, projectId, country?): Observable<any> {
    if (country) {
      let headers = new HttpHeaders({});
      headers = headers.set('country', country);
      return this.http.post(`${this.basePath}/mas/fs/projects/${projectId}/inputs`, input, { headers });
    }
    return this.http.post(`${this.basePath}/mas/fs/projects/${projectId}/inputs`, input);
  }
  getlistAllRuns() {
    let queryParameters = new HttpParams({});
    const from = 30;
    queryParameters = queryParameters.set('from', from as any);
    return this.http.get<any>(`${this.basePath}/mas/fs/projects/status`, {
      params: queryParameters,
    });
  }

  getProjectStatus(projectId): Observable<any> {
    let queryParameters = new HttpParams({});
    const base64 = false;
    const format = 'svg';
    queryParameters = queryParameters.set('format', format as any);
    queryParameters = queryParameters.set('base64', base64 as any);

    let headers = new HttpHeaders({});
    headers = headers.append('accept', 'image/svg+xml');

    return this.http.get(`${this.basePath}/mas/fs/projects/${projectId}/status`,
      {
        responseType: 'text',
        params: queryParameters,
       })
    }

  triggerRerun(projectId, instanceID, name): Observable<any>{ 
    return this.http.post(`${this.basePath}/mas/fs/projects/rerun/${projectId}/${instanceID}`, {name : name})
  }


  // Solutions
  getSolutions(): Observable<any>{
    return this.http.get(`${this.basePath}/mas/fs/solutions`)
  }

  getSolution(solutionId): Observable<any>{
    return this.http.get(`${this.basePath}/mas/fs/solutions/${solutionId}`)
  }

}
