import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'nestedProperty' })
export class NestedPropertyPipe implements PipeTransform {
  transform<T>(obj: T, property: string) {
    if (!obj || !property) {
      return '';
    }
    const props = property.split('.');
    const value = this.getValue<T>(obj, props, 0);

    return value;
  }

  /** recursively get the property */
  getValue<T>(obj: T, properties: Array<string>, i: number) {
    if (obj === undefined) {
      return '';
    }
    const index = properties[i];
    if (i === properties.length - 1) {
      const value = obj[index];
      return value === undefined || value === null ? '' : obj[index];
    } else {
      return this.getValue(obj[index], properties, ++i);
    }
  }
}
