import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-viz-menu',
  templateUrl: './viz-menu.component.html',
  styleUrls: ['./viz-menu.component.scss'],
})
export class VizMenuComponent implements OnInit {


  ngOnInit(): void {}
}
