<div class="user-creation-container mb-5">
  <div class="panel">
    <app-user-creation-form (userAdded)="onUserAdded($event)"></app-user-creation-form>
  </div>

  <table aria-describedby="user table" class="table table-bordered table-striped">
    <thead class="thead-dark">
      <tr>
        <th scope="col">Email</th>
        <th scope="col">First Name</th>
        <th scope="col">Last Name</th>
        <th scope="col">Ontological Domain</th>
        <th scope="col">File Hosting Country</th>
        <th scope="col">Organization</th>
        <th scope="col">Last Active</th>
        <th scope="col"></th>
        <th scope="col"></th>
      </tr>
    </thead>

    <tbody>
      <tr *ngFor="let user of users">
        <td>
          <p class="email">{{ user.email }}</p>
        </td>

        <td>
          <p class="first-name">{{ user.first_name }}</p>
        </td>
        <td>
          <p>{{ user.last_name }}</p>
        </td>
        <td>
          <p>{{ user.ontological_domain }}</p>
        </td>
        <td>
          <p>{{ user.file_hosting_country }}</p>
        </td>
        <td>
          <p>{{ user.organization }}</p>
        </td>
        <td>
          <p>{{ getTimeOfLastActivity(user.last_activity_time) }}</p>
        </td>
        <td>
          <button (click)="resetPassword(user.email)">Reset Password</button>
        </td>
        <td>
          <button *ngIf="!user.activated" (click)="activateUser(user._key)" [disabled]="currentUser.id === user._key">
            Activate Account
          </button>
          <button *ngIf="user.activated" (click)="deactivateUser(user._key)" [disabled]="currentUser.id === user._key">
            Deactivate Account
          </button>
        </td>
      </tr>
    </tbody>
  </table>
</div>