import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { UntypedFormGroup, UntypedFormControl, Validators } from '@angular/forms';
import { FileGroupsService } from 'src/app/api/groups.service';
import { ProjectsService } from 'src/app/api/projects.service';
import { GroupsManagerService } from 'src/app/api/groups-manager.service';
import { FileManager2Service } from '../file-manager2.service';
import { TabsName } from 'src/app/shared/helpers/tabsName';
import * as _ from 'lodash';
import { LayoutOrganizationService } from 'src/app/api/layout-organization.service';
import { Router } from '@angular/router';
import { LocalService } from 'src/app/api/local-storage.service';
import { PagesName } from 'src/app/shared/helpers/pagesName';
import { Action, DataManagerService } from '../../data-manager/data-manager.service';
import { ItemType } from 'src/app/shared/helpers/itemType';

@Component({
  selector: 'app-group-creation-form',
  templateUrl: './group-creation-form.component.html',
  styleUrls: [
    '../../../shared/styles/forms.component.scss',
    '../../../shared/layout/managers.scss',
    './group-creation-form.component.scss',
  ],
})
export class GroupCreationFormComponent implements OnInit {

  @Input() onSubmit : Function;

  private clusterFiles = {};
  public filesToAppend = [];
  public isLoading = true;
  public metadata;
  public keys = [];
  public popsegProjects = [];
  private uniqueClusters = {};
  public values = [[], []];
  public selection = [[],[],[],[],['transcriptome', 'clinical']];
  public lastUpdate = [];

  public leftSideIsLoading = true;
  public rightSideIsLoading = true;
  public rightSideHasBeenClicked = false;
  public metadataKeys

  public patientCount = 0
  public popsegPatientCount = 0
  public popsegProjectSelected = false;
  public metadataSelected = false;

  public selectedFolders = [];
  public selectedKeys = ['', '', '', '', 'data_type'];
  public selectedValues = ['', ''];
  public selectedInstanceFilegroup = '';

  public searchSubscription;
  public isExploringOnly = false;
  public isUsingGuidedUserFlow = false;

  public selectConfig = {
    enableSearch: true,
    showUncheckAll:true,
    dynamicTitleMaxItems: 1,
    showCheckAll: true
  };
  constructor(
    private projectsService: ProjectsService,
    public localService: LocalService,
    private fileManagerService: FileManager2Service,
    private dataManager: DataManagerService,
    private fileGroupsService: FileGroupsService,
    public groupsManagerService: GroupsManagerService, 
    private l : LayoutOrganizationService,
    private router: Router,
    ) {
      this.isUsingGuidedUserFlow = this.router.routerState.snapshot.url == '/project-creation/define-project/popseg' || this.router.routerState.snapshot.url === '/' + PagesName.DataManager;
      this.isExploringOnly = this.router.routerState.snapshot.url == '/explore-files';
  }

  ngOnInit() {
    this.patientCount = -1;
    this.searchFiles();
    this.initForm();
    this.initSelectors();
  }
  getCountryValue = () => {
    return _.get(this.fileManagerService, 'activeCountry.value');
  };
  initForm() {
    this.groupsManagerService.groupCreationForm = new UntypedFormGroup({
      groupInput: new UntypedFormControl(null, [Validators.required]),
    });
  }

  initSelectors() {
    if (!this.isUsingGuidedUserFlow && !this.isExploringOnly) {
      this.onKeySelect(0, {value:'c_diag_disease_at_onset'})
      this.onKeySelect(1, {value:'data_type'})

    } else {
      this.onKeySelect(0, {value:'indication'})
      this.onKeySelect(1, {value:'harmonized_age_at_index'})
      this.onKeySelect(2, {value:'harmonized_gender'})
      this.onKeySelect(3, {value:'harmonized_icd_10_code'})
      this.onKeySelect(4, {value:'data_type'})
    }
  }

  refreshSampleCount(event) {
    if (!(this.selectedKeys[0] == '' || this.selectedKeys[1] == '' || this.selectedValues[0] == '' || this.selectedValues[1] == '')) {
      this.patientCount = -1;
      let searchRequest = {
        "filegroup_id": "",
        "unique_clusters": false,
        "unique_metadata_keys": false,
        "data_type": "string",
        "disease_filters": "string",
        "or_filter": {},
        "and_filter": {},
        "optional_filter": {},
        "parent_ids": []
      }
      searchRequest.or_filter[this.selectedKeys[0]] = this.selectedValues[0];
      searchRequest.and_filter[this.selectedKeys[1]] = this.selectedValues[1];
      searchRequest.optional_filter[this.selectedKeys[1]] = this.selectedValues[2];
      searchRequest.parent_ids = this.selectedFolders;

      if (!!this.searchSubscription) {
        this.searchSubscription.unsubscribe();
      }

      this.searchSubscription = this.projectsService.searchFiles(searchRequest, this.getCountryValue()).subscribe((res) => {
        this.filesToAppend = res.file_ids;
        if (res.counts.patients > 0) {
          this.metadataSelected = true;
          this.popsegProjectSelected = false;
        }
        this.patientCount = res.counts.patients;
      });
    } else {
      this.filesToAppend = []
    }
  }

  onKeySelect(key, event) {
    this.leftSideIsLoading = true;
    this.projectsService.searchFiles({
      "filegroup_id": "",
      "unique_clusters": false,
      "unique_metadata_keys": false,
      "metadata_key": event.value,
      "workflow_name": "",
      "data_type": "",
      "disease_filters": ""
    }, this.getCountryValue()).subscribe((res) => {
    this.values[key] = res.metadata_values.map(value => {
      return {id: value, name: value, value: value, label: value, selected: event.value == "data_type" && (value == "clinical" || value == "transcriptome")}
    })
   
    if (event.value == 'data_type') {
      this.selectedValues[key] = 'clinical,transcriptome';
      this.values[key + 1] = [];
      this.values[key].forEach((value) => {
        if (!value.selected) {
          this.values[key + 1].push(value);
        }
      });
    }

    console.log(key, event.value)
    console.log(this.selectedValues);
  
    this.leftSideIsLoading = false;
    this.selectedKeys[key] = event.value
    this.refreshSampleCount(event);
  });
  }

  onFolderSelect(event) {
    this.selectedFolders = event.srcElement.value.split(',');
    this.refreshSampleCount(event);
  }

  onProjectSelectorClick() {
    if(!this.rightSideHasBeenClicked) {
      this.rightSideHasBeenClicked = true;
    }
  }

  onSelectionChange() {
    this.searchFiles();
  }

  searchFiles() {
    let orFilter = {};
    let andFilter = {data_type: ''};
    let ok = false;
    
    if(JSON.stringify(this.lastUpdate) == JSON.stringify(this.selection)) {
      return;
    } else {
      this.lastUpdate = JSON.parse(JSON.stringify(this.selection));
    }
    for (let x = 0; x < this.selection.length; x++) {
      for (let y = 0; y < this.selection[x].length; y++) {
        
        if(this.selectedKeys[x] != 'data_type') {
          if (orFilter[this.selectedKeys[x]] == undefined) {
            orFilter[this.selectedKeys[x]] = '';
          }
          orFilter[this.selectedKeys[x]] += this.selection[x][y].replace(",", "\\,");
          ok = true;
          if (y < this.selection[x].length - 1) {
            orFilter[this.selectedKeys[x]] += ','
          }
        } else {
          andFilter[this.selectedKeys[x]] += this.selection[x][y];
          if (y < this.selection[x].length - 1) {
            andFilter[this.selectedKeys[x]] += ','
          }
        }
      }
      if (this.selectedKeys[x] == 'data_type') {
        if (!!this.searchSubscription) {
          this.searchSubscription.unsubscribe();
        }
        this.patientCount = -1;
        this.searchSubscription = this.projectsService.searchFiles({
          or_filter: orFilter,
          and_filter: andFilter,
          count_only: true
        }, this.getCountryValue()).subscribe(res => {
            this.filesToAppend = res.file_ids;
            this.patientCount = res.counts.patients;
        });
      }
    }

  }

  onValueSelect(valueIndex, event) {
    let commaList = '';
    var options = event.srcElement && event.srcElement.options;
    var values = [];
    for (var i = 0; i < options.length; i++) {
      if (this.selectedKeys[valueIndex] == 'data_type' && (options[i].value == 'clinical' || options[i].value == 'transcriptome')) {
        options[i].selected = true;
      }
      if (options[i].selected) {
        if (commaList != '') {
          commaList += ',';
        }
        values.push(options[i].value);
        commaList += options[i].value;
      }
    }



    this.selectedValues[valueIndex] = commaList;

    this.refreshSampleCount(event);
  }

  onProjectSelect(event) {
    this.selectedInstanceFilegroup = event.value;
    this.refreshPopsegSampleCount(event);
  }

  refreshPopsegSampleCount(event) {
    this.projectsService.searchFiles({
      "filegroup_id": this.selectedInstanceFilegroup,
      "unique_clusters": false,
      "unique_metadata_keys": false,
    }, this.getCountryValue()).subscribe((res) => {
      this.popsegPatientCount = res.counts.patients
      this.filesToAppend = res.file_ids
      if(this.popsegPatientCount > 0) {
        this.popsegProjectSelected = true
        this.metadataSelected = false
      }
    })
  
  }

  saveNewGroup = () => {
    let self = this;
    this.fileGroupsService.isLoading = true;
    return this.fileGroupsService
      .postGroup(
        {
          description: '',
          metadata: {},
          name: this.groupInput.value,
        },
        'response',
        this.getCountryValue()
      )
      .subscribe(
        (res) => {
          this.dataManager.actionEvent.emit({action: Action.Refresh, itemType: ItemType.Cohort})

          if (this.onSubmit) {
            this.onSubmit(res);
          }
          self.chunkedInsert(
            res.id,
            self.filesToAppend.map((fileToAppend) => fileToAppend.substr(5))
          );
        },
        (error) => {
          if (error.status === 422) {
            alert('Error during the creation of the group.');
          } else {
            _.get(error, 'error.message');
            alert(error.error.message);
          }
        }
      );
  };

  chunkedInsert = (fileGroupId, files) => {
    const chunk = (arr, size) => arr.reduce((acc, e, i) => (i % size ? acc[acc.length - 1].push(e) : acc.push([e]), acc), []);
    let chunks = chunk(files, 20000);
    let chunksDone = 0;
    let chunkIdx = 0;
    chunks.forEach((c) => {
      setTimeout(() => {
        this.fileGroupsService.addFilesToGroup(fileGroupId, c, this.getCountryValue()).subscribe(() => {
          chunksDone += 1;
          if (chunksDone == chunks.length) {
            this.fileGroupsService.isLoading = false;
            this.groupsManagerService.listGroups(TabsName.MyFiles, this.fileManagerService.activeCountry);
            this.l.toast(
              'New group '+ this.groupInput.value + '  <b>successfully</b> created.',
              null,
              5000,
              '',
              'success'
            );
            this.groupsManagerService.eventRefreshGroups.emit(true)
          }
        });
      }, 100 * chunkIdx);
      chunkIdx += 1;
    });
  };

  get groupInput() {
    return this.groupsManagerService.groupCreationForm.get('groupInput');
  }
}
