<div *ngIf="!accessGuidedUserFlow" class="homepage d-flex flex-column">
  <div class="tile-container d-flex justify-content-around">
    <a
      class="tile project d-flex flex-column center justify-content-center"
      routerLink="/project-manager"
      (mouseenter)="changeFolderSrc()"
      (mouseleave)="changeFolderSrc()"
      *ngIf="accessProjectManager"
    >
      <div>
        <h4>MANAGE YOUR PROJECTS</h4>
      </div>
      <div class="icon">
        <div class="image-container">
          <i
            [ngClass]="{
              'fas fa-folder': folder,
              'fad fa-folder-open': !folder
            }"
            aria-hidden="true"
          ></i>
        </div>
      </div>
    </a>
    <a
      class="tile d-flex file flex-column center justify-content-center"
      routerLink="/file-manager"
      (mouseenter)="changeFileSrc()"
      (mouseleave)="changeFileSrc()"
      *ngIf="accessFileManager && !accessDataManager"
    >
      <div>
        <h4 class="only-on-mobile">VIEW YOUR FILES</h4>
        <h4 class="hidden-on-mobile">MANAGE YOUR FILES</h4>
      </div>
      <div class="icon">
        <div class="image-container">
          <i
            class="fa-file"
            [ngClass]="{
              fas: file,
              fad: !file
            }"
            aria-hidden="true"
          ></i>
        </div>
      </div>
    </a>
    <a
    class="tile d-flex file flex-column center justify-content-center"
    [routerLink]="'/' + PagesName.DataManager"
    (mouseenter)="changeFileSrc()"
    (mouseleave)="changeFileSrc()"
    *ngIf="accessDataManager"
  >
    <div>
      <h4 class="only-on-mobile">VIEW YOUR DATA</h4>
      <h4 class="hidden-on-mobile">MANAGE YOUR DATA</h4>
    </div>
    <div class="icon">
      <div class="image-container">
        <i
          class="fa-file"
          [ngClass]="{
            fas: file,
            fad: !file
          }"
          aria-hidden="true"
        ></i>
      </div>
    </div>
  </a>
    <a
    class="tile d-flex file flex-column center justify-content-center"
    [routerLink]="'/'+ PagesName.KnowledgeSearch + '/' + PagesName.Query"
    (mouseenter)="changeSearchSrc()"
    (mouseleave)="changeSearchSrc()"
    *ngIf="accessLittResearch  && _.includes(availableFeatures, Features.KnowledgeSearch)"
  >
    <div>
      <h4 class='text-uppercase'>{{PagesPublicName.KnowledgeSearchLong}}</h4>
    </div>
    <div class="icon">
      <div class="image-container">
        <i
          class="fa-search"
          [ngClass]="{
            fas: search,
            fad: !search
          }"
          aria-hidden="true"
        ></i>
      </div>
    </div>
  </a>
  </div>
  <app-user-notifications-page *ngIf='accessNotifications' [showRibbon]="false" class="notifications-overview"></app-user-notifications-page>
</div>
<app-homepage-saas *ngIf='accessGuidedUserFlow'></app-homepage-saas>