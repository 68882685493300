import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import * as _ from 'lodash';
import { LocalService } from 'src/app/api/local-storage.service';
@Injectable({
  providedIn: 'root',
})
export class TeamsHttpService {
  protected basePath = 'XXXX';
  user
  constructor(private readonly http: HttpClient, private localService: LocalService) {
    this.user = this.localService.getFromLocalStorage('user')
  }
  updateTeam = (teamId, newBody, country): Observable<any> => {
    let headers = new HttpHeaders({});
    headers = headers.set('country', country);
    return this.http.patch<any>(`${this.basePath}/mas/teams/${teamId}`, newBody, { headers });
  };
  deleteTeam = (id, country): Observable<any> => {
    let headers = new HttpHeaders({});
    headers = headers.set('country', country);
    return this.http.delete<any>(`${this.basePath}/mas/teams/${id}`, { headers });
  };

  createTeam = (country): Observable<any> => {
    let headers = new HttpHeaders({});
    headers = headers.set('country', country);
    const body = {
      name: 'New team',
      owner_user_ids: [_.get(this.user, 'id')],
      subscription_id: '',
      user_ids: [_.get(this.user, 'id')],
    };
    return this.http.post<any>(`${this.basePath}/mas/teams`, body, { headers });
  };

  activateUser(id, country) {
    let headers = new HttpHeaders({});
    headers = headers.set('country', country);
    return this.http.post(`${this.basePath}/mas/auth/activate`, {
      user_id: id,
    });
  }

  deactivateUser(id, country) {
    let headers = new HttpHeaders({});
    headers = headers.set('country', country);
    return this.http.post(`${this.basePath}/mas/auth/deactivate`, {
      user_id: id,
    });
  }

  submitUser(
    user: {
      email: string;
      first_name: string;
      last_name: string;
      ontological_domain: string;
      file_hosting_country: string;
      organization: string;
    },
    country
  ) {
    let queryParameters = new HttpParams({});
    let headers = new HttpHeaders({});
    headers = headers.set('country', country);
    queryParameters = queryParameters.set('set_password_via_email', true as any);
    return this.http.post(`${this.basePath}/mas/users`, user, {
      params: queryParameters,
      headers,
    });
  }

  getTeams = (country?): Observable<any[]> => {
    if (country) {
      let headers = new HttpHeaders({});
      headers = headers.set('country', country);
      return this.http.get<any>(`${this.basePath}/mas/teams`, { headers });
    } else {
      return this.http.get<any>(`${this.basePath}/mas/teams`);
    }
  };
  getTeam = (id): Observable<any> => {
    return this.http.get<any>(`${this.basePath}/mas/teams/teamusers/${id}`);
  };
  getTeam2 = (id, country): Observable<any> => {
    let headers = new HttpHeaders({});
    headers = headers.set('country', country);
    return this.http.get<any>(`${this.basePath}/mas/teams/teamusers/${id}`, { headers });
  };
  getUser(userId, country): Observable<any> {
    let headers = new HttpHeaders({ country: country });
    return this.http.get<any>(`${this.basePath}/mas/users/${userId}`, { headers });
  }
}
