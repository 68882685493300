<div *ngIf='user' class="page d-flex flex-column">
  <div class="my-account-page flex-fill d-flex flex-column">
    <div class='h-100 w-100 d-flex flex-nowrap'>



      <div *ngIf='Menu' id='NAVIGATION' class='d-flex flex-column'>
        <div *ngFor='let el of Menu'>
          <button class='btn' [class.selected]="el.privateName === currentSection" (click)='scrollTo(el.privateName)'>
            <div class="menu-link" [class.selected]="el.privateName === currentSection">{{el.publicName}} </div>
          </button>
          <div class='nav d-flex flex-column' *ngIf='el.sub.length'>
            <button class='btn' [class.selected]="el.privateName === currentSection" *ngFor=' let el2 of el.sub'
              (click)='scrollTo(el2.privateName)'>
              <div [class.selected]="el2.privateName === currentSubSection" class="menu-sub-link">{{el2.publicName}}
              </div>
            </button>
          </div>
        </div>
      </div>




      <div id='SCROLLABLEPAGE' scrollSpy [spiedTags]="['DIV']" (sectionChange)="onSectionChange($event)"
        (subSectionChange)="onSubSectionChange($event)" class='px-4 flex-fill scrollable h-100 d-flex flex-column'>
        <div id='{{Menu[0].privateName}}' class='section'>
          <h3 class='title text-muted'>MENU > {{Menu[0].publicName}}</h3>
          <div class='page-separator'></div>
          <div id='{{Menu[0].sub[0].privateName}}' class='sub-section'>
            <h4 class='my-4 font-weight-bold'>{{Menu[0].sub[0].publicName}}</h4>
            <div class="mx-2">
              <div class='my-3'>
                <div class='text-uppercase text-muted'>Account Image</div>
                <div class='my-2 avatar-container'>
                  <app-avatar [fontSize]="avatarFontSize"></app-avatar>
                </div>
              </div>
              <div class='my-3'>
                <div class='text-uppercase text-muted'>Name</div>
                <p class="user-name">{{ user.first_name }} {{ user.last_name }}</p>
              </div>
              <div class='my-3'>
                <div class='text-uppercase text-muted'>Email</div>
                <p class="email">{{ user.email }}</p>
              </div>
              <div *ngIf='user.id' class='my-3'>
                <div class='text-uppercase text-muted'>User ID</div>
                <p class="email">{{ user.id }}</p>
              </div>
              <div class='my-3'>
                <div class='text-uppercase text-muted'>Organization</div>
                <p class="organization">{{ user.organization }}</p>
              </div>
              <div class='my-3'>
                <div class='text-uppercase text-muted'>Teams</div>
                <p class="teams" *ngFor='let team of getTeams()'>{{ team.name }}</p>

              </div>


            </div>
          </div>
          <div id='{{Menu[0].sub[1].privateName}}' class='sub-section'>
            <h4 class='my-4 font-weight-bold'>{{Menu[0].sub[1].publicName}}</h4>
            <div class='mx-2'>
              <form [formGroup]="passwordModificationForm">

                <label class='d-flex' for="currentPassword">
                  <span class='text-uppercase text-muted'>Current password</span>
                </label>
                <div class="input-icon-container d-flex align-items-center flex-nowrap"
                  [class.field-error]="hasAtLeastOneError('currentPassword')">
                  <input [class.text-error]="hasAtLeastOneError('currentPassword')" class="value-input"
                    (keydown)='resetFeedbackError()' [type]="showCurrentPassword ? 'text' : 'password'"
                    id='currentPassword' formControlName="currentPassword" />
                  <div class='d-flex align-items-center' ngbTooltip="{{getTooltipContent('currentPassword')}}"
                    tooltipClass="custom-tooltip" placement="right">
                    <i *ngIf="hasAtLeastOneError('currentPassword')" class="users-icon error fa fa-exclamation-circle"
                      aria-hidden="true"></i>
                  </div>
                  <button tabindex="-1" (click)="passwordVisibilitySwitcher('showCurrentPassword')"
                    class="text-capitalize btn btn-icon ">

                    <i class="users-icon far" [class.fa-eye]='showCurrentPassword'
                      [class.fa-eye-slash]='!showCurrentPassword' aria-hidden="true"></i>
                  </button>
                </div>


                <label class='d-flex' for="newPassword">
                  <span class='text-uppercase text-muted'>New password</span>
                </label>
                <div [class.field-error]="hasAtLeastOneError('newPassword')"
                  class="input-icon-container d-flex align-items-center flex-nowrap">
                  <input [class.text-error]="hasAtLeastOneError('newPassword')" class="value-input"
                    [type]="showNewPassword ? 'text' : 'password'" id='newPassword' formControlName="newPassword" />
                  <div class='d-flex align-items-center' ngbTooltip="{{getTooltipContent('newPassword')}}"
                    tooltipClass="custom-tooltip" placement="right">
                    <i *ngIf="hasAtLeastOneError('newPassword')" class="fa fa-exclamation-circle users-icon error"
                      aria-hidden="true"></i>
                  </div>
                  <button tabindex="-1" (click)="passwordVisibilitySwitcher('showNewPassword')"
                    class="text-capitalize btn btn-icon">
                    <i class="users-icon far" [class.fa-eye]='showNewPassword' [class.fa-eye-slash]='!showNewPassword'
                      aria-hidden="true"></i>
                  </button>
                </div>
                <div class='small'
                  [class.text-muted]="newPassword.hasError('minlength') || newPassword.hasError('required')"
                  [class.text-primary]="!newPassword.hasError('minlength') && !newPassword.hasError('required') &&  (newPassword.dirty || newPassword.touched)">
                  <i *ngIf="newPassword.hasError('minlength') || newPassword.hasError('required')"
                    class="small fal fa-circle" aria-hidden="true"></i>
                  <i class="small far fa-check-circle"
                    *ngIf="!newPassword.hasError('minlength') && !newPassword.hasError('required') &&  (newPassword.dirty || newPassword.touched)"
                    aria-hidden="true"></i>
                  Your password needs 8 characters minimum.
                </div>
                <div class='small'
                  [class.text-muted]="newPassword.hasError('noSpecialCharacter') || newPassword.hasError('required')"
                  [class.text-primary]="!newPassword.hasError('noSpecialCharacter') && !newPassword.hasError('required') &&  (newPassword.dirty || newPassword.touched)">
                  <i *ngIf="newPassword.hasError('noSpecialCharacter') || newPassword.hasError('required')"
                    class="small fal fa-circle" aria-hidden="true"></i>
                  <i class="small far fa-check-circle"
                    *ngIf="!newPassword.hasError('noSpecialCharacter') && !newPassword.hasError('required') &&  (newPassword.dirty || newPassword.touched)"
                    aria-hidden="true"></i>
                  Your password must contain symbols (@#$%)).
                </div>
                <div class='small'
                  [class.text-muted]="newPassword.hasError('shouldNotHaveSpaces') || newPassword.hasError('required')"
                  [class.text-primary]="!newPassword.hasError('shouldNotHaveSpaces') && !newPassword.hasError('required') &&  (newPassword.dirty || newPassword.touched)">
                  <i *ngIf="newPassword.hasError('shouldNotHaveSpaces') || newPassword.hasError('required')"
                    class="small fal fa-circle" aria-hidden="true"></i>
                  <i class="small far fa-check-circle"
                    *ngIf="!newPassword.hasError('shouldNotHaveSpaces') && !newPassword.hasError('required') &&  (newPassword.dirty || newPassword.touched)"
                    aria-hidden="true"></i>
                  Your password should not contain spaces.
                </div>





                <label class='d-flex' for="newPasswordConfirmation">
                  <span class='text-uppercase text-muted'>Confirm new password</span>
                </label>

                <div class="input-icon-container d-flex align-items-center flex-nowrap"
                  [class.field-error]="hasAtLeastOneError('newPasswordConfirmation')">
                  <input class="value-input" [class.text-error]="hasAtLeastOneError('newPasswordConfirmation')"
                    [type]="showNewPasswordConfirmation ? 'text' : 'password'" id='newPassword'
                    id='newPasswordConfirmation' formControlName="newPasswordConfirmation" />
                  <div class='d-flex align-items-center' ngbTooltip="{{getTooltipContent('newPasswordConfirmation')}}"
                    tooltipClass="custom-tooltip" placement="right">
                    <i *ngIf="hasAtLeastOneError('newPasswordConfirmation') &&  (newPasswordConfirmation.dirty || newPasswordConfirmation.touched)"
                      class="fa fa-exclamation-circle error" aria-hidden="true"></i>
                  </div>

                  <button tabindex="-1" (click)="passwordVisibilitySwitcher('showNewPasswordConfirmation')"
                    class="text-capitalize btn btn-icon">
                    <i class="users-icon far" [class.fa-eye]='showNewPasswordConfirmation'
                      [class.fa-eye-slash]='!showNewPasswordConfirmation' aria-hidden="true"></i>
                  </button>
                </div>
                <span ngbTooltip="{{getTooltipContent('passwordModificationForm')}}" tooltipClass="custom-tooltip"
                  placement="right">
                  <button (click)="handlePasswordModification()" class="my-4 btn btn-add btn-dark" type="submit"
                    value="modify password" [disabled]="!passwordModificationForm.valid"
                    [class.no-pointer]='!passwordModificationForm.valid'>Modify password</button>
                </span>
              </form>
            </div>
          </div>

        </div>
        <div id='{{Menu[1].privateName}}' class='section last'>
          <h3 class='title text-muted'>MENU > {{Menu[1].publicName}}</h3>
          <div class='page-separator'></div>
          <div id='{{Menu[1].sub[0].privateName}}' class='sub-section'>
            <h4 class='my-4 font-weight-bold'>{{Menu[1].sub[0].publicName}}</h4>
            <form [formGroup]="userGroupCreationForm">
              <label for="userGroupName">
                <span class='text-uppercase text-muted'>Name</span>
              </label>
              <div class="input-icon-container d-flex align-items-center flex-nowrap">
                <input placeholder="Enter name" class="value-input" type="text" id='userGroupName'
                  formControlName="userGroupName" />
                <div class="text-capitalize  cursor-info" ngbTooltip="{{getTooltipContent('userGroupName')}}"
                  tooltipClass="custom-tooltip" placement="right">
                  <i [class.error]="(userGroupName.hasError('required') &&  (userGroupName.dirty || userGroupName.touched))  || userGroupName.hasError('alreadyExists') || userGroupName.hasError('maxlength')"
                    class="users-icon far fa-users" aria-hidden="true"></i>
                </div>
              </div>
              <label for="userGroupName">
                <span class='text-uppercase text-muted'>Description</span>
              </label>
              <div class="input-icon-container d-flex align-items-center flex-nowrap">
                <input placeholder="Enter description" class="value-input" type="text" id='userGroupDescription'
                  formControlName="userGroupDescription" />
              </div>

              <button (click)="addUserGroup()" class="my-4 btn btn-add btn-dark" type="submit"
                value="Add usergroup name" [disabled]="!userGroupName.valid">Create</button>
            </form>
          </div>
          <div id='{{Menu[1].sub[1].privateName}}' class='sub-section'>
            <h4 class='my-4 font-weight-bold'>{{Menu[1].sub[1].publicName}}</h4>

            <form [formGroup]='inviteForm'>
              <div class='d-flex flex-nowrap my-3'>
                <div class='user-list-container d-flex flex-column '>
                  <form class='user-list-form'>
                    <label for='userId'>
                      <span class='cursor-info text-uppercase text-muted'>Filter email</span>
                    </label>
                    <div class='d-flex'>
                      <div class='d-flex flex-column justify-content-start'>


                        <div class="input-icon-container d-flex align-items-center flex-nowrap">
                          <input (keydown)='debouncedFilter()' [(ngModel)]="filterEmail" class="value-input" type="text"
                            name='filterEmail' />
                          <i class="user-icon far fa-search" aria-hidden="true"></i>
                        </div>

                      </div>

                    </div>
                  </form>
                  <div class='user-list '>
                    <table aria-describedby='usergroups table' role='presentation' class="table"
                      *ngIf='shareService.getTeamsMembers()'>
                      <tbody>
                        <tr
                          *ngIf='!shareService.getTeamsMembers().length && _.findIndex(usersToInvite,createNewMember()) === -1 '
                          [class.user-selected]='_.findIndex(usersToInvite,createNewMember()) > -1'
                          (click)='toggleUserSelectionToInvite(createNewMember())'>
                          <td>
                            <i class="mx-2 text-muted user-icon far fa-paper-plane text-dark" aria-hidden="true"></i>
                            <span class='font-weight-bold'>
                              {{filterEmail}}
                            </span>
                          </td>
                          <td></td>

                        </tr>
                        <!-- <tr *ngFor='let el of shareService.getNewMembers()'
                          [class.user-selected]='_.findIndex(usersToInvite,el) > -1'
                          (click)='toggleUserSelectionToInvite(el)'>
                          <td>
                            <i class="mx-2 text-muted user-icon far fa-paper-plane text-dark" aria-hidden="true"></i>
                            <span class='font-weight-bold'>
                              {{el.value}}
                            </span>
                          </td>
                          <td></td>

                        </tr> -->
                        <ng-container *ngFor='let el of shareService.getTeamsMembers()'>
                          <tr *ngIf='_.findIndex(usersToInvite,el) > -1' class='user-selected'
                            (click)='toggleUserSelectionToInvite(el)'>
                            <td>
                              <i class="mx-2 text-muted user-icon far fa-address-book text-dark" aria-hidden="true"></i>
                              <span class='font-weight-bold'>
                                {{el.value}}
                              </span>
                            </td>
                            <td>
                              <span class='text-muted'>
                                {{el.team}}
                              </span>
                            </td>
                          </tr>
                        </ng-container>
                        <ng-container *ngFor='let el of shareService.getTeamsMembers()'>
                          <tr *ngIf='_.findIndex(usersToInvite,el) == -1' (click)='toggleUserSelectionToInvite(el)'>
                            <td>
                              <i class="mx-2 text-muted user-icon far fa-address-book text-dark" aria-hidden="true"></i>
                              <span class='font-weight-bold'>
                                {{el.value}}
                              </span>
                            </td>
                            <td>
                              <span class='text-muted'>
                                {{el.team}}
                              </span>
                            </td>
                          </tr>
                        </ng-container>
                      </tbody>
                    </table>
                  </div>
                  <button class="my-3 btn btn-add btn-dark" (click)='inviteUsers()' value="Add users"
                    [disabled]="!getCanManage() || (!usersToInvite.length || usergroupSelected.hasError('required'))">Invite
                    to group <i class="mx-2 far fa-arrow-right" aria-hidden="true"></i>
                  </button>
                </div>



                <div class='user-list-container  d-flex flex-column '>
                  <div class='user-list-form'>
                    <label for='usergroupSelected'>
                      <span class='cursor-info text-uppercase text-muted'>Select a group</span>
                    </label>

                    <select (change)='handleGroupSelection()' id="usergroupSelected"
                      [class.invalid]='!usergroupSelected.valid && (usergroupSelected.dirty || usergroupSelected.touched)'
                      formControlName="usergroupSelected" class="form-control">

                      <option *ngFor="let usergroup of shareService.getUsergroupNames()" [value]="usergroup.id">
                        {{usergroup.value}}
                      </option>

                    </select>
                    <p class='text-danger'
                      *ngIf="usergroupSelected.hasError('required') && (usergroupSelected.dirty || usergroupSelected.touched)">
                      This field is required.</p>
                  </div>
                  <div class='user-list '>
                    <table role='presentation' aria-describedby="usergroup content table" class="table"
                      *ngIf='usergroupSelected.value'>
                      <tbody>
                        <tr (click)='toggleUserSelectionToRemove(permission.grantee.id)' class="invited"
                          [class.user-selected]='_.indexOf(usersToRemove,permission.grantee.id) > -1'
                          *ngFor="let permission of getRetrievedUsergroupPermissions()">
                          <td>
                            <span class='font-weight-bold'>
                              {{permission.grantee.email}}
                            </span>
                          </td>
                          <td>
                            <span *ngIf='permission.role' class="text-capitalize  cursor-info"
                              ngbTooltip=" {{permission.role_description}}" tooltipClass="custom-tooltip"
                              placement="bottom">
                              {{permission.role}}</span>
                          </td>

                          <td class='onTop'>
                            <div [hidden]='permission.role === "owner"' *ngIf="getCanManage()" class="dropdown">
                              <button class="btn dropdown-toggle" type="button" id="dropdownMenuButton"
                                data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                <i class="text-muted far fa-edit" aria-hidden="true"></i>
                              </button>
                              <div class="dropdown-menu" aria-labelledby="dropdownMenuButton">
                                <a (click)='handleChangePermission(permission.grantee.id, "reader")'
                                  class="dropdown-item"><i class="far" [class.fa-check]='permission.role === "reader"'
                                    [class.icon-blank]='permission.role !== "reader"' aria-hidden="true"></i> Reader</a>
                                <a (click)='handleChangePermission(permission.grantee.id, "contributor")'
                                  class="dropdown-item"><i class="far fa-check"
                                    [class.fa-check]='permission.role === "contributor"'
                                    [class.icon-blank]='permission.role !== "contributor"' aria-hidden="true"></i>
                                  Contributor</a>
                              </div>

                            </div>


                          </td>

                        </tr>
                      </tbody>
                    </table>
                  </div>
                  <button class="my-3 btn btn-add btn-dark" (click)='handleDeleteUsersFromUsergroup()'
                    [disabled]="!usersToRemove.length || !getCanManage()">Remove
                    from group <i class="mx-2 far fa-times" aria-hidden="true"></i>
                  </button>
                </div>
              </div>

            </form>

          </div>
        </div>
      </div>

    </div>



  </div>
</div>