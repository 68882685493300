import { Component, Input } from '@angular/core';
import { LayoutOrganizationService } from 'src/app/api/layout-organization.service';

@Component({
  selector: 'app-header2',
  templateUrl: './header2.component.html',
  styleUrls: ['./header2.component.scss'],
})
export class Header2Component {
  @Input() theme?
  constructor(public l: LayoutOrganizationService) { }
}
