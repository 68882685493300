import { Component } from '@angular/core';
import { LayoutOrganizationService } from 'src/app/api/layout-organization.service';

@Component({
  selector: 'app-horizontal',
  templateUrl: './horizontal.component.html',
  styleUrls: ['./horizontal.component.scss'],
})

export class HorizontalComponent  {
  constructor(public l: LayoutOrganizationService) { };
}
