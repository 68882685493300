<div class='w-100 h-100 d-flex flex-column'>
  <app-header>

    <span class="path h-100 w-100 d-flex align-items-center justify-content-between">
      <span (click)='teamsService.navigateToTeams()' class='path-element cursor-pointer font-weight-bold'>
        {{PagesPublicName.TeamsManager}}
        <span *ngIf='teamsService.selectedTeam'>
          <i class="fal mx-2 fa-chevron-right" aria-hidden="true"></i> <span
            class='last-path-element'>{{_.get(teamsService, 'formattedSelectedTeam.name', '')}}
          </span>
        </span>
      </span>

      <mims-select *ngIf='_.includes(availableFeatures,Features.MultiCountry)' class='mr-4'
        [selectedOption]='_.nth(teamsService.countryDataSource,0)' [dataSource]="teamsService.countryDataSource"
        (valueChanged)="teamsService.setCountry($event)">
      </mims-select>
    </span>

  </app-header>
  <app-content *ngIf='!!teamsService.formattedSelectedTeam' class='scrollable' [isHeader]='true'>
    <app-team-manager [formattedSelectedUser]="_.get(teamsService.formattedSelectedUser, 'value')"
      [selectedUser]="_.get(teamsService.selectedUser, 'value')" (teamEvent)='teamsService.handleTeamEvent($event)'
      (userEvent)='teamsService.handleUserEvent($event)' [selectedTeam]='teamsService.selectedTeam'
      [formattedSelectedTeam]='teamsService.formattedSelectedTeam'></app-team-manager>
  </app-content>
  <app-content *ngIf='!teamsService.formattedSelectedTeam' class='scrollable' [isHeader]='true'>

    <div class='d-flex my-4'>
      <div class='btn bg-dark text-light mx-3'
        ngbTooltip="{{!teamsService.canCreateTeam() ? 'Team creation is currently limited to local country' : ''}}"
        placement="right" tooltipClass="custom-tooltip" [class.disabled]='!teamsService.canCreateTeam()'
        (click)='teamsService.canCreateTeam() ? teamsService.handleTeamEvent({action : TeamActions.Create}) : ""'> <i
          class="fas mx-2 fa-plus" aria-hidden="true"></i>
        Create team
      </div>

      <div class='d-flex flex-column flex-grow-1 mr-3'>
        <mims-loader [style.height.rem]='20' *ngIf='!getTeams()' [isLoading]='!getTeams()' [enableBackDrop]="false">
        </mims-loader>
        <span *ngIf='getTeams() && getTeams().length'>
          <app-team *ngFor='let team of  getTeams(); trackBy : trackFn'
            (teamEvent)='teamsService.handleTeamEvent($event)' [team]='team'></app-team>
        </span>
        <div class='empty-display w-100 justify-content-center align-items-center d-flex font-weight-bold'
          *ngIf='getTeams() && !getTeams().length'>
          <p>
            All your teams will display here.
          </p>
        </div>
      </div>
    </div>
  </app-content>

</div>

<app-custom-modal *ngIf='layout.isManageSubscriptionOpen'
  [modalTitle]="teamsService.hasSubscription(teamsService.selectedTeam) ? 'Manage subscription' : 'Add subscription'"
  idName="modalManageSubscription" [show]="layout.isManageSubscriptionOpen" confirmTitle="Save"
  [confirmActions]="[handleChangeSubscription]" confirmTooltip='Please verify your form.' [form]="teamSubscriptionForm
" [cancelActions]="[teamsService.resetSelectedTeam]">
  <div class="custom-modal-content">
    <app-team-subscription-form  [(teamSubscriptionForm)]='teamSubscriptionForm' *ngIf='layout.isManageSubscriptionOpen'
      [team]='teamsService.selectedTeam'>
    </app-team-subscription-form>
  </div>

</app-custom-modal>
