import { Component, OnInit, OnDestroy } from '@angular/core';
import { LocalStorage } from 'ngx-webstorage';
import { ApplicationScreenSizeService } from '../application-screen-size-service/application-screen-size.service';
import { Router, NavigationEnd } from '@angular/router';
import { LayoutOrganizationService } from 'src/app/api/layout-organization.service';
import { NotificationsService } from '../../api/notifications.service';
import { NotificationHttpService } from 'src/app/api/notification-http.service';
import { Subscription } from 'rxjs';
import { PermissionsHttpService } from '../permissions-http-service/permissions-http.service';
import { filter } from 'rxjs/operators';
import { PagesName } from 'src/app/shared/helpers/pagesName';
import { PagesPublicName } from '../helpers/pagesPublicName';
import * as _ from 'lodash';
import { ShareService } from 'src/app/api/share.service';
import { Status } from '../helpers/status';
import { LocalService } from 'src/app/api/local-storage.service';
import { Features } from 'src/environments/features';
import { availableFeatures } from 'src/environments/environment';

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss'],
})
export class SidebarComponent implements OnInit, OnDestroy {
  session;
  user;
  PagesName = PagesName;
  PagesPublicName = PagesPublicName;
  mouseIsOut = false;
  currentPage: string = PagesName.Home;
  serviceSubscription: Subscription;
  socketSubscription: Subscription;
  accessProjectManager = false;
  accessUserManager = false;
  accessWorflowEditor = false;
  accessWorkflowDebugger = false;
  deleteFilePermission = false;
  accessTeamManager = false;
  accessFileManager = false;
  accessNotificationManager = false;
  accessLittResearch = false;
  accessRunsDashboard = false;
  accessDataManager = false;
  canExploreProjects = false;
  notificationWSsubscription;
  dlLink = null;
  download = false;
  _ = _;
  Features = Features;
  availableFeatures = availableFeatures;

  constructor(
    public router: Router,
    private appScreenSize: ApplicationScreenSizeService,
    public l: LayoutOrganizationService,
    public notifService: NotificationsService,
    private localService: LocalService,
    private notifHttp: NotificationHttpService,
    private permissions: PermissionsHttpService,
    private shareService: ShareService
  ) {
    this.user = this.localService.getFromLocalStorage('user');
    this.session = this.localService.getFromLocalStorage('session');
    this.router.events.pipe(filter((e) => e instanceof NavigationEnd)).subscribe((res: NavigationEnd) => {
      const url = res.urlAfterRedirects;
      this.l.sidebarHidden = false;
      if (
        url.includes(PagesName.WorflowCreateMethod) ||
        url.includes(PagesName.WorkflowEditor) ||
        // url.includes(PagesName.WorkflowDebugger) ||
        // url.includes(PagesName.ProjectOverview) ||
        url.includes(PagesName.DevTest)
      ) {
        this.l.sidebarHidden = true;
      }

      if (res.url === '/') {
        this.currentPage = PagesName.Home;
      } else {
        const split = res.url.split('/');
        this.currentPage = split[1];
      }
    });
  }

  ngOnInit() {
    this.checkHover();

    this.appScreenSize.isMobile.subscribe((response) => {
      this.l.mobile = response;
    });
    this.appScreenSize.isTablet.subscribe((response) => {
      this.l.tablet = response;
    });
    this.appScreenSize.isDesktop.subscribe((response) => {
      this.l.desktop = response;
    });
    this.appScreenSize.getMobile();
    this.appScreenSize.getTablet();
    this.appScreenSize.getDesktop();

    this.notifService.getNotifications();

    this.socketSubscription = this.notifHttp.notificationSocket().subscribe(
      (notification) => {
        if (!this.notifService.isSystemNotification(notification)) {
          let notifications = this.notifService.userNotifications.value;
          _.set(notification, '_body', this.notifService.getParsedBody(notification));
          notifications.unshift(notification);
          this.notifService.userNotifications.next(notifications);
          this.notifService.notificationGroupedByExecutionId.next(
            _.groupBy(notifications, function (o) {
              if (!o._body) {
                return undefined;
              }
              return o._body.execution_message_id;
            })
          );
        }
        this.notifService.newNotification.next(notification);
      },
      (error) => {
        console.log(error);
      }
    );

    this.permissions
      .checkPermission({
        permissions: {
          // accessTeamManager: {},
          // accessWorkflowDebugger: {},
          // accessUserManager: {},
          // accessWorflowEditor: {},
          accessFileManager: {},
          // accessProjectManager: {},
          accessNotifications: {},
          // accessKnowledgeExplorer: {},
          // deleteMultipleFiles: {},
          // uploadMetadata: {},
          // uploadFiles: {},
          // deleteFiles: {},
          // downloadAllFiles: {},
          // downloadProjectFiles: {},
          // deleteProject: {},
          // createUser: {},
          accessLittResearch: {},
          accessProjectManager: {},
          // createProject: {},
          administrateUsers: {},
          createWorkflow: {},
          debugWorkflow: {},
          createTeam: {},
          accessRunsDashboard: {},
          manageFocusGeneLists: {},
          exploreProjects: {},
        },
      })
      .subscribe((res) => {
        this.accessUserManager = res.administrateUsers;
        this.accessWorflowEditor = res.createWorkflow;
        this.accessWorkflowDebugger = res.debugWorkflow;
        this.accessTeamManager = res.createTeam;
        this.accessProjectManager = res.accessProjectManager;
        this.accessLittResearch = res.accessLittResearch;
        this.accessFileManager = res.accessFileManager;
        this.accessNotificationManager = res.accessNotifications;
        this.accessRunsDashboard = res.accessRunsDashboard;
        this.accessDataManager = res.manageFocusGeneLists;
        this.canExploreProjects = res.exploreProjects;
      });
      // this.shareService.listUsergroups();
      // this.shareService.listTeamsMembers();
    

    if (this.notifService.newNotification) {
      this.notificationWSsubscription = this.notifService.newNotification.subscribe((notif) => {
        let executionId = this.notifService.getExecutionMessageId(notif);
        if (!notif || !executionId) {
          return;
        }
        if (!_.includes(this.notifService.getPendingFoldersExecutionIds(), executionId)) {
          return;
        }
        let folder = this.notifService.getFolder(executionId);
        if (this.notifService.isSuccessfullNotif(notif)) {
          _.set(folder, 'status', Status.Done);
          let accessToken = this.localService.getFromLocalStorage('country', 'access_token', this.session.accessToken);
          let link = this.notifService.getUrl(notif) + '?access_token=' + accessToken;
          _.set(folder, 'link', link);
          this.notifService.updateCompressionToastMessage();
          if (
            _.filter(this.notifService.pendingFolders, (o) => {
              return o.status === Status.Done;
            }).length > 1
          ) {
            this.notifService.compressionToastTitle.next('Downloads are ready');
          } else {
            this.notifService.compressionToastTitle.next('Download is ready');
          }
          setTimeout(() => {
            this.startDownload(folder.link);
          }, 1500);
        }
        if (this.notifService.isFailedNotif(notif)) {
          _.set(folder, 'status', Status.Failed);
          this.notifService.updateCompressionToastMessage();
        }
      });
    }
  }

  startDownload = (link) => {
    this.dlLink = link;
    this.download = true;
  };

  handleDownloadReset() {
    return () => {
      _.set(this, 'download', false);
      _.set(this, 'dlLink', null);
    };
  }
  checkHover() {
    this.l.hover = matchMedia('(hover: hover').matches;
  }

  logout(): void {
    this.l.toggleSideBar();
    localStorage.clear();
    this.router.navigate([PagesName.Login]).then(() => {
      window.location.reload();
    });
  }
  getPagesPublicName(pagesName) {
    let el = _.findKey(this.PagesName, (o) => {
      return o === pagesName;
    });
    return _.get(this.PagesPublicName, el, null);
  }
  handleSideBarClosingOnHover() {
    this.mouseIsOut = true;
    setTimeout(() => {
      if (!this.mouseIsOut) {
        return;
      }
      this.l.close('sidebar');
    }, 500);
  }
  resetTimer() {
    this.mouseIsOut = false;
  }
  handleSideBarClosingOnClick() {
    this.l.close('sidebar');
  }

  ngOnDestroy() {
    if (this.serviceSubscription) this.serviceSubscription.unsubscribe();
    if (this.socketSubscription) this.socketSubscription.unsubscribe();
    if (this.notificationWSsubscription) this.notificationWSsubscription.unsubscribe();
  }
}
