<div class="columns">
  <div class="column right">
    <button *ngIf="canCreateProject" class="btn btn-dark" (click)="createProject()">Create Project</button>
    <button *ngIf="!canCreateProject" ngbTooltip="Subscription Needed" class="btn btn-dark disabled">Create Project</button>
    <div class="images">
      <iframe style="width:100%;height:100%" src="https://www.youtube.com/embed/aZXAiQh7rlY" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
      <iframe style="width:100%;height:100%" src="https://www.youtube.com/embed/LzDm5oaWquk" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
  
    </div>
  </div>

  <div class="column">
    <div>
      <documentation-atlassian
        [docAtlassianID]="3020030032"
      ></documentation-atlassian>
    </div>
  </div>
</div>