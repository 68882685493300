export interface IOptionData<T> {
  /**
   * The dropdown option value
   */
  value?: any;

  /**
   * Dropdown option label
   * This is what is displayed on UI, also used for search option by a text
   */
  label?: string;

  /**
   * Extra data associated to this option
   */
  extra?: T;
}
