<div *ngIf='project.value' class="h-100 d-flex flex-column" style="background: rgba(0, 0, 0, 0.01);">
  <app-header>
    <div class="text-light h-100 w-100 d-flex bg-dark">
      <div [ngClass]="accessRerunProject ? 'col-sm-8': 'col-sm-10'" class="h-100 d-flex align-items-center">
        <i class="fad fa-folder-open project-icon bg-btn"></i>
        <h3>{{ getTitle(project) }} </h3>
        <div *ngIf="getFormattedInstances(project).length && getInstanceStatus(project, currentInstanceIndex) === Status.Failed ">
            <i class="fas fa-times text-danger icons-project-status"  aria-hidden="true"></i>
        </div>
        <div *ngIf="getFormattedInstances(project).length && getInstanceStatus(project, currentInstanceIndex) !== Status.Failed && getInstanceStatus(project, currentInstanceIndex) !== Status.Done">
          <i class="fad fa-spinner text-warning icons-project-status"  aria-hidden="true"></i>
        </div>
        <div *ngIf="getFormattedInstances(project).length && (getInstanceStatus(project, currentInstanceIndex) === Status.Done)">
          <i class="fas fa-check text-success icons-project-status"  aria-hidden="true"></i>
        </div>
      </div>

      <div *ngIf="getFormattedInstances(project).length" class="h-100 col-sm-2 d-flex align-items-center">
        <mims-select class="col-sm-12" [listWidth]="400" [selectedOption]='_.nth(getFormattedInstances(project),0)'
          [dataSource]="getFormattedInstances(project)" (valueChanged)="handleInstanceSelection(project,$event)">
        </mims-select>
      </div>
      <div *ngIf="accessRerunProject" class="h-100 justify-content-center col-sm-2 d-flex flex-column align-items-center">
        <div style="width: inherit;" *ngIf="getCanExecute(project) && getFormattedInstances(project).length && getInstanceEndTime(project, currentInstanceIndex) !== 0">
          
          <button (click)="handleReRun(project)" class="btn btn-outline-dark btn-sm btn-rerun bg-btn col-sm-12">
            <b>
              <i class="far fa-sync-alt btn-rerun bg-btn" aria-hidden="true"></i>
              <span *ngIf="!hasInstanceForm(project, currentInstanceIndex)">Rerun</span>
              <span *ngIf="hasInstanceForm(project, currentInstanceIndex)">Change Parameters</span>
            </b>
          </button>
        </div>
      </div> 
    </div>
  </app-header>

  <app-horizontal-nav-tab *ngIf="getFormattedInstances(project).length" [activeTab]='tabIndex' [navigate]="false"
    (selectedIndex)='handleTabSelection(project, $event)' [links]="getLinks(project, currentInstanceIndex)"
    [titles]="getTabNames(project, currentInstanceIndex)">
    <button (click)="onSave()" *ngIf="showSaveFocusGeneList()" class="btn btn-dark save-list-button" style="width:200px">Save Focus Gene List</button>
  </app-horizontal-nav-tab>
  <app-content [isTab]='true' [isHeader]='true'>
    <div class='btn btn-link' id="figure-legend" *ngIf='showPopSegLegend' (click)='handleLegend()'> <i class="far fa-info-circle"
      aria-hidden="true"></i> Figure Legends</div>
    <div
      *ngIf="getInstanceStatus(project, currentInstanceIndex) === Status.NotStarted">
      <app-spinner></app-spinner>
      <div class="mt-5 text-center">
        <i class="far fa-exclamation-circle" aria-hidden="true"></i>
        In progress...
      </div>
    </div>
    <div *ngIf="!getFormattedInstances(project).length">
      <div class="mt-5 text-center">
        <i class="far fa-exclamation-circle" aria-hidden="true"></i>
        In progress...
      </div>
    </div>
    <div *ngIf="getFormattedInstances(project).length && !getError(project,currentInstanceIndex)">
      <span *ngFor='let tab of getTabs(project,currentInstanceIndex); let index = index'>
        <app-project-dashboard2 *ngIf="!tab.formData" 
          [hidden]='index !== tabIndex' class="w-100" [isActive]='index === tabIndex' 
          [version]="getInstance(project, currentInstanceIndex)" [projectTitle]="getTitle(project)" 
          [projectId]='projectId'
          [country]='country'
          [dashboardId]='tab.dashboardId' [dashboardTitle]="_.nth(getTabNames(project, currentInstanceIndex),index)">
        </app-project-dashboard2>
        <dash-form *ngIf="tab.formData" [schemaId]="tab.formData.schema" [uischemaId]="tab.formData.uischema" class="form"
          [projectId]="projectId" [instanceId]="getFormattedInstances(project)[currentInstanceIndex].id" 
          [formKey]="tab.formData.formKey" [description]="tab.formData.description" [title]="tab.formData.title"></dash-form>
    </span>
    </div>
    <div *ngIf="getError(project,currentInstanceIndex)" class="my-3 text-center">
      <i class="far fa-exclamation-circle" aria-hidden="true"></i>
      Error: {{getError(project,currentInstanceIndex)}}
    </div>
  </app-content>
</div>

<app-full-screen-modal *ngIf='layout.isLegendModalOpen' idName='modalLegend' class='d-flex flex-column'>
  <div class='w-100 h-100 scrollable' style="padding-left: 15%; padding-right: 15%;">
    <div *ngIf='isBiomark(currentInstanceIndex)' >
      <documentation-atlassian
        [docAtlassianID]="3020029953"
      ></documentation-atlassian>
    </div>
    <div *ngIf='isIndicationPrioritization(currentInstanceIndex)' >
      <documentation-atlassian
        [docAtlassianID]="3019931649"
      ></documentation-atlassian>
    </div>
    <div *ngIf='isSolution(currentInstanceIndex, Solution.Genetic_Interaction_Discovery)' >
      <documentation-atlassian
        [docAtlassianID]="3019898881"
      ></documentation-atlassian>
    </div>
    <div *ngIf='isOld(currentInstanceIndex)' >
      <markdown class='w-100' *ngIf='tabIndex === 0' [data]='legendfile1.value'> </markdown>
      <markdown class='w-100' *ngIf='tabIndex === 1' [data]='legendfile2.value'> </markdown>
      <markdown class='w-100' *ngIf='tabIndex === 2'  [data]='legendfile3.value'> </markdown>
  </div>
  </div>
</app-full-screen-modal>

<app-full-screen-modal *ngIf='layout.isRerunModalOpen' idName='modalRerun'  class='d-flex flex-column'>
  <div class='w-100 h-100 px-5 scrollable'>
    <div class="rerun-modal">
      <div *ngIf="hasInstanceForm(project, currentInstanceIndex)">
        <h3>Change Parameters</h3>
      </div>
      <div *ngIf="!hasInstanceForm(project, currentInstanceIndex)">
        <h3>Re-run</h3>
      </div>
      <hr>
    </div>
    
    <div *ngIf="hasInstanceForm(project, currentInstanceIndex)">
      <dash-form  
        [formKey]="getFormKey(project,currentInstanceIndex)"
        [schemaId]="getSchemaID(project,currentInstanceIndex)"
        [uischemaId]="getUISchemaID(project,currentInstanceIndex)"
        [projectId]="getProjectID(project)"
        [instanceId]="getInstanceID(project,currentInstanceIndex)"
        [description]="getFormDescription(project,currentInstanceIndex)"
        [title]="getFormTitle(project,currentInstanceIndex)"
        [dataId]="getDataID(project,currentInstanceIndex)"
        ></dash-form>
    </div>

    <div *ngIf="!hasInstanceForm(project, currentInstanceIndex)" class="rerun-modal">
      <h4><b>Name version</b></h4>
      <input (keyup)="onVersionNameChange($event)" maxlength="20em" width="20em">
      <button (click)="handleReRunInstance(project, currentInstanceIndex)" class="rerun-modal-btn">
          <i class="far fa-sync-alt" aria-hidden="true"></i>
          Run
      </button>
    </div>

  </div>
</app-full-screen-modal>

<app-custom-modal *ngIf='isSavingFocusGeneList' [show]='isSavingFocusGeneList' modalTitle="Save Focus Gene List" confirmTitle="Save Focus Gene List"
(confirmEvent)="saveFocusGeneList()" (cancelEvent)="closeFocusGeneListForm()">
<h3>Selected Genes</h3>
  <p *ngIf="moreGenes">{{getGenesSummary()}} <button class="btn btn-link" (click)="moreGenes=false" href="">Show Less</button> </p>
  <p *ngIf="!moreGenes">{{getGenesSummary().substr(0,200)}}<span *ngIf="getGenesSummary().length > 200">... <button class="btn btn-link" (click)="moreGenes=true" href="">Show More</button></span></p>
  <br />
<h3>File Name</h3>
<input type="text"id="focus_gene_file_name" (change)="onGeneFileTitleChange($event)" />
<br />
</app-custom-modal>
