<div class='user-container mx-auto d-flex justify-content-between flex-column'>
  <small>
    <ul class='list-unstyled mb-5'>
      <li *ngFor='let item of formattedSelectedUser | keyvalue' class='my-2 '>
        <span class='text-muted text-uppercase'>{{item.key}} : </span>
        <span class='font-weight-bold ' [class.text-uppercase]='item.key !== "id"'>{{item.value}}</span>
        <span *ngIf='item.key === "role"'>
          <span class="btn dropdown-toggle" type="button" id="dropdownMenuButton" data-toggle="dropdown"
            aria-haspopup="true" aria-expanded="false">
            <i class=" far fa-edit" aria-hidden="true"></i>
          </span>
          <span class="dropdown-menu" aria-labelledby="dropdownMenuButton">
            <a (click)='handleChangePermission("Team Manager")' class="dropdown-item"><i class="far"
                [class.fa-check]=' item.value === "Team manager"' [class.icon-blank]=' item.value !== "Team manager"'
                aria-hidden="true"></i>
              Team manager</a>
            <a (click)='handleChangePermission("Member")' class="dropdown-item"><i class="far"
                [class.fa-check]=' item.value === "Member"' [class.icon-blank]=' item.value !== "Member"'
                aria-hidden="true"></i>
              Member</a>
          </span>
        </span>
      </li>

    </ul>
    <ul class='list-unstyled'>
      <li class='my-4 '>
        <div class='btn btn-light border' (click)='handleRemove()'> <i class="far fa-minus-circle mx-2"
            aria-hidden="true"></i>
          Remove from team</div>
      </li>
      <li class='my-4 '>
        <div class='btn btn-light border' [class.disabled]='!_.get(selectedUser, "activated")'
          (click)='!_.get(selectedUser, "activated") ? "" : handleDeactivate()'> <i
            class="far fa-user-lock mx-2" aria-hidden="true"></i>Deactivate account
        </div>
      </li>
    </ul>
  </small>
</div>
