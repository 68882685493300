import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Tabs } from 'src/app/shared/helpers/tabs';

@Component({
  selector: 'app-horizontal-nav-tab-2',
  templateUrl: './horizontal-nav-tab-2.component.html',
  styleUrls: ['./horizontal-nav-tab-2.component.scss'],
})
export class HorizontalNavTab2Component {
  @Input() dataSource: Array<Tabs>;
  @Input() selectedTab: any;
  @Output() valueChanged : EventEmitter<any> = new EventEmitter();



  isActive(el) {
    return el === this.selectedTab;
  }
  changeTab(el) {
    this.valueChanged.next(el)
  }
}
