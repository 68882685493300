<div (click)='markAsRead()' [class.read]='isRead()' [class.unread]="!isRead()"
  class='notification align-items-center justify-content-between d-flex p-2 px-3 btn'>
  <span class='d-flex align-items-center'>
    <mims-initials-bubble ngbTooltip="{{getFullName()}}" tooltipClass="custom-tooltip" placement="top"
      [fullName]='getFullName()'></mims-initials-bubble>
    <span class='text-muted text-capitalize' ngbTooltip="{{'Project located in ' + getCountry()}}" tooltipClass="custom-tooltip" placement="top"> {{getProjectName()}}    
    </span>
    <!-- <mims-flag  *ngIf='multicountryUser' ngbTooltip="{{'Project located in ' + getCountry()}}" tooltipClass="custom-tooltip" placement="top" class='cursor-info flag mx-2' [country]='getCountry()'></mims-flag> -->
    <span *ngIf='getTitle().length' class='mx-2'>–</span>
    {{getTitle()}} 
  </span>
  <span>{{getDateOfCreation() | timeAgo | removeAgo}}
  </span>
</div>
