import { EventEmitter, Injectable } from '@angular/core';
import * as _ from 'lodash';
import { LayoutOrganizationService } from './layout-organization.service';
import { PermissionsHttpService } from 'src/app/shared/permissions-http-service/permissions-http.service';

import { ProjectsService } from './projects.service';
import { Router } from '@angular/router';
import { TabsName } from 'src/app/shared/helpers/tabsName';
import { PagesName } from 'src/app/shared/helpers/pagesName';
import { BehaviorSubject } from 'rxjs';
import { FileSystemState } from '../pages/login/login-http-service/login-http.service';
import { LocalService } from './local-storage.service';
import { TabsPublicName } from '../shared/helpers/tabsPublicName';
import { KnowledgeSearchService } from '../pages/knowledge-search/knowledge-search.service';
import { ShareService } from './share.service';
import { ItemType } from '../shared/helpers/itemType';
import { Tabs } from '../shared/helpers/tabs';
import { SortType } from '../shared/helpers/sortType';
import { SortDirection } from '../shared/helpers/sortDirection';
export enum Action {
  ChangeCountry,
  ChangeTab,
  SelectTab,
  InitManager,
  ChangePage,
  DeletePermanently,
  Restore,
  CreateProject,
  SelectDisplayedFiles,
  UnSelectDisplayedFiles,
  AccessGuidedUserFlow
}
export enum ProjectItemAction {
  SelectShare,
  Share,
  Info,
  OpenDashboard,
  OpenQuery,
  ToggleSelect,
  Trash,
  Rename,
  RemovePermission,
  AddToSelection,
  RemoveFromSelection,
  DeletePermanentlySelected,
  RestoreSelected
}
export function constructObject(defaultValue, defaultValue2?, defaultValue3?) {
  let newVariable = {};
  _.set(newVariable, TabsName.MyProjects, defaultValue);
  _.set(newVariable, TabsName.SharedProjects, defaultValue2 ? defaultValue2 : defaultValue);
  _.set(newVariable, TabsName.DeletedProjects, defaultValue3 ? defaultValue3 : defaultValue);
  return newVariable;
}
@Injectable()
export class ProjectManagerService {
  bulkSelection = constructObject([]);
  displayedProjects = constructObject(new BehaviorSubject<any>(undefined));
  actionEvent = new EventEmitter(undefined);
  itemActionEvent = new EventEmitter(undefined);
  countriesDataSource;
  activeCountry;
  activeItem = constructObject(new BehaviorSubject<any>(undefined));
  tabsDataSource: Array<Tabs> = [
    { value: TabsName.MyProjects, label: TabsPublicName.MyProjects },
    { value: TabsName.SharedProjects, label: TabsPublicName.SharedProjects },
    { value: TabsName.DeletedProjects, label: TabsPublicName.DeletedProjects },
  ];
  activeTab = this.tabsDataSource[0];
  canAccessGuidedUserFlow = false;
  limit = constructObject(100);
  page = constructObject(1);
  count = constructObject(new BehaviorSubject<any>(0));
  filters = constructObject('ownedByMe=true', 'sharedWithMe=true,sharedWithMyUserGroups=true', 'trashed=true');
  sortByType = constructObject(SortType.DateModification, SortType.DateModification, SortType.DateTrashing);
  sortByDirection = constructObject(SortDirection.Desc);
  constructor(
    private projectsService: ProjectsService,
    private localService: LocalService,
    private l: LayoutOrganizationService,
    private shareService: ShareService,
    private router: Router,
    private knowledgeSearchService: KnowledgeSearchService,
    private permissions: PermissionsHttpService

  ) {

    if(this.router.url.indexOf('shared-projects')) { // TODO: Fix correctly instead
      this.activeTab = this.tabsDataSource[1];
    }

    this.initCountries();
    this.actionEvent.subscribe((event) => {
      if (event) {
        handleManagerAction(event);
      }
    });
    this.itemActionEvent.subscribe((event) => {
      if (event) {
        handleItemAction(event);
      }
    });

    const handleManagerAction = (event) => {
      const action = _.nth(event, 0);
      const destination = _.nth(event, 1);
      switch (action) {
        case Action.CreateProject:
          this.router.navigate(['/' + PagesName.ProjectCreation]);
          break
        case Action.ChangeTab:
          this.handleChangeTab(destination);
          break;
        case Action.SelectTab:
          this.handleSelectTab(destination);
          break;
        case Action.Restore:
          this.handleRestoreMultiple();
          break;
        case Action.DeletePermanently:
          this.handleDeletePermanently();
          break;
        case Action.ChangeCountry:
          this.handleChangeCountry(destination);
          break;
        case Action.ChangePage:
          console.log('change page');
          break;
        case Action.InitManager:
          this.handleInitManager();
          break;
        default:
          return false;
      }
    };
    const handleItemAction = (event) => {
      const item = _.nth(event, 0);
      const itemAction = _.nth(event, 1);
      const value = _.nth(event, 3);
      const id = _.get(item, 'id');
      const country = _.get(item, 'country', _.get(item, 'host_country'), this.country);
      const title = _.get(item, 'title');
      switch (itemAction) {
        case ProjectItemAction.Info:
          this.handleInfo(id, country, title);
          break;
        case ProjectItemAction.AddToSelection:
          this.addToBulkSelection(id, country, title);
          break;
        case ProjectItemAction.Trash:
          this.handleTrash(id, country, title);
          break;
        case ProjectItemAction.RemoveFromSelection:
          this.removeFromBulkSelection(id);
          break;
        case ProjectItemAction.Share:
          this.handleShare(id, value, country, title);
          break;
        case ProjectItemAction.SelectShare:
          this.handleSelectShare(id, country, title);
          break;
        case ProjectItemAction.RemovePermission:
          this.handleRemovePermission(item, id, country, value);
          break;
        case ProjectItemAction.Rename:
          this.handleRename(id, value, title, country);
          break;
        case ProjectItemAction.OpenQuery:
          this.handleOpenQuery(id, country);
          break;
        case ProjectItemAction.OpenDashboard:
          this.handleOpenDashboard(id, country);
          break;
        case ProjectItemAction.DeletePermanentlySelected:
          this._set('bulkSelection', []);
          this.addToBulkSelection(id,country,title)
          this.handleRestoreMultiple()
          break
        case ProjectItemAction.RestoreSelected:
          this._set('bulkSelection', []);
          this.addToBulkSelection(id,country,title)
          this.handleRestoreMultiple()
          break
        default:
          return false;
      }
    };

    this.permissions
    .checkPermission({
      permissions: {

        accessGuidedUserFlow:{}
      },
    })
    .subscribe((res) => {
      this.canAccessGuidedUserFlow = res.accessGuidedUserFlow;
    });
  }
  get tab(){
    return  _.get(this, 'activeTab.value')
  }
  get country(){
    return  _.get(this, 'activeCountry.value')
  }
  _get(variableName) {
    return _.get(this, [variableName, this.tab]);
  }
  _set(variableName, value, specifiedTab?) {
    _.set(this, [variableName, specifiedTab ? specifiedTab : this.tab], value);
  }
  _setAsync(variableName, value, specifiedTab?) {
    _.get(this, [variableName, specifiedTab ? specifiedTab : this.tab]).next(value);
  }
  _getAsync(variableName) {
    return _.get(this, [variableName, this.tab, 'value']);
  }
  handleOpenDashboard(id, country) {
    if (this.canPerformItemAction(ProjectItemAction.OpenDashboard)) {
      this.router.navigate(['/' + PagesName.ProjectManager, this.tab, id, country, PagesName.ProjectOverview]);
    }
  }
  handleRename(id, value, title, country) {
    this.projectsService.projectPatch(id, value, 'title', country).subscribe(() => {
      this.l.toast('Project <b>' + title + '</b> successfully renamed as <b> ' + value + '</b>', null, 5000, '', 'success');
      this.refresh(true);
      this.retrieveProject(id, country, title, true);
    });
  }
  handleShare(id, value, country, title) {
    this.shareService.shareToUsers(ItemType.Project, id, value, country).then(() => {
      this.retrieveProject(id, country, title, true);
    });
  }
  initCountries() {
    let countries = [];
    let fileSystems = this.localService.getFromLocalStorage('user', 'file_systems');
    fileSystems = _.sortBy(fileSystems, (o) => {
      return o.accessState !== FileSystemState.Local;
    });
    fileSystems.forEach((el) => {
      if (el.accessState !== FileSystemState.Disabled) {
        countries.push({ label: el.name, value: el.value });
      }
    });
    this.countriesDataSource = countries;
    this.activeCountry = _.nth(countries, 0);
}

  getTooltip(action) {
    switch (action) {
      case Action.CreateProject:
        if (_.get(this, 'activeTab.value') !== TabsName.MyProjects) return "You can't perform this action in this tab";
        return 'Create a new project';
      case Action.DeletePermanently:
        if (!this._get('bulkSelection').length) return 'Select at least one item to delete permanently';
        return "Delete selected items permanently. This action can't be undone";
      case Action.Restore:
        if (!this._get('bulkSelection').length) return 'Select at least one item to restore';
        return 'Restore selected items. These item will appear at the root of your files tab';
      default:
        return '';
    }
  }
  canPerformItemAction(action: ProjectItemAction) {
    switch (action) {
      case ProjectItemAction.OpenQuery:
        return this.tab !== TabsName.DeletedFiles;
      case ProjectItemAction.OpenDashboard:
        return this.tab !== TabsName.DeletedFiles;
      default:
        return false;
    }
  }
  canPerformAction(action: Action) {
    switch (action) {
      case Action.AccessGuidedUserFlow:
        return this.canAccessGuidedUserFlow
      case Action.CreateProject:
        return this.tab === TabsName.MyProjects;
      case Action.Restore:
        return this._get('bulkSelection').length > 0;
      case Action.DeletePermanently:
        return this._get('bulkSelection').length > 0;
      default:
        return false;
    }
  }
  handleInfo(id, country, title) {
    this.retrieveProject(id, country, title);
    if (!this.l.isProjectInfosModalOpen) {
      this.closePannel();
      this.l.open('modalProjectsInfos');
    }
  }
  handleTrash(id, country, title) {
    let text = '<b>' + title + '</b> will be trashed. Are you sure you want to do this ?';
    this.l.customConfirm(text, () => {
      this.projectsService.projectRemove(id, country).subscribe(
        (res) => {
          this.l.toast('Project <b>' + title + '</b> successfully trashed.', null, 5000, '', 'success');
          this.closePannel();
          this.refresh(true);
        },
        () => {
          this.l.toast('<b>Error</b> during the trashing of the project <b> ' + title + '</b>', null, 5000, '', 'danger');
        }
      );
    });
  }
  handleSelectShare(id, country, title) {
    this.retrieveProject(id, country, title);
    if (!this.l.isProjectShareModalOpen) {
      this.closePannel();
      this.l.open('modalProjectsShare');
    }
  }
  handleRemovePermission = (item, id, country, value) => {
    this.l.customConfirm('Are you sure you want to remove this permission ?', () => {
      this.projectsService.removePermissionFromProject(value, id, country).subscribe(
        () => {
          this.l.toast('Permission <b>successfully</b> removed from project.', null, 8000, 'Profile');
          this.retrieveProject(id, country, _.get(item, 'title'), true);
        },
        () => {
          this.l.toast('Impossible operation', null, 8000, 'Profile', 'danger');
        }
      );
    });
  };
  handleOpenQuery(id, country) {
    if (this.canPerformItemAction(ProjectItemAction.OpenQuery)) {
      this.projectsService.projectGet(id, country).subscribe((res) => {
        let input = _.find(_.get(res, 'input_list'), (o) => {
          return o.value === 'query';
        });
        if (!input) {
          this.l.toast('Impossible to open this search project', null, 8000, 'Profile', 'danger');
          return;
        }
        let savedQuery = _.last(_.get(input, 'tags.savedQueries'));
        _.set(savedQuery, 'query.id', id);
        this.knowledgeSearchService.setQueryParams(_.get(savedQuery, 'query'));
        this.knowledgeSearchService.editMode = true;
        this.knowledgeSearchService.navigateToResults(_.get(savedQuery, 'formulation'));
      });
    }
  }
  closePannel = () => {
    this.l.close();
    this._setAsync('activeItem', undefined);
  }
  handleChangeCountry(destination) {
    this.closePannel();
    this.activeCountry = destination;
    this.shareService.listTeamsMembers(this.country);
    this.shareService.listUsergroups(this.country);
    this.refresh();
  }
  handleChangeTab(destination) {
    this.router.navigate([PagesName.ProjectManager, _.get(destination, 'value')])
  }
  handleSelectTab(destination) {
    this.closePannel();
      let newTab =  _.find(this.tabsDataSource, (o) => {return o.value === destination})
      if (newTab){
        this.activeTab = newTab;
      }
     this.refresh();
  }
  handleDeletePermanently() {
    let isPlural = this._get('bulkSelection').length > 1;
    let subject = isPlural ? 'these ' + this._get('bulkSelection').length + ' projects ?' : 'this project ? ';
    let text = 'Are you sure you want to permanentely delete ' + subject;
    this.l.customConfirm(text, () => {
      this.handlePermanentlyDeleteMultiple();
    });
  }
  handleInitManager = () => {
    this.closePannel();
    this.shareService.listTeamsMembers(this.country);
    this.shareService.listUsergroups(this.country);
    _.set(this, 'bulkSelection', constructObject([]))
    _.set(this, 'displayedProjects', constructObject(new BehaviorSubject<any>(undefined)))
  }
  refresh(seamless?) {
    if (!seamless) {
      this._setAsync('displayedProjects', undefined);
    }
    this.projectsService
      ._projectList(this._get('sortByType') + ',' + this._get('sortByDirection'), this._get('filters'), this._get('limit'), this.country)
      .subscribe((res) => {
        _.get(res, 'data').forEach((item) => {
          _.set(item, 'country', this.country);
        });
        this._setAsync('displayedProjects', _.get(res, 'data'));
      });
  }

  retrieveProject(id, country, title, seamless?) {
    if (!seamless) {
      this._setAsync('activeItem', undefined);
    }
    this.projectsService.projectGet(id, country).subscribe(
      (result) => {
        this._setAsync('activeItem', result);
      },
      () => {
        this.l.toast('<b>Error</b> when retrieving project info<b> ' + title + '</b>', null, 5000, '', 'danger');
      }
    );
  }
  retrieveCount() {
    return this.projectsService.count(this._get('filters'), this.country).subscribe((res) => {
      this._setAsync('count', res.count);
    });
  }

  addToBulkSelection = (id, country, title) => {
    this.closePannel();
    let editedBulkSelection = _.cloneDeep(this._get('bulkSelection'));
    editedBulkSelection.push({ id, country, title });
    this._set('bulkSelection', editedBulkSelection);
  };

  removeFromBulkSelection = (id) => {
    this.closePannel();
    let editedBulkSelection = _.cloneDeep(this._get('bulkSelection'));
    _.remove(editedBulkSelection, function (el) {
      return el.id === id;
    });
    this._set('bulkSelection', editedBulkSelection);
  };

  bulkAction = (handleFunction, endCallback, errorCallBack, successCallback, confirmText?) => {
    let items = this._get('bulkSelection');
    items.forEach((item) => {
      handleFunction(item).subscribe(
        () => {
          successCallback(item);
        },
        () => {
          errorCallBack(item);
        },
        () => {
          if (item === _.last(items)) {
            endCallback(item);
          }
        }
      );
    });
  };
  handlePermanentlyDeleteMultiple = () => {
    const permanentlyDeleteHandleFunction = (item) => {
      return this.projectsService.permanentelyDelete(_.get(item, 'id'), _.get(item, 'country'));
    };
    const permanentlyDeleteEndCallback = (item) => {
      this.l.toast('Item(s) successfully deleted ', null, 5000, '', 'success');
      this._set('bulkSelection', []);
      return this.refresh();
    };
    const permanentlyDeleteErrorCallBack = (item) => {
      this.l.toast('Impossible to permanently delete <b>' + _.get(item, 'title') + '</b>', null, 5000, '', 'danger');
    };
    const permanentlyDeleteSuccessCallback = (item) => {
      return undefined;
    };
    this.bulkAction(
      permanentlyDeleteHandleFunction,
      permanentlyDeleteEndCallback,
      permanentlyDeleteErrorCallBack,
      permanentlyDeleteSuccessCallback
    );
  };
  handleRestoreMultiple = () => {
    const restoreHandleFunction = (item) => {
      return this.projectsService.restore(_.get(item, 'id'), _.get(item, 'country'));
    };
    const restoreEndCallback = (item) => {
      this.l.toast('Item(s) successfully restored ', null, 5000, '', 'success');
      this._set('bulkSelection', []);
      return this.refresh();
    };
    const restoreErrorCallBack = (item) => {
      this.l.toast('Impossible to restore <b>' + _.get(item, 'title') + '</b>', null, 5000, '', 'danger');
    };
    const restoreSuccessCallback = (item) => {
      return undefined;
    };
    this.bulkAction(restoreHandleFunction, restoreEndCallback, restoreErrorCallBack, restoreSuccessCallback);
  };
}
