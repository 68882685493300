
export enum TabsPublicName {
  SharedProjects = 'Projects shared with me',
  MyProjects = 'My projects',
  MyFiles = 'My files',
  SharedFiles = 'Files shared with me',
  DeletedFiles = 'Deleted files',
  MyData = 'My Data',
  SharedData = 'Data shared with me',
  DeletedData = 'Deleted data',
  DeletedProjects = 'Deleted projects',
  MyNotifications = 'Notifications',
  DeletedNotifications = 'Deleted notifications',
  Query = 'Search',
  Trends = 'Trends in LitCovid'
}
