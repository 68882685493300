<div class="page d-flex flex-column">
  <app-header theme="transparent">
    <app-path-2
      *ngIf="!projectsService.getProjectName() && !projectsService.getFromDebugger()"
      theme="light"
      [dataSource]="[
        { title: PagesPublicName.ProjectManager, id: null, action: [PagesName.ProjectManager] },
        { title: _.nth(projectsService.getStepsList(), projectsService.getActiveStep()), id: null }
      ]"
    >
    </app-path-2>
    <app-path-2
      *ngIf="projectsService.getProjectName() && !projectsService.getFromDebugger()"
      theme="light"
      [dataSource]="[
        { title: PagesPublicName.ProjectManager, id: null, action: [PagesName.ProjectManager] },
        { title: PagesPublicName.ProjectCreation, id: null },
        { title: 'Project ' + projectsService.getProjectName(), id: null },
        { title: _.nth(projectsService.getStepsList(), projectsService.getActiveStep()), id: null }
      ]"
    >
    </app-path-2>
    <app-path-2
      *ngIf="projectsService.getProjectName() && projectsService.getFromDebugger()"
      theme="light"
      [dataSource]="[
        { title: PagesPublicName.WorkflowDebugger, id: null, action: [PagesName.WorkflowDebugger] },
        { title: 'Project Edition', id: null },
        { title: 'Project ' + projectsService.getProjectName(), id: null },
        { title: _.nth(projectsService.getStepsList(), projectsService.getActiveStep()), id: null }
      ]"
    >
    </app-path-2>
    <app-path-2
      *ngIf="!projectsService.getProjectName() && projectsService.getFromDebugger()"
      theme="light"
      [dataSource]="[
        { title: PagesPublicName.WorkflowDebugger, id: null, action: [PagesName.WorkflowDebugger] },
        { title: PagesPublicName.ProjectCreation, id: null },
        { title: 'New Project', id: null },
        { title: _.nth(projectsService.getStepsList(), projectsService.getActiveStep()), id: null }
      ]"
    >
    </app-path-2>
  </app-header>
  <app-content [isHeader]="true">
    <div id="grid">
      <div id="steps">
        <ul class="list-unstyled">
          <li
            class="step mb-5"
            [class.active]="index === projectsService.getActiveStep()"
            *ngFor="let step of projectsService.getStepsList(); let index = index; let last = last"
          >
            <span *ngIf="!last">
              <i class="fa fal fa-check" [class.text-transparent]="!(projectsService.getActiveStep() > index)" aria-hidden="true"></i>
              {{ step }}
            </span>
          </li>
        </ul>
      </div>
      <div id="router" class="border">
        <router-outlet></router-outlet>
      </div>
    </div>
  </app-content>
  <mims-loader id="left-loader" [isLoading]="this.fileGroupsService.isLoading" [enableBackDrop]="true"></mims-loader>
</div>
