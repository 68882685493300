import { Features } from "./features";

export const environment = {
  production: true,
  name: 'prod',
  activationPath: 'https://prodcane-mims.canadaeast.cloudapp.azure.com',
  platformName: 'MIMs Platform',
  platformUrl: "https://prodcane.pages.dev",
  apiBasePath: 'https://prodcane-mims.canadaeast.cloudapp.azure.com',
};
export const availableFeatures = [
  Features.MultiCountry,
  Features.DoubleAuth,
  Features.UploadFile,
  Features.DeleteFile,
  Features.KnowledgeSearch,
  Features.MoveManuallyToGroup
]
