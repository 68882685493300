import { Component, OnInit } from '@angular/core';
import { UntypedFormGroup, UntypedFormControl } from '@angular/forms';
import { ProjectsService, RawInputTypes } from 'src/app/api/projects.service';
import { Router, ActivatedRoute } from '@angular/router';
import * as _ from 'lodash';
import { AdditionalTypes, IsRequired } from 'src/app/shared/helpers/formattedInput';
import { PagesName } from 'src/app/shared/helpers/pagesName';
import { PagesPublicName } from 'src/app/shared/helpers/pagesPublicName';
import { LayoutOrganizationService } from 'src/app/api/layout-organization.service';
import { ProjectManagerService } from 'src/app/api/project-manager.service';
import { LocalService } from 'src/app/api/local-storage.service';
import { TabsName } from 'src/app/shared/helpers/tabsName';
import { BehaviorSubject, forkJoin } from 'rxjs';
import { TeamsHttpService } from '../../teams-manager/teams-http.service';
import { FileGroupsService } from 'src/app/api/groups.service';
import { Files2Service } from 'src/app/api/files2.service';

@Component({
  selector: 'app-project-creation-form',
  templateUrl: './project-creation-form.component.html',
  styleUrls: ['../project-creation.component.scss', './project-creation-form.component.scss'],
})
export class ProjectCreationFormComponent implements OnInit {
  existingFilegroupInput;
  submitted = false;
  PagesPublicName = PagesPublicName;
  projectId;
  project;
  tagName = 'is_filegroup';
  fieldObjects = new BehaviorSubject<any>([]);
  fileGroupField;
  focusGeneField;
  popsegResultsField;
  inputsInProject = [];
  workflowId = {popseg: '246169070', biomark: '01f9f943-4ea6-4f09-8ffe-aee0a3792de0'};
  form: UntypedFormGroup;
  displayedFilegroups;
  focusGeneLists;
  selectedSolution:string = '';
  public isLoading = false;

  constructor(
    public projectsService: ProjectsService,
    public projectsManagerService: ProjectManagerService,
    private fileGroupsService: FileGroupsService,
    private fs: Files2Service,
    private teamsService: TeamsHttpService,
    private router: Router,
    private route: ActivatedRoute,
    public layout: LayoutOrganizationService,
    private localService: LocalService
  ) {


    if (this.route.snapshot.paramMap.get('projectId')) {
      _.set(this, 'projectId', this.route.snapshot.paramMap.get('projectId'));
    }
    if (this.route.snapshot.paramMap.get('country')) {
      _.set(this.projectsService, 'projectStorageCountry', this.route.snapshot.paramMap.get('country'));
    } else {
      _.set(this.projectsService, 'projectStorageCountry', this.localService.getFromLocalStorage('user', 'account_country'));
    }
    if(this.route.snapshot.paramMap.get('solution')) {
      this.selectedSolution = this.route.snapshot.paramMap.get('solution');
    }
    this.route.queryParams.subscribe((params) => {
      if (_.get(params, 'fromDebugger') === 'true') {
        projectsService.setFromDebugger(true);
      }
    });
  }

  ngOnInit() {
    this.form = this.createEmptyForm();
    
    let titleFieldObject = this.createTitleField();
    let teamFieldObject = this.createTeamField();
    let fields = [titleFieldObject, teamFieldObject]

    // FileGroup Selector
    if (!this.selectedSolution || this.selectedSolution == "popseg") {
      this.fileGroupField = this.createFilegroupField();
      fields.push(this.fileGroupField)
      this.refreshFileGroupList("");
    }
 
    if (this.selectedSolution == "biomark") {
      this.popsegResultsField = this.createPopsegResultsField();
      fields.push(this.popsegResultsField);
      this.refreshPopsegResults();
    }


    // FocusGeneList Selector
    if (this.selectedSolution == "popseg" || this.selectedSolution == "biomark") {
      this.focusGeneField = this.createFocusGeneField();
      fields.push(this.focusGeneField)
      this.refreshFocusGeneLists();
    }

    let descriptionFieldObject = this.createDescriptionField();
    fields.push(descriptionFieldObject);


    this.fieldObjects.next(fields);
    
    forkJoin({
      teams: this.retrieveTeams(),
      project: this.retrieveProject(),
    }).subscribe((res) => {
      //TEAMS
      teamFieldObject.retrievedAllowedValues.next(res.teams);
      let team = _.get(res.project, 'owner_team_id', '');
      let preselectedTeam = _.find(res.teams, (o) => {
        return o._key === team;
      });
      if (preselectedTeam) {
        teamFieldObject.retrievedValue.next(preselectedTeam);
      } else {
        teamFieldObject.retrievedValue.next(_.nth(res.teams, 0));
      }
      //PROJECTS
      this.project = res.project;
      let title = _.get(res.project, 'title');
      let description = _.get(res.project, 'description');
      if (title) {
        this.projectsService.setProjectName(title);
        titleFieldObject.retrievedValue.next(title);
      }
      if (description) {
        descriptionFieldObject.retrievedValue.next(description);
      }
      this.inputsInProject = _.get(res.project, 'input_list', []);
      this.existingFilegroupInput = _.nth(this.projectsService.filterProjectInputs(this.inputsInProject, this.tagName), 0);
    });
  }
  getAllowedValueUpdate(fieldobject) {
    return _.get(fieldobject, 'retrievedAllowedValues', new BehaviorSubject<any>(undefined));
  }
  getFieldObjectsLength() {
    return _.get(this, 'fieldObjects.value.length', 0);
  }
  refreshFileGroupList(groupID) {
    this.displayedFilegroups = [];
    this.fileGroupsService.listAllGroups('name', 'ownedByMe=true,sharedWithMe=true,sharedWithMyUserGroups=true', undefined).subscribe(
      (res) => {
        res.data = res.data.filter(fg => {return fg.name != "Autogenerated"})

        _.get(res, 'data').forEach((el) => {
          if (!el.owned_by_me && el.id) {
            _.set(el, 'name', el.name + ' –– shared with me  ');
          }
          if (el.country) {
            _.set(el, 'name', '(' + el.country + ') ' + el.name);
          }
        });
        
        this.displayedFilegroups = _.concat(this.displayedFilegroups, _.get(res, 'data'));
        this.fileGroupField.retrievedAllowedValues.next(this.displayedFilegroups);
        let preselectedFilegroup = _.find(this.displayedFilegroups, (o) => {
          if (groupID) {
            return o.id == groupID
          }
          return o.id === _.get(this.existingFilegroupInput, 'value');
        });
        if (preselectedFilegroup) {
          this.fileGroupField.retrievedValue.next(preselectedFilegroup);
        } else {
          this.fileGroupField.retrievedValue.next(_.nth(this.displayedFilegroups, 0));
        }

        this.displayedFilegroups.push({"name": "Create new cohort..."})
      },
      (error) => {
        this.displayedFilegroups = _.concat(this.displayedFilegroups, []);
        this.layout.toast('Impossible to get file groups', null, 8000, '', 'warning');
      }
    );
  }

  refreshFocusGeneLists() {
    this.focusGeneLists = [];
    this.projectsService.searchFiles({"or_filter":{"data_type":"focus_gene_list"},"and_filter":{"data_type":"focus_gene_list"}}).subscribe(
      res => {
        res.file_ids.forEach((fileId) => {
          this.fs.getPathFromFileId(fileId.substr(5), this.projectsService.getProjectStorageCountry()).subscribe(
            res2 => {
              this.focusGeneLists.push(res2.parts[res2.parts.length-1]);
              console.log(this.focusGeneLists);
              this.focusGeneField.retrievedAllowedValues.next(this.focusGeneLists);
              this.focusGeneField.retrievedValue.next(this.focusGeneLists[0]);

            },
            err2 => {
              console.warn(err2);
            }
          );
        })
      },
      err => {
        console.warn(err);
      }
    );
  }

  refreshPopsegResults() {
    this.isLoading = true;
    this.projectsService.searchFiles({
    "filegroup_id": "",
    "unique_clusters": false,
    "unique_metadata_keys": false,
    "workflow_name": "ndication",
    "data_type": "",
    "disease_filters": ""}).subscribe(
      res => {
        console.log(res.project_instances);
        let results = res.project_instances.map( i => { 
          return {
            name: i.project_name, 
            id: i.instance_filegroup_id,
            clusterFileId: i.cluster_file_id,
            uniqueClusters: i.unique_clusters
          }
        });
        setTimeout(() => {
          this.isLoading = false;
        this.popsegResultsField.retrievedAllowedValues.next(results);
        this.popsegResultsField.retrievedValue.next(results[0]);
      }, 500)
      },
      err => console.error(err)
    );
  }

  retrieveTeams = () =>
    new Promise((resolve, reject) => {
      this.teamsService.getTeams().subscribe(
        (res) => {
          resolve(res);
        },
        () => {
          this.layout.toast('Impossible to get teams', null, 8000, '', 'warning');
          reject();
        }
      );
    });

  retrieveProject = () =>
    new Promise((resolve, reject) => {
      if (this.projectId) {
        this.projectsService.projectGet(this.projectId).subscribe(
          (res) => {
            resolve(res);
          },
          () => {
            this.layout.toast('Impossible to get project', null, 8000, '', 'warning');
            reject();
          }
        );
      } else {
        this.projectsService.setProjectName('');
        resolve({});
      }
    });
  getFilegroupFieldObject() {
    return _.get(this, 'filegroupFieldObject', null);
  }

  getFormValidity() {
    return _.get(this, 'form.valid', false);
  }

  createTeamField = () => {
    return {
      formControlName: 'team',
      type: AdditionalTypes.Object,
      description: 'Select a team',
      fitToContent: false,
      is_array: true,
      multiple_selection: false,
      retrievedAllowedValues: new BehaviorSubject<any>(undefined),
      retrievedValue: new BehaviorSubject<any>(undefined),
    };
  };
  createDescriptionField = () => {
    return {
      formControlName: 'description',
      placeholder: 'Description ...',
      type: AdditionalTypes.Paragraph,
      description: 'Description',
      fitToContent: false,
      showIcon: 'fas fa-info-circle',
      validations: [IsRequired],
      retrievedValue: new BehaviorSubject<any>(undefined),
    };
  };
  createTitleField = () => {
    return {
      formControlName: 'title',
      placeholder: 'Project Name',
      type: AdditionalTypes.Title,
      description: 'Name',
      fitToContent: false,
      showIcon: 'fas fa-info-circle',
      validations: [IsRequired],
      retrievedValue: new BehaviorSubject<any>(undefined),
    };
  };
  getFieldObjects() {
    return _.get(this, 'fieldObjects.value', []);
  }

  getFormError() {
    return this.getFormValidity() ? '' : 'Please verify your form.';
  }
  createFilegroupField = () => {
    return {
      formControlName: 'filegroup',
      type: AdditionalTypes.Object,
      description: 'Cohort',
      fitToContent: false,
      is_array: true,
      multiple_selection: false,
      retrievedValue: new BehaviorSubject<any>(undefined),
      retrievedAllowedValues: new BehaviorSubject<any>(undefined),
    };
  };
  createFocusGeneField = () => {
    return {
      formControlName: 'focusgene',
      type: AdditionalTypes.Object,
      description: 'Focus Gene List',
      fitToContent: false,
      is_array: true,
      multiple_selection: false,
      retrievedValue: new BehaviorSubject<any>(undefined),
      retrievedAllowedValues: new BehaviorSubject<any>(undefined),
    };
  };

  createPopsegResultsField = () => {
    return {
      formControlName: 'popsegresults',
      type: AdditionalTypes.Object,
      description: 'Previous Results',
      fitToContent: false,
      is_array: true,
      multiple_selection: false,
      retrievedValue: new BehaviorSubject<any>(undefined),
      retrievedAllowedValues: new BehaviorSubject<any>(undefined),
    };
  };
  createEmptyForm() {
    return new UntypedFormGroup({
      title: new UntypedFormControl(),
      description: new UntypedFormControl(),
      filegroup: new UntypedFormControl(),
      team: new UntypedFormControl(),
      focusgene: new UntypedFormControl(),
      popsegresults: new UntypedFormControl(),
    });
  }
  getForm() {
    return _.get(this, 'form', null);
  }
  setProjectStorageCountry(country) {
    _.set(this.projectsService, 'projectStorageCountry', country);
  }
  createProject() {
    if (this.form.valid) {
      this.submitted = true;
      const description = this.getForm().value.description;
      const title = this.getForm().value.title;
      const filegroup = this.getForm().value.filegroup;
      const team = this.getForm().value.team;
      const focusGeneFileId = _.get(this.getForm().value, 'focusgene.id');
      const clusterFileId = _.get(this.getForm().value, 'popsegresults.clusterFileId')
      const uniqueClusters = _.get(this.getForm().value, 'popsegresults.uniqueClusters')
      const selectedInstanceFilegroup = _.get(this.getForm().value, 'popsegresults.id')

      if (!filegroup || !filegroup.id) {
        if (!this.projectId) {
          this.setProjectStorageCountry(this.localService.getFromLocalStorage('user', 'account_country', 'US'));
        } else {
          this.setProjectStorageCountry(_.get(this.project, 'host_country'));
        }
      } else {
        if (!this.projectId) {
          this.setProjectStorageCountry(_.get(filegroup, 'country'));
        } else {
          if (filegroup.country !== _.get(this.project, 'host_country')) {
            this.layout.toast(
              'Project location is <b>' +
                _.get(this.project, 'host_country') +
                '</b>. Please choose a filegroup located in ' +
                _.get(this.project, 'host_country') +
                ' too or create another project.',
              null,
              5000,
              '',
              'danger'
            );
            return;
          } else {
            this.setProjectStorageCountry(_.get(filegroup, 'country', null));
          }
        }
      }
      if (this.projectId) {
        this.submitProject(title, description, filegroup, team);
      } else {
        this.submitNewProject(title, description, filegroup, team, focusGeneFileId, clusterFileId, uniqueClusters, selectedInstanceFilegroup);
      }
      this.getForm().reset();
    }
  }
  
  onChange(field) {
    if (this.form.value.filegroup && this.form.value.filegroup.name === "Create new cohort..." && field.formControlName == "filegroup") {
      this.layout.open('modalGroup');
    }
  }

  submitProject(title, description, filegroup, team) {
    this.projectsService.updateProject({ title, description }, this.projectId).subscribe(
      (res) => {
        this.projectsService.setProjectName(title);
      },
      (error) => {
        this.layout.toast('Error when updating project', null, 8000, '', 'warning');
      }
    );
    if (_.get(team, '_key')) this.projectsService.associateTeam(this.projectId, team._key).subscribe();
    if (this.existingFilegroupInput && _.get(this, 'existingFilegroupInput.id') === _.get(filegroup, 'id')) {
      this.router.navigate(
        [PagesName.ProjectCreation, PagesName.ChooseWorkflow, this.projectId, this.projectsService.getProjectStorageCountry()],
        {
          queryParams: {
            fromDebugger: this.projectsService.getFromDebugger(),
            filegroupId: _.get(filegroup, 'id'),
            teamId: _.get(team, '_key'),
          },
        }
      );
      return;
    }
    if (this.existingFilegroupInput && _.get(this, 'existingFilegroupInput.id') !== _.get(filegroup, 'id')) {
      this.projectsService.removeInput(this.projectId, _.get(this, 'existingFilegroupInput.id')).subscribe(
        () => {
          return undefined;
        },
        () => {
          this.layout.toast('Error when deleting previously added file group input', null, 8000, '', 'warning');
        }
      );
    }

    if (_.get(filegroup, 'id')) {
      const tags = { is_filegroup: true };
      this.projectsService.appendInput(this.projectId, RawInputTypes.FileGroup, _.get(filegroup, 'id'), {}, tags).subscribe(
        (res) => {},
        (error) => this.layout.toast('Error when setting file group input', null, 8000, '', 'warning')
      );
    }
    this.router.navigate(
      [PagesName.ProjectCreation, PagesName.ChooseWorkflow, this.projectId, this.projectsService.getProjectStorageCountry()],
      {
        queryParams: {
          fromDebugger: this.projectsService.getFromDebugger(),
          filegroupId: _.get(filegroup, 'id'),
          teamId: _.get(team, '_key'),
        },
      }
    );
  }

  submitNewProject(title, description, filegroup, team, focusGeneFileId, clusterFileId,uniqueClusters, selectedInstanceFilegroup) {
    let self = this;
    this.projectsService.createProject({ title, description }).subscribe(
      (res) => {
        const id = res.id;

        // Team Association
        if (!!this.projectsService.preselectedSolution) {
          team = {_key: "a63dba48-3acf-4c59-b33f-4bbf98aa69ab"}
        }
        if (_.get(team, '_key')) this.projectsService.associateTeam(id, team._key).subscribe();

        // Standard Filegroup Input
        if (_.get(filegroup, 'id')) {
          const tags = { is_filegroup: true };
          this.projectsService.appendInput(id, RawInputTypes.FileGroup, _.get(filegroup, 'id'), {}, tags).subscribe(
            () => {
              return undefined;
            },
            () => this.layout.toast('Error when setting file group input', null, 8000, '', 'warning')
          );
        }

        // Popseg Pre-selected (guided user flow)
        if (this.projectsService.preselectedSolution == "popseg") {
          this.submitPopSeg(id, focusGeneFileId,filegroup, team);
          return;

        // Biomark Pre-selected (guided user flow)
        } else if (this.projectsService.preselectedSolution == "biomark") {
          this.submitBioMark(id, focusGeneFileId,clusterFileId,uniqueClusters,selectedInstanceFilegroup, team);
          return;
        }

        // No workflow Selected
        const path = [PagesName.ProjectCreation, PagesName.ChooseWorkflow, id, this.projectsService.getProjectStorageCountry()];
        this.router.navigate(path, {
          queryParams: {
            fromDebugger: this.projectsService.getFromDebugger(),
            filegroupId: _.get(filegroup, 'id'),
            teamId: _.get(team, '_key'),
          },
        });

      },
      () => this.layout.toast('Error when creating project', null, 8000, '', 'warning')
    );
  }

  submitBioMark(projectId, focusGeneFileId, clusterFileId, uniqueClusters, selectedInstanceFilegroup, team) {
    this.projectsService.appendInput(projectId, RawInputTypes.File, focusGeneFileId).subscribe(() => {
      this.fileGroupsService.postGroup({
        description: '',metadata: { hidden: 'true', cluster_file_id: clusterFileId}, name: "Autogenerated" },
        'response',this.projectsService.getProjectStorageCountry()).subscribe((res) => {
          this.fileGroupsService.filegroupPatch(this.projectsService.getProjectStorageCountry(), selectedInstanceFilegroup, {"cluster_file_id": clusterFileId}, "metadata").subscribe(() => {
            this.projectsService.appendInput(projectId, RawInputTypes.FileGroup, selectedInstanceFilegroup).subscribe(() => {
            const path = [PagesName.ProjectCreation, PagesName.ConfigureWorkflow, projectId, this.workflowId['biomark'], this.projectsService.getProjectStorageCountry()];
            this.router.navigate(path, {
             queryParams: {
               fromDebugger: this.projectsService.getFromDebugger(),
               filegroupId: selectedInstanceFilegroup,
               teamId: _.get(team, '_key'),
               clusterFileId: clusterFileId,
               uniqueClustersFileGroupId: res.id,
             },
           });
          }, err=> console.error(err))
        }, err=> console.error(err))
      }, err => console.error(err));
    }, err => console.error(err));
  }

  submitPopSeg(projectId, focusGeneFileId, filegroup, team) {
    this.isLoading = true;
    this.projectsService.appendInput(projectId, RawInputTypes.File, focusGeneFileId).subscribe(() => {
      this.projectsService.triggerWorkflow(projectId, this.workflowId["popseg"]).subscribe(() => {
        const path = [PagesName.ProjectManager];
        this.isLoading = false;
        this.router.navigate(path, {
          queryParams: {
            fromDebugger: this.projectsService.getFromDebugger(),
            filegroupId: _.get(filegroup, 'id'),
            teamId: _.get(team, '_key'),
          },
        });
        this.layout.toast('Project successfully created', null, 8000, '', 'success')
      }, err=> console.error(err))
    }, err => console.error(err))
  }


  previous() {
    this.submitted = true;
    if (this.projectsService.getFromDebugger()) {
      this.router.navigate([PagesName.WorkflowDebugger]);
    } else {
      this.router.navigate([PagesName.ProjectManager]);
    }
  }

  onFileGroupCreated = (fileGroupId) => {
    this.refreshFileGroupList(fileGroupId.id);
  }
}
