<div class="columns">
  <div class="column right">
    <button *ngIf="canCreateProject" class="btn btn-dark" (click)="createProject()">Create Project</button>
    <button *ngIf="!canCreateProject" ngbTooltip="Subscription Needed" class="btn btn-dark disabled">Create Project</button>
    <div class="images" *ngIf="video">
      <iframe style="width:100%;height:100%" [src]='getVideo()' title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
    </div>
  </div>

  <div class="column">
    <div *ngIf="atlassianId">
      <documentation-atlassian
        [docAtlassianID]="getAtlassianID()"
      ></documentation-atlassian>
    </div>
  </div>
</div>