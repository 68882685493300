import { Component, OnDestroy } from '@angular/core';
import { ProjectsService } from '../../api/projects.service';
import { SessionStorage } from 'ngx-webstorage';
import { LayoutOrganizationService } from 'src/app/api/layout-organization.service';
import * as _ from 'lodash';
import { Action, ProjectItemAction, ProjectManagerService } from 'src/app/api/project-manager.service';
import { ActivatedRoute } from '@angular/router';
import { ShareService } from 'src/app/api/share.service';
import { TabsPublicName } from 'src/app/shared/helpers/tabsPublicName';
import { TabsName } from 'src/app/shared/helpers/tabsName';
import { PagesPublicName } from 'src/app/shared/helpers/pagesPublicName';
import { PermissionsHttpService } from 'src/app/shared/permissions-http-service/permissions-http.service';
import { ItemType } from 'src/app/shared/helpers/itemType';
import { SortType } from 'src/app/shared/helpers/sortType';
import { SortName } from 'src/app/shared/helpers/sortName';

@Component({
  selector: 'app-project-manager',
  styleUrls: ['../../shared/layout/managers.scss', './project-manager.component.scss'],
  templateUrl: './project-manager.component.html',
})
export class ProjectManagerComponent implements OnDestroy {
  @SessionStorage() projects: any;
  createProject = false;
  canExploreProjects = false;
  _ = _;
  usergroups = [];
  Action = Action;
  TabsName = TabsName;
  TabsPublicName = TabsPublicName;
  SortType = SortType;
  SortName = SortName;
  PagesPublicName = PagesPublicName;
  ItemType = ItemType;
  ProjectItemAction = ProjectItemAction;
  previousUrlState = { id: '', viewTab: '' };
  loading = true;
  constructor(
    public projectsService: ProjectsService,
    public shareService: ShareService,
    public projectManagerService: ProjectManagerService,
    public l: LayoutOrganizationService,
    public route: ActivatedRoute,
    public permissions: PermissionsHttpService
  ) {
    this.l.isTopBarOpen = true;
    this.l.isTopNavOpen = true;
    let func = this.permissions.checkPermission({
      permissions: {
        createProject: {},
        exploreProjects: {},
      },
    });
    if (func) {
      func.subscribe((res) => {
        this.canExploreProjects = res.exploreProjects;
        this.createProject = res.createProject;
        this.loading = false;
      });
    }
    if (_.get(this, 'projectManagerService.actionEvent')) {
      this.projectManagerService.actionEvent.emit([Action.InitManager]);
    }
    if (_.get(this, 'route.params')) {
      this.route.params.subscribe((params) => {
        const viewTab = params['viewTab'];
        if (viewTab === TabsName.MyProjects) {
          this.projectManagerService.actionEvent.emit([Action.SelectTab, TabsName.MyProjects]);
        } else if (viewTab === TabsName.SharedProjects) {
          this.projectManagerService.actionEvent.emit([Action.SelectTab, TabsName.SharedProjects]);
        } else if (viewTab === TabsName.DeletedProjects) {
          this.projectManagerService.actionEvent.emit([Action.SelectTab, TabsName.DeletedProjects]);
        }
      });
    }
  }

  ngOnDestroy() {
    this.l.isTopBarOpen = false;
    this.l.isTopNavOpen = false;
  }
}
