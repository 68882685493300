<div class="d-flex justify-content-between align-items-center" [class]="'depth-' + depth">
  <div class="d-flex align-items-center">
    <input *ngIf="selectable" type="checkbox" class="mr-2" />
    <div class="d-flex align-items-center">
      <div class="title">{{ category.name }}</div>
      <sup class="count ml-1">{{ category.count }}</sup>
    </div>
  </div>
  <div class="d-flex align-items-center">
    <div *ngIf="category?.children?.length" class="mr-2">
      <app-filter-radio-group [name]="category.name"></app-filter-radio-group>
    </div>
    <app-expander-button [ngStyle]="{ 'visibility': category?.children?.length ? 'visible' : 'hidden' }"
      (clicked)="handleExpand()"></app-expander-button>
  </div>
</div>
<div class="children pl-3" *ngIf="expanded">
  <app-category-filter *ngFor="let child of category.children" [category]="child" [selectable]="true" [depth]="depth + 1">
  </app-category-filter>
</div>
