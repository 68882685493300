<app-header>
  <div class="h-100 w-100 p-3 d-flex align-items-center justify-content-between text-light">
    <div class="d-flex align-items-center">
      <div class="font-weight-bold">{{PagesPublicName.DataManager}} </div>
    </div>
  </div>
</app-header>
<!-- <app-header2>
  <div class="h-100 w-100 d-flex align-items-center justify-content-between text-light">

   <div class="h-100 w-100 p-5 d-flex align-items-center justify-content-between text-light">
    <div class="d-flex align-items-center">
      <div class=" icon-bg soft-border d-flex align-items-center justify-content-center ">
        <i class="fad fa-file text-primary  m-2" aria-hidden="true"></i>
      </div>
      <h3 class="m-0 ml-3">{{PagesPublicName.DataManager}} </h3>
      <div class="font-weight-bold">{{PagesPublicName.DataManager}} </div>

    </div>
     <div class="d-flex align-items-center" >
      <i class="fas fa-bell mr-2" aria-hidden="true"></i>
      <mims-initials-bubble ngbTooltip="{{userName}}" theme='dark' tooltipClass="custom-tooltip" placement="top"
      [fullName]='userName'></mims-initials-bubble>
      </div>
  </div>
</app-header2> -->
<app-horizontal-nav-tab-2 [selectedTab]='dataManager.activeTab'
  (valueChanged)="trigger({action: Action.ChangeTab, destination: $event});trigger({action: Action.InitManager})"
  [dataSource]="dataManager.tabsDataSource">
  <mims-select *ngIf="_.get(dataManager, 'countriesDataSource.length') > 1" [selectedOption]="dataManager.activeCountry"
    [dataSource]="dataManager.countriesDataSource"
    (valueChanged)="trigger({action: Action.ChangeCountry, destination: $event});trigger({action: Action.InitManager})">
  </mims-select>
</app-horizontal-nav-tab-2>
<app-content *ngIf="dataManager" [isHeader]='true' [isTab]='true'>
  <div class="w-100 scrollable h-100 pr-2 bg-ivory">
    <div class='w-100 p-5 '>
      <div class='w-75 mx-auto' *ngIf='_.get(dataManager, "activeTab.value") !== TabsName.DeletedData'>
        <div class="mb-3 d-flex align-items-end">
          <div *ngIf="!dataManager.canPerformAction(Action.CreateCohort)" class="font-weight-bold text-dark mr-auto">
            Cohorts
          </div>
          <button ngbTooltip="{{dataManager.getTooltip(Action.CreateCohort)}}"
            *ngIf="dataManager.canPerformAction(Action.CreateCohort)" tooltipClass="custom-tooltip" placement="bottom"
            (click)="dataManager.canPerformAction(Action.CreateCohort) ? dataManager.actionEvent.emit({action : Action.CreateCohort} ): ''"
            class="smooth-border button btn text-center mr-auto btn-primary text-light">
            <span class="cursor-info">Create cohort
            </span>
          </button>
          <app-sort-by class='mr-2' name="Name"
            [selected]="dataManager.getFromLocalStorage('cohortSortByType') === SortType.Name"
            (onClick)="handleSortBy(SortType.Name, dataManager.getFromLocalStorage('cohortSortByDirection'), ItemType.Cohort)"
            [direction]="dataManager.getFromLocalStorage('cohortSortByDirection')"></app-sort-by>
          <app-sort-by name="Date of modification"
            [selected]="dataManager.getFromLocalStorage('cohortSortByType') === SortType.DateModification"
            (onClick)="handleSortBy(SortType.DateModification, dataManager.getFromLocalStorage('cohortSortByDirection'), ItemType.Cohort)"
            [direction]="dataManager.getFromLocalStorage('cohortSortByDirection')"></app-sort-by>
        </div>
        <div class="w-100 filter-separator"> </div>
        <mims-files-2 class='p-5 d-block' [itemType]="ItemType.Cohort" [viewTab]="dataManager.activeTab"
          [displayedFiles]="dataManager._getAsync('displayedCohorts')" [bulkSelection]="[]" [singleSelection]="{}"
          (itemAction)="dataManager.itemActionEvent.emit($event)">
        </mims-files-2>
      </div>
      <div class='w-75 mx-auto'>
        <div class=" mb-3 d-flex align-items-end">
          <div *ngIf="!dataManager.canPerformAction(Action.CreateFocusGeneList)"
            class="font-weight-bold text-dark mr-auto">
            Focus gene lists
          </div>
          <button ngbTooltip="{{dataManager.getTooltip(Action.CreateFocusGeneList)}}"
            *ngIf="dataManager.canPerformAction(Action.CreateFocusGeneList)" tooltipClass="custom-tooltip"
            placement="bottom"
            (click)="dataManager.canPerformAction(Action.CreateFocusGeneList) ? dataManager.actionEvent.emit({action : Action.CreateFocusGeneList} ): ''"
            class="smooth-border button btn text-center mr-auto  btn-primary text-light">
            <span class="cursor-info">Create focus gene lists
            </span>
          </button>
          <app-sort-by class='mr-2' name="Name"
            [selected]="dataManager.getFromLocalStorage('focusGeneSortByType') === SortType.Title"
            (onClick)="handleSortBy(SortType.Title, dataManager.getFromLocalStorage('focusGeneSortByDirection'), ItemType.FocusGeneList)"
            [direction]="dataManager.getFromLocalStorage('focusGeneSortByDirection')"></app-sort-by>
          <app-sort-by name="Date of modification"
            [selected]="dataManager.getFromLocalStorage('focusGeneSortByType') === SortType.DateModification"
            (onClick)="handleSortBy(SortType.DateModification, dataManager.getFromLocalStorage('focusGeneSortByDirection'), ItemType.FocusGeneList)"
            [direction]="dataManager.getFromLocalStorage('focusGeneSortByDirection')"></app-sort-by>
        </div>
        <div class="w-100 filter-separator"> </div>
        <mims-files-2 class='p-5 d-block' [itemType]="ItemType.FocusGeneList" [viewTab]="dataManager.activeTab"
          [displayedFiles]="dataManager._getAsync('displayedFiles')" [bulkSelection]="[]" [singleSelection]="{}"
          (itemAction)="dataManager.itemActionEvent.emit($event)">
        </mims-files-2>
      </div>
    </div>
  </div>
  <div id="info-content" [class.active]="l.isFilesInfosModalOpen">
    <div id="modalFilesInfos" class='m-3'>
      <div class="d-flex justify-content-between my-4">
        <div class="d-flex align-items-center">
          <div class="icon-bg d-flex align-items-center justify-content-center   mr-3 smooth-border">
            <i class="fa-solid  fa-grid-2 " aria-hidden="true"></i>
          </div>
          <div class='font-weight-bold'>Info</div>
        </div>
        <button type="button" class="close" aria-label="Close" (click)="l.close('modalFilesInfos')">
          <span><i class="fa-regular fa-1x  fa-circle-xmark text-primary" aria-hidden="true"></i></span>
        </button>
      </div>
      <mims-loader *ngIf="!item" class='w-100 pos-relative' [enableBackDrop]='false' [isLoading]="!item">
      </mims-loader>
      <div class="pannel-content">

        <app-data-info *ngIf="item" [country]="dataManager.activeCountry" [item]="item"
          [teamsDataSource]='_.get(dataManager, "teamsDataSource.value")' [itemType]="_.get(item, 'kind')">
        </app-data-info>
      </div>
    </div>
  </div>
  <div id="share-content" [class.active]="l.isFilesShareModalOpen">
    <div id="modalFilesShare" class='m-3'>
      <div class="d-flex justify-content-between my-4">
        <div class="d-flex align-items-center">
          <div class="icon-bg d-flex align-items-center justify-content-center   mr-3 smooth-border">
            <i class="fa-solid  fa-grid-2 " aria-hidden="true"></i>
          </div>
          <div class='font-weight-bold'>Share</div>
        </div>
        <button type="button" class="close" aria-label="Close" (click)="l.close('modalFilesShare')">
          <span><i class="fa-regular fa-1x  fa-circle-xmark text-primary" aria-hidden="true"></i></span>
        </button>
      </div>
      <mims-loader *ngIf="!item" class='w-100 pos-relative' [enableBackDrop]='false' [isLoading]="!item">
      </mims-loader>
      <div class="pannel-content d-flex flex-column">
        <ul class='list-unstyled '>
          <small>
            <li class='mb-4 text-uppercase'><span class='text-info font-weight-bold'>Name of
                {{_.get(item, 'kind') === ItemType.FileGroups ? "cohort" : "file"}} </span>
              <div class='font-weight-bold my-2'>{{_.get(item, 'title', _.get(item, 'name'))}}</div>
            </li>
            <li class='my-4 text-uppercase'>
              <span class='text-info font-weight-bold'>Owner </span>
              <div class='w-100 align-items-center my-2 d-flex'>
                <mims-initials-bubble ngbTooltip="{{_.get(item, 'owner.name')}}" theme='dark'
                  tooltipClass="custom-tooltip" placement="top" [fullName]="_.get(item, 'owner.name')">
                </mims-initials-bubble>
                <span class='font-weight-bold'>
                  {{_.get(item, 'owner.name')}} </span>
              </div>
            </li>
          </small>
        </ul>
        <app-share *ngIf="_.get(dataManager, 'getAutocompleteData')" [autocompleteData]="dataManager.getAutocompleteData()"
          [style.height.rem]="30" (itemEventNew)='triggerItem($event)' [item]="item" [type]="itemType">
        </app-share>
        <div class="mt-5 w-100">
          <div class="d-flex flex-nowrap">
            <button type="button" class="flex-fill mx-2 btn btn-dark" (click)="dataManager.closePannel()">
              Cancel
            </button>
            <button type="button" class="flex-fill mx-2 btn btn-dark"
              [disabled]="!_.get(dataManager, 'getUsersSelected().length')"
              (click)="triggerItem({item: item, itemAction : ItemAction.Share, destination: dataManager.getUsersSelected()})">
              Confirm
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</app-content>
<app-group-creation-form *ngIf="l && l.isGroupModalOpen"></app-group-creation-form>
