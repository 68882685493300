import { Component, Input } from '@angular/core';
@Component({
  selector: 'mims-flag',
  templateUrl: './flag.component.html',
  styleUrls: ['./flag.component.scss'],
})
export class FlagComponent{
  @Input() country;
  // Country = Country;
  // flagExists = false;
  // ngOnInit()  {
  //   this.flagExists = this.fileExists(this.getSrc(this.country));
  // }
  // getSrc(country) {
  //   return 'assets/images/flags/' + country + '.svg';
  // }
  // getAlternativeSrc() {
  //   return 'assets/images/flags/' + Country.Unknown + '.svg';
  // }
  // getUrl(src) {
  //   return 'url(' + src + ')';
  // }
  // fileExists(src) {
  //   var http = new XMLHttpRequest();
  //   http.open('HEAD', src, false);
  //   http.send();
  //   return http.status != 404;
  // }
}
