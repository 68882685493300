import { Component, Input, OnInit } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import {Location} from '@angular/common';

import * as _ from 'lodash';
import { AdditionalTypes, InputJson, IsRequired } from 'src/app/shared/helpers/formattedInput';
import { PagesName } from 'src/app/shared/helpers/pagesName';
import { PagesPublicName } from 'src/app/shared/helpers/pagesPublicName';
import { ProjectsService, WorkflowInput, getVisible, RawInputTypes } from 'src/app/api/projects.service';
import { BehaviorSubject, Subscription } from 'rxjs';
import { LayoutOrganizationService } from 'src/app/api/layout-organization.service';
import { FileGroupsService } from 'src/app/api/groups.service';

@Component({
  selector: 'app-project-configuration',
  templateUrl: './project-configuration.component.html',
  styleUrls: ['../project-creation.component.scss', './project-configuration.component.scss'],
})
export class ProjectConfigurationComponent implements OnInit {
  @Input() projectId?;
  @Input() workflowId?;
  tagName = 'is_config';
  submitted = false;
  inputsInProject = new BehaviorSubject<any>([]);
  inputsInWorkflow = new BehaviorSubject<any>(null);
  form: UntypedFormGroup;
  fieldObjects = new BehaviorSubject<any>([]);
  PagesPublicName = PagesPublicName;
  workflowInputsSubscription: Subscription;
  projectInputsSubscription: Subscription;
  filegroupId;
  teamId;
  clusterFileId;
  uniqueClustersFileGroupId;
  doneUploading = new BehaviorSubject<any>(undefined);
  doneUploadingSubscription: Subscription;
  isBiomark= false;
  creditCost = 0;
  public isLoading
  constructor(
    public projectsService: ProjectsService,
    private router: Router,
    private route: ActivatedRoute,
    private layout: LayoutOrganizationService,
    private fileGroupsService: FileGroupsService,
    private location: Location

  ) {
    if (!this.projectId) _.set(this, 'projectId', this.route.snapshot.paramMap.get('projectId'));
    if (!this.workflowId) _.set(this, 'workflowId', this.route.snapshot.paramMap.get('workflowId'));
    if (this.route.snapshot.paramMap.get('country')) {
      _.set(this.projectsService, 'projectStorageCountry', this.route.snapshot.paramMap.get('country'));
    }
    this.route.queryParams.subscribe((params) => {
      if (params['fromDebugger'] === 'false' || !_.get(params, 'fromDebugger')) {
        projectsService.setFromDebugger(false);
      } else if (params['fromDebugger'] === 'true') {
        projectsService.setFromDebugger(true);
      }
      _.set(this, 'filegroupId', params['filegroupId']);
      _.set(this, 'teamId', params['teamId']);
      this.uniqueClustersFileGroupId = params['uniqueClustersFileGroupId']
    });
  }

  ngOnInit(): void {
    this.projectsService.getWorkflow(this.workflowId).subscribe(
      (res) => {
        // todo: remove this biomark hard-code activation variable
        if(_.get(res, 'name', '').toLowerCase().indexOf('biomark') != -1) {
          this.isBiomark = true;
        }

        // set inputs
        let inputs = _.get(res, 'inputs', {});
        inputs = _.pickBy(inputs, getVisible);
        this.inputsInWorkflow.next(inputs);

        // set credit cost
        if (this.filegroupId) {
          const creditThresholds = _.get(res, "credit_thresholds", {});
          if (Object.keys(creditThresholds).length > 0) {
            this.updateCreditCost(creditThresholds);
          }
        }

      },
      () => {
        this.inputsInWorkflow.next({});
        this.layout.toast('Error when getting workflow inputs', null, 8000, '', 'warning');
      }
    );

    this.workflowInputsSubscription = this.inputsInWorkflow.subscribe((res) => {
      let fieldObjects = [];
      this.getProjectInputs(this.tagName);

      if (_.isEmpty(res)) {
        this.fieldObjects.next(fieldObjects);
        return;
      }
      this.form = this.projectsService.createEmptyForm(res);
      for (const [key, value] of Object.entries(res)) {
        let w = value as WorkflowInput;
        let input: InputJson = {
          formControlName: key,
          originalJson: w,
          type: _.get(w, 'type'),
          description: _.get(w, 'description'),
          is_array: _.get(w, 'tags.is_array'),
          cluster_selection: _.get(w, 'tags.cluster_selection'),
          multiple_selection: _.get(w, 'tags.multiple_selection'),
          allowed_values: _.words(_.get(w, 'tags.allowed_values', ''), /[^,]+/g),
          allowed_values_descriptions: _.words(_.get(w, 'tags.allowed_values_descriptions', ''), /[^,]+/g),
          fitToContent: true,
          showIcon: 'fa fa-info-circle',
          validations: _.get(w, 'optional') ? [] : [IsRequired],
          retrievedValue: new BehaviorSubject<any>(undefined),
          retrievedAllowedValues: new BehaviorSubject<any>(undefined),
          retrievedAllowedValuesDescription: new BehaviorSubject<any>(undefined),
        };
        if (this.isBiomarkerInput(key)) {
          _.set(input, 'useSelect', true);
          _.set(input, 'max_select_height', 3);
        }
        fieldObjects.push(input);
      }

      // Sort fields to show required ones first
      fieldObjects = fieldObjects.sort((a,b) => {
        if (!a.originalJson.optional && b.originalJson.optional) {
          return -1;
        } else if (a.originalJson.optional && !b.originalJson.optional) {
          return 1;
        }
        return 0;
      });

      this.fieldObjects.next(fieldObjects);
      let fgId = this.filegroupId;
      if (this.uniqueClustersFileGroupId) {
        fgId = this.uniqueClustersFileGroupId;
      }

      console.log(fgId);
      this.projectsService.getClustersNumber(fgId).subscribe((res) => {

        if (this.uniqueClustersFileGroupId) {
          this.fileGroupsService.deleteGroup(fgId,this.projectsService.getProjectStorageCountry()).subscribe(() => {}, err => console.error(err));
        }
        
        let array = [];
        let arrayDescription = [];

        const uniqueClusters = _.get(res, 'unique_clusters', undefined)
        if (uniqueClusters) {
          uniqueClusters.split(",").forEach(cluster => {
            array.push(cluster);
            arrayDescription.push('Cluster ' + cluster);
          });
        } else {
          const clusterCount = _.get(res, 'counts.clusters');
          for (let i = 0; i < clusterCount; i++) {
            array.push(JSON.stringify(i));
            arrayDescription.push("Cluster "+ i);
          }
        }

        this.fieldObjects.value.forEach(field => {
          if(field.cluster_selection) {
            field.retrievedAllowedValues.next(array);
            field.retrievedAllowedValuesDescription.next(arrayDescription);
          }
        });

        this.projectInputsSubscription = this.inputsInProject.subscribe((res) => {
          if (_.isEmpty(res)) {
            return;
          }
          this.projectsService.setRetrievedValue(res, this.getFieldObjects(false, false));
        });
      });
    });
  }
  getFieldObject(formControlName) {
    return _.find(this.fieldObjects.value, (o) => {
      return o.formControlName === formControlName;
    });
  }
  
  updateCreditCost(creditThresholds) {
    this.projectsService.searchFiles({filegroup_id: this.filegroupId}).subscribe(searchRes => {
      const patients = _.get(searchRes, "counts.patients", 0);
      
      if (patients > 0 && Object.keys(creditThresholds).length > 0) {
        let cost = 1;
        Object.keys(creditThresholds).forEach(threshold => {
          if(patients >= threshold) {
            cost = creditThresholds[threshold];
          }
        });
        this.creditCost = cost;
      }
    });
  }

  isBiomarkerInput(formControlName) {
    return _.get(this, 'inputsInWorkflow.value['+formControlName+'].tags.cluster_selection', false);
  }

  onCheckCluster(fieldObject) {
    let activatedClustersValue = _.get(this.form, 'controls.activated.value', []);
    let deactivatedClustersValue = _.get(this.form, 'controls.deactivated.value', []);
    if (_.intersection(activatedClustersValue, deactivatedClustersValue).length) {
      if (fieldObject.formControlName === 'activated') {
        _.remove(deactivatedClustersValue, function (n) {
          return _.includes(activatedClustersValue, n);
        });
        this.getFieldObject('not_activated').retrievedValue.next(deactivatedClustersValue);
      }
      if (fieldObject.formControlName === 'not_activated') {
        _.remove(activatedClustersValue, function (n) {
          return _.includes(deactivatedClustersValue, n);
        });
        this.getFieldObject('activated').retrievedValue.next(activatedClustersValue);
      }
    }
  }

  getProjectInputs(tagName) {
    this.projectsService.projectGet(this.projectId).subscribe(
      (res) => {
        if (!this.projectsService.getProjectName()) {
          this.projectsService.setProjectName(res.title);
        }
        let inputsInProject = _.get(res, 'input_list', []);
        inputsInProject = this.projectsService.filterProjectInputs(inputsInProject, tagName);
        this.inputsInProject.next(inputsInProject);
      },
      () => {
        this.layout.toast('Error when getting projet inputs', null, 8000, '', 'warning');
      }
    );
  }

  getFormError() {
    if (!this.getNextValidity() && !this.getFormValidity()) {
      return 'Please verify your form.';
    }
    if (!this.getNextValidity() && this.getFormValidity()) {
      return 'Please wait until all inputs are uploaded.';
    }
    return '';
  }
  getWorkflowName() {
    return _.get(this, 'workflow.name', null);
  }
  getForm() {
    return _.get(this, 'form', null);
  }
  getNextValidity() {
    return this.doneUploading.value !== false && this.getFormValidity();
  }
  getFormValidity() {
    return _.get(this, 'form.status', false) === 'VALID';
  }
  getFieldObjects(optionalOnly:boolean, requiredOnly: boolean) {
    const fieldObjects = _.get(this, 'fieldObjects.value', [])
    if (optionalOnly) {
      return _.filter(fieldObjects, o => o.originalJson.optional);
    }
    if (requiredOnly) {
      return _.filter(fieldObjects, o => !o.originalJson.optional);
    }
    return fieldObjects;
  }

  getFieldObjectsLength() {
    return _.get(this, 'fieldObjects.value.length', []);
  }

  getInputsInProjects() {
    return _.get(this, 'inputsInProject.value', []);
  }
  getFormControl(form, fieldObject) {
    return _.get(form, 'controls[' + this.getFormControlName(fieldObject) + ']');
  }
  getFormControlName(fieldObject) {
    return _.get(fieldObject, 'formControlName');
  }
  getUserValue(form, fieldObject) {
    return _.get(this.getFormControl(form, fieldObject), 'value');
  }
  handleFileUpload(file, element) {
    return new Promise((resolve, reject) => {
      if (!file){
        resolve(true)
      }
      file.metadata = { "hidden": "true", "name": element.formControlName };
      this.projectsService.uploadSmallFile(file, this.teamId).subscribe(
        (res) => {
          _.set(this.form, 'controls[' + element.formControlName + '].value', res);
          resolve(true);
        },
        () => {
          reject();
        }
      );
    });
  }
  next() {
    if (this.doneUploadingSubscription) this.doneUploadingSubscription.unsubscribe();
    this.doneUploading.next(false);
    let files = _.filter(this.fieldObjects.value, (o) => {
      return o.type === RawInputTypes.File;
    });
    let array = [];
    files.forEach((element) => {
      array.push(this.handleFileUpload(this.getUserValue(this.form, element)[0], element));
    });
    Promise.all(array).then(
      () => {
        this.doneUploading.next(true);
      },
      () => {
        this.layout.toast('Error when uploading inputs', null, 8000, null, 'danger');
        return;
      }
    );
    this.doneUploadingSubscription = this.doneUploading.subscribe((res) => {
      if (res) {
        this.submitted = true;
        this.projectsService
          .updateAllInputs(this.projectId, this.getFieldObjects(false, false), this.getInputsInProjects(), this.getForm(), this.tagName)
          .then(
            () => {
              this.projectsService.triggerWorkflow(this.projectId, this.workflowId).subscribe(
                () => {
                  this.isLoading = false;
                  this.router.navigate(
                    [ PagesName.ProjectManager ],
                    { queryParams: { fromDebugger: this.projectsService.getFromDebugger() } }
                  );
                  this.layout.toast('Project successfully created', null, 8000, '', 'success')
                },
                () => {
                  this.isLoading = false;
                  this.layout.toast('Error when triggering project execution', null, 8000, null, 'danger');
                }
              );
             this.isLoading = true;
            },
            () => {
              this.layout.toast('Error when updating inputs', null, 8000, null, 'danger');
            }
          );
      }
    });
  }
  previous() {
    this.location.back();
  }

  ngOnDestroy() {
    if (this.workflowInputsSubscription) this.workflowInputsSubscription.unsubscribe();
    if (this.projectInputsSubscription) this.projectInputsSubscription.unsubscribe();
    if (this.doneUploadingSubscription) this.doneUploadingSubscription.unsubscribe();
  }
}
