import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { PagesName } from 'src/app/shared/helpers/pagesName';
import { PermissionsHttpService } from 'src/app/shared/permissions-http-service/permissions-http.service';

@Component({
  selector: 'gene-discovery-doc',
  styleUrls: ['./gene-discovery.component.scss'],
  templateUrl: './gene-discovery.component.html',
})
export class GeneDiscoveryDocComponent {
  canCreateProject = false

  constructor(private router: Router, private permissions: PermissionsHttpService) {
    this.permissions
    .checkPermission({
      permissions: {

        createProject:{}
      },
    })
    .subscribe((res) => {
      this.canCreateProject = res.createProject;
    });
  }
  createProject() {
    this.router.navigate(["gene-search"]);
  }
}
