<div class="page d-flex flex-column">
  <app-header>
    <span class="path d-flex align-items-center justify-content-between">
      <span class='path-element font-weight-bold'>
        Search for targeted pathway </span>
    </span>

  </app-header>
<div class=' m-5 d-flex flex-column align-items-center d-flex'>
  <div class='w-75 d-flex justify-content-center'>
    <form [formGroup]="formGroup" class="mx-3 form d-flex flex-column custom-modal-content">
      <label><strong>Drug Target:</strong></label>
      <app-input-field [parentForm]='formGroup' [inputJson]='drugTarget'>
      </app-input-field>
      <br /><br />
      <label>Genes involved in targeted pathway (optional) :</label>
      <app-input-field [parentForm]='formGroup' [inputJson]='keywords1Object'>
      </app-input-field >
      <app-input-field [parentForm]='formGroup' [inputJson]='keywords2Object'>
      </app-input-field>
      <app-input-field [parentForm]='formGroup' [inputJson]='keywords3Object'>
      </app-input-field>

      <div
      (click)="onSubmit()"
        placement="left"
        [class.disabled]="" tabindex="0"
        class='btn  mt-3 mx-auto btn-dark button '>
        <span class='small text-uppercase'> Search </span>
      </div>
    </form>
  </div>
</div>
  <div *ngIf="creditCost > 0" class="card mx-auto">
    <div class="card-body">
    <br />
    <p>For this project you will use <strong>{{creditCost}} credit(s)</strong> from your license.</p>
    </div>
  </div>
</div>