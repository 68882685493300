import { ApplicationRef, EventEmitter, Injectable } from '@angular/core';
import { BehaviorSubject, forkJoin, Observable } from 'rxjs';
import * as _ from 'lodash';
import { FileSystemState } from '../login/login-http-service/login-http.service';
import { TabsName } from 'src/app/shared/helpers/tabsName';
import { TabsPublicName } from 'src/app/shared/helpers/tabsPublicName';
import { PagesPublicName } from 'src/app/shared/helpers/pagesPublicName';
import { LocalService } from 'src/app/api/local-storage.service';
import { availableFeatures } from 'src/environments/environment';
import { Features } from 'src/environments/features';
import { Files2Service } from 'src/app/api/files2.service';
import { LayoutOrganizationService } from 'src/app/api/layout-organization.service';
import { ModelsPostObject } from 'src/app/model/modelsPostObject';
import { UntypedFormControl, UntypedFormGroup, ValidationErrors, Validators } from '@angular/forms';
import { Status } from 'src/app/shared/helpers/status';
import { TeamsService } from '../teams-manager/teams.service';
import { GroupsManagerService } from 'src/app/api/groups-manager.service';
import { AutoCompleteType, ShareService } from 'src/app/api/share.service';
import { ItemType } from 'src/app/shared/helpers/itemType';
import { InfiniteListingService } from 'src/app/api/infiniteListing.service';
import { SortType } from 'src/app/shared/helpers/sortType';
import { SortDirection } from 'src/app/shared/helpers/sortDirection';
import { Tabs } from 'src/app/shared/helpers/tabs';

export function createShortItemObject(id, title, country, type: ItemType, content_size, capabilities) {
  return { id, title, country, type, content_size, capabilities };
}


export enum Action {
  ChangeSortingDirection,
  ChangeSortingType,
  TrashMultiple,
  Upload,
  UploadSelect,
  CreateFolder,
  CreateGroup,
  Restore,
  MoveSelect,
  MoveMultiple,
  ChangePage,
  DeletePermanently,
  InitManager,
  ChangeCountry,
  ChangeTab,
  UnselectAll,
  SelectDisplayedFiles,
  UnSelectDisplayedFiles,
  MoveToGroupMultiple,
  AddToGroupSelect,
}
export enum ItemAction {
  Download,
  SelectShare,
  Share,
  Info,
  ToggleSelect,
  Trash,
  Quality,
  MoveToFolder,
  Modify,
  OpenFolder,
  OpenParentFolder,
  Rename,
  SelectMetadata,
  UploadMetadata,
  RemovePermission,
}

export function constructObjectByCountry(countriesDataSource, defaultValue) {
  let newVariable = {};
  countriesDataSource.forEach((country) => {
    _.set(newVariable, country.value, defaultValue);
  });
  return newVariable;
}
export function constructObject(defaultValue, defaultValue2?, defaultValue3?) {
  let newVariable = {};
  _.set(newVariable, TabsName.MyFiles, defaultValue);
  _.set(newVariable, TabsName.SharedFiles, defaultValue2 ? defaultValue2 : defaultValue);
  _.set(newVariable, TabsName.DeletedFiles, defaultValue3 ? defaultValue3 : defaultValue);
  return newVariable;
}
export function extensionValidator(control: UntypedFormControl): ValidationErrors | null {
  const isCsv = (name) => {
    return !!name.match(/.csv$/i);
  };
  const isJson = (name) => {
    return !!name.match(/.json$/i);
  };
  if (control.value) {
    return isCsv(control.value) || isJson(control.value) ? null : { extensionMustBeCsvOrJson: true };
  } else {
    return null;
  }
}
@Injectable()
export class FileManager2Service {
  limit = constructObject(10);
  page = constructObject(1);
  storedPages = constructObject({});
  displayedGroups = constructObject(new BehaviorSubject<any>(undefined));

  //Info
  path = constructObject(new BehaviorSubject<any>(undefined));
  displayedFiles = constructObject(new BehaviorSubject<any>(undefined));
  count = constructObject(new BehaviorSubject<any>(0));
  openedFolder = constructObject(null);
  openedPage = constructObject(1);

  //filters and sort by
  filters = constructObject('ownedByMe=true', 'sharedWithMe=true', 'trashed=true');

  //Selects Data sources
  teamsDataSource = new BehaviorSubject<any>(undefined);
  countriesDataSource;
  tabsDataSource: Array<Tabs> = [
    { value: TabsName.MyFiles, label: TabsPublicName.MyFiles },
    { value: TabsName.SharedFiles, label: TabsPublicName.SharedFiles },
    { value: TabsName.DeletedFiles, label: TabsPublicName.DeletedFiles },
  ];
  //Move
  folderDataSource = new BehaviorSubject<any>(undefined);
  groupDataSource = constructObject(new BehaviorSubject<any>(undefined));

  //Store Selected
  bulkSelection = constructObject([]);
  activeCountry;
  activeItem = constructObject(undefined);

  //Layout

  //For upload
  metadataFile = constructObject(undefined);
  files = constructObject(undefined);
  uploadingFiles = new BehaviorSubject<any>([]);
  uploadToastTitle = new BehaviorSubject<any>('Uploading files . . .   ');
  isUploadToastOpened = new BehaviorSubject<boolean>(false);
  activeTab = this.tabsDataSource[0];
  autocompleteData;
  uploadToast;

  //Forms
  moveForm = new UntypedFormGroup({
    folder: new UntypedFormControl(null, [Validators.required]),
  });
  moveGroupForm = new UntypedFormGroup({
    group: new UntypedFormControl(null, [Validators.required]),
  });
  folderCreationForm = new UntypedFormGroup({
    folderInput: new UntypedFormControl(null, [Validators.required]),
  });
  fileUploadForm: UntypedFormGroup = new UntypedFormGroup({
    team: new UntypedFormControl(null, [Validators.required]),
    fileInput: new UntypedFormControl(null),
  });
  metadataUploadForm = new UntypedFormGroup({
    metadataInput: new UntypedFormControl(null, [Validators.required, extensionValidator]),
  });
  quality = false;

  //Event
  actionEvent = new EventEmitter(undefined);
  itemEvent = new EventEmitter(undefined);

  constructor(
    private groupsManagerService: GroupsManagerService,
    private filesService: Files2Service,
    private teamsService: TeamsService,
    private localService: LocalService,
    private l: LayoutOrganizationService,
    private shareService: ShareService,
    private infiniteListingService: InfiniteListingService,
    private appRef: ApplicationRef
  ) {
    if (!localStorage.getItem('sortByType')) {
      localStorage.setItem('sortByType', JSON.stringify(constructObject(SortType.Title)));
    }
    if (!localStorage.getItem('sortByDirection')) {
      localStorage.setItem('sortByDirection', JSON.stringify(constructObject(SortDirection.Asc)));
    }
    this.initCountries();

    this.itemEvent.subscribe((event) => {
      if (event) {
        this.handleItemAction(event);
      }
    });
    this.actionEvent.subscribe((event) => {
      if (event) {
        this.handleManagerAction(event);
      }
    });
    _.get(this.groupsManagerService, 'eventRefreshGroups').subscribe((event) => {
      if (event) {
        this.retrieveGroups();
      }
    });
  }
  getSortByType() {
    const sortByType = JSON.parse(localStorage.getItem('sortByType'));
    return _.get(sortByType, this.activeTab.value);
  }
  setSortByType(value) {
    let sortByType = JSON.parse(localStorage.getItem('sortByType'));
    _.set(sortByType, this.activeTab.value, value);
    localStorage.setItem('sortByType', JSON.stringify(sortByType));
  }
  setSortByDirection(value) {
    let sortByDirection = JSON.parse(localStorage.getItem('sortByDirection'));
    _.set(sortByDirection, this.activeTab.value, value);
    localStorage.setItem('sortByDirection', JSON.stringify(sortByDirection));
  }
  getSortByDirection() {
    const sortByDirection = JSON.parse(localStorage.getItem('sortByDirection'));
    return _.get(sortByDirection, this.activeTab.value);
  }

  handleItemAction(event) {
    const item = _.nth(event, 0);
    const itemAction = _.nth(event, 1);
    const destination = _.nth(event, 2);
    const value = _.nth(event, 3);
    let storedPages = this._get('storedPages');

    if (item.country.value !== this.activeCountry.value) {
      this.activeCountry = item.country;
    }
    switch (itemAction) {
      case ItemAction.ToggleSelect:
        if (
          _.find(this._get('bulkSelection'), (o) => {
            return o.id === item.id;
          })
        ) {
          this.removeFromBulkSelection(item);
        } else {
          this.addToBulkSelection(item);
        }
        break;
      case ItemAction.SelectShare:
        this.l.close();
        this.refreshItem(item).then(() => {
          this.l.open('modalFilesShare');
          _.set(this.l.fmPannelIsOpened, this.activeTab.value, true);
        });
        break;
      case ItemAction.Share:
        this.shareService.shareToUsers(ItemType.File, _.get(item, 'id'), this.getUsersSelected(), this.activeCountry.value).then(() => {
          this.refreshItem(item).then(() => {
            this.l.open('modalFilesShare');
            _.set(this.l.fmPannelIsOpened, this.activeTab.value, true);
          });
        });
        break;
      case ItemAction.Info:
        this.refreshItem(item).then(() => {
          this.l.open('modalFilesInfos');
          _.set(this.l.fmPannelIsOpened, this.activeTab.value, true);
        });
        break;
      case ItemAction.Trash:
        let line1 = 'This item <b>' + _.get(item, 'title') + '</b> will be deleted. Are you sure you want to do this ?';
        let line2 = '<br><br>' + 'This item will be permanentely deleted in 30 days.';
        let text = line1 + line2;
        this.l.customConfirm(text, () => {
          this.handleTrashAction(item).then(() => {
            this.reloadFolder();
          });
        });
        break;
      case ItemAction.Download:
        this.handleDownloadAction(item);
        break;
      case ItemAction.SelectMetadata:
        this._set('metadataFile', undefined);
        this.metadataUploadForm.reset();
        this.l.open('modalMetaData');
        break;
      case ItemAction.RemovePermission:
        this.handleRemovePermission(item, value).then(() => {
          this.refreshItem(item);
        });
        break;
      case ItemAction.UploadMetadata:
        this.l.close('modalMetaData');
        if (this._get('metadataFile'))
          this.handleMetadataUpload(item).then(() => {
            this.reloadFolder();
            this.refreshItem(item);
          });
        break;
      case ItemAction.Rename:
        this.handleRenameAction(item, value).then(() => {
          this.reloadFolder();
          this.refreshItem(item);
        });
        break;
      case ItemAction.Quality:
        this.handleQualityAction(item);
        break;
      case ItemAction.OpenFolder:
        _.set(storedPages, this._get('openedFolder').id, this._get('openedPage'));
        if (_.get(storedPages, item.id)) {
          delete storedPages[item.id];
        }
        this._set('storedPages', storedPages);
        this.openFolder(item, 1);
        break;
      case ItemAction.OpenParentFolder:
        _.set(storedPages, this._get('openedFolder').id, 1);
        let page = _.get(this._get('storedPages'), item.id, 1);
        this.openFolder(item, page);
        break;
      case ItemAction.MoveToFolder:
        this.handleMoveAction(item, destination);
        break;
    }
  }

  handleRemovePermission(item, value) {
    return new Promise((resolve) => {
      return this.filesService.removePermissionFromFile(value, _.get(item, 'id'), this.activeCountry.value).subscribe(
        () => {
          this.l.toast('Permission <b>successfully</b> removed from file.', null, 8000, 'Profile');
          resolve(true);
        },
        (error) => {
          this.l.toast('Impossible operation', null, 8000, 'Profile', 'danger');
        }
      );
    });
  }
  handleMetadataUpload(item) {
    return new Promise((resolve) => {
      this.filesService.postMetaDataFile(_.get(item, 'id'), this._get('metadataFile'), 'response', this.activeCountry.value).subscribe(
        () => {
          resolve(true);
        },
        (error) => {
          this.l.toast(_.get(error, 'error.message'), null, 5000, '', 'danger');
        }
      );
    });
  }
  handleMoveAction(item, destination) {
    console.log(destination);
  }
  handleQualityAction(item) {
    this._set('activeItem', item);
    this.quality = true;
  }
  handleRenameAction(item, value) {
    return new Promise((resolve) => {
      if (_.get(item, 'id')) {
        return this.filesService.modify(_.get(item, 'id'), { title: value }, this.activeCountry.value).subscribe(
          () => {
            resolve(true);
          },
          () => {
            this.l.toast('Error when renaming the item', null, 5000, '', 'danger');
          }
        );
      }
    });
  }

  handleDownloadAction(item) {
    const a = document.createElement('a');
    const params = {
      data_type: 'content',
      accept: 'application/octet-stream',
      access_token: this.localService.getSpecificCountryAccessToken(this.activeCountry.value),
    };
    const searchParams = new URLSearchParams(params);
    a.href =
      this.localService.getSpecificCountryUrl(this.activeCountry.value) + '/mas/fs/files/' + _.get(item, 'id') + '/data/?' + searchParams;
    a.download = _.get(item, 'title');
    a.click();
  }
  retrieveInfo = (item) => {
    return new Promise((resolve) => {
      return this.filesService.retrieveInfo(_.get(item, 'id'), this.activeCountry.value).subscribe(
        (res) => {
          _.set(res, 'country.value', this.activeCountry.value);
          resolve(res);
        },
        () => {
          this.l.toast('Error when retrieving the description of the item', null, 5000, '', 'danger');
        }
      );
    });
  };
  refreshItem = (item) => {
    return new Promise((resolve) => {
      this._set('activeItem', undefined);
      return this.retrieveInfo(item).then((res) => {
        this._set('activeItem', res);
        resolve(res);
      });
    });
  };
  handleTrashAction = (item) => {
    return new Promise((resolve) => {
      return this.filesService.trash(_.get(item, 'id'), this.activeCountry.value).subscribe(
        (res) => {
          this.l.toast('Item <b>' + _.get(item, 'title') + '</b> successfully trashed', null, 5000, '', 'success');
          resolve(true);
        },
        (error) => {
          this.l.toast(_.get(error, 'error.message'), null, 5000, '', 'danger');
        }
      );
    });
  };
  getUsersSelected() {
    return _.get(this.shareService, 'usersSelected', []);
  }

  // Manager function
  handleManagerAction(event) {
    const action = _.nth(event, 0);
    const destination = _.nth(event, 1);
    const page = _.nth(event, 2);
    switch (action) {
      case Action.ChangeSortingDirection:
        this.setSortByDirection(destination);
        setTimeout(() => {
          this.reloadFolder();
        }, 1);
        break;
      case Action.ChangeSortingType:
        this.setSortByType(destination);
        setTimeout(() => {
          this.reloadFolder();
        }, 1);
        break;
      case Action.TrashMultiple:
        let line1 = 'All selected files and folder will be trashed. Are you sure you want to do this ?';
        let line2 = '<br><br>' + 'Items will be permanentely deleted in 30 days.';
        let text = line1 + line2;
        this.l.customConfirm(text, () => {
          this.handleTrashMultiple();
        });
        break;
      case Action.UploadSelect:
        this.l.open('modalFile');
        break;
      case Action.SelectDisplayedFiles:
        this._getAsync('displayedFiles').forEach((item) => {
          this.addToBulkSelection(item);
        });
        break;
      case Action.AddToGroupSelect:
        this.l.open('modalGroupMove');
        break;
      case Action.UnselectAll:
        this.removeFromBulkSelection();
        break;
      case Action.MoveToGroupMultiple:
        this.handleAddToGroupMultiple(this.moveGroupForm.controls.group.value).then(() => {
          this.groupsManagerService.eventRefreshGroup.next({ id: this.moveGroupForm.controls.group.value, country: this.activeCountry });
        });
        break;
      case Action.UnSelectDisplayedFiles:
        this._getAsync('displayedFiles').forEach((item) => {
          this.removeFromBulkSelection(item);
        });
        break;
      case Action.Upload:
        this.handleNewFiles();
        break;
      case Action.CreateFolder:
        this.l.open('modalFolder');
        break;
      case Action.CreateGroup:
        this.l.open('modalGroup');
        break;
      case Action.Restore:
        this.handleRestoreMultiple();
        break;
      case Action.ChangePage:
        this.handlePageChange(page, destination);
        break;
      case Action.InitManager:
        this.closePannel();
        this.handleRetrieveAll();
        break;
      case Action.DeletePermanently:
        this.handlePermanentlyDeleteMultiple();
        break;
      case Action.MoveSelect:
        this.l.open('modalMove');
        this.retrieveFolders(this._get('openedFolder')).then((data) => {
          this.folderDataSource.next(data);
        });
        break;
      case Action.MoveMultiple:
        this.handleMoveMultiple().finally(() => {
          this.reloadFolder();
        });
        break;
      case Action.ChangeCountry:
        this.closePannel();
        this.activeCountry = destination;
        this.handleRetrieveAll();
        break;
      case Action.ChangeTab:
        this.handleTabChange(destination);
        break;
      default:
        return false;
    }
  }
  handleMove = (itemId, destination) => {
    return new Promise((resolve) => {
      return this.filesService.moveFile(itemId, destination, this.activeCountry.value).subscribe(
        (res) => {
          resolve(true);
        },
        (error) => {
          this.l.toast(_.get(error, 'error.message'), null, 5000, '', 'danger');
        }
      );
    });
  };
  handleAddToGroup = (itemId, destination) => {
    return new Promise((resolve) => {
      return this.filesService.addToGroup(destination, itemId, this.activeCountry.value).subscribe(
        (res) => {
          resolve(true);
        },
        (error) => {
          this.l.toast(_.get(error, 'error.message'), null, 5000, '', 'danger');
        }
      );
    });
  };
  handleAddToGroupMultiple = (destination) => {
    return new Promise((resolve, reject) => {
      let observableBatch = [];
      this._get('bulkSelection').forEach((item) => {
        observableBatch.push(this.handleAddToGroup(item.id, destination));
      });
      return forkJoin(observableBatch).subscribe(
        () => {
          this.l.toast('Items successfully added to selected group', null, 5000, '', 'success');
          resolve(true);
        },
        (error) => {
          reject(error);
        }
      );
    });
  };
  handleMoveMultiple = () => {
    return new Promise((resolve, reject) => {
      let destination = this.moveForm.controls.folder.value;
      let observableBatch = [];
      this._get('bulkSelection').forEach((item) => {
        observableBatch.push(this.handleMove(item.id, destination));
      });
      return forkJoin(observableBatch).subscribe(
        () => {
          this.l.toast('Items successfully moved to selected folder', null, 5000, '', 'success');
          resolve(true);
        },
        (error) => {
          reject(error);
        }
      );
    });
  };
  getVisibleBulkLength = () => {
    return _.intersectionBy(this._get('bulkSelection'), this._getAsync('displayedFiles'), 'id').length;
  };
  getBulkSize = () => {
    let output = [0, 'kB'];
    let size = 0;
    this._get('bulkSelection').forEach((item) => {
      size = size + item.content_size;
    });
    if (size > 0) {
      var i = Math.floor(Math.log(size) / Math.log(1000));
      let calc: any = size / Math.pow(1000, i);
      output = [calc.toFixed(1) * 1, ['B', 'kB', 'MB', 'GB', 'TB'][i]];
    }
    return output;
  };
  listTeamsMembers = (country) => {
    return new Promise((resolve) => {
      let teamsMembers = [];
      this.shareService.getTeams(country).subscribe((res) => {
        let observableBatch = [];
        res.forEach((el) => {
          observableBatch.push(this.shareService.getTeam(_.get(el, '_key'), country));
        });
        forkJoin(observableBatch).subscribe((response) => {
          response.forEach((team) => {
            const emails = _.get(team, 'user_emails', []);
            _.get(team, 'team.user_ids', []).forEach((el2, index2) => {
              teamsMembers.push({
                id: el2,
                value: _.nth(emails, index2),
                team: _.get(res, 'name', null),
                type: AutoCompleteType.TeamsMembers,
              });
            });
          });
          resolve(teamsMembers);
        });
      });
    });
  };
  saveMetadataFiles(files) {
    this._set('metadataFile', files);
  }
  handlePageChange(page, folder) {
    if (page == this._get('openedPage')) {
      return;
    }
    this._set('openedPage', page);
    this._setAsync('displayedFiles', undefined);
    this.listFolder(folder, page);
  }
  openFolder(folder, page) {
    this._set('bulkSelection', []);
    this._set('pannelIsOpened', false);
    this._set('openedFolder', folder);
    this._set('openedPage', page);
    this._setAsync('displayedFiles', undefined);
    this.retrievePath(folder);
    this.retrieveCount(folder);
    this.listFolder(folder, page);
  }
  handleTabChange(event) {
    this.closePannel();
    this.activeTab = event;
    this.openFolder(this.getRootFolder(), 1);
    this.retrieveGroups();
  }
  addToBulkSelection(item) {
    let bulkSelection = this._get('bulkSelection');
    if (
      !_.find(bulkSelection, (o) => {
        return o.id === item.id;
      })
    ) {
      bulkSelection.push(item);
    }
    this._set('bulkSelection', bulkSelection);
  }

  removeFromBulkSelection(item?) {
    if (!item) {
      this._set('bulkSelection', []);
      return;
    }
    let bulkSelection = this._get('bulkSelection');
    bulkSelection = _.filter(bulkSelection, (o) => {
      return o.id !== item.id;
    });
    this._set('bulkSelection', bulkSelection);
  }
  retrieveCount(folder) {
    const country = folder.country ? folder.country : this.activeCountry;
    let filters = this._get('filters');
    if (this.activeTab.value !== TabsName.DeletedFiles) {
      filters = filters + ',parent="' + folder.id + '"';
    }
    return this.filesService.count(filters, country.value).subscribe(
      (res) => {
        this._setAsync('count', res.count);
      },
      (error) => {
        console.log(error);
      }
    );
  }
  handleCreateGroup = () => {
    const name = this.folderCreationForm.controls.folderInput.value;
    const newFolderObject: ModelsPostObject = {
      description: '',
      is_folder: true,
      metadata: {},
      size: 0,
      title: name,
    };
    this.filesService
      .postFile(this._get('openedFolder').id, newFolderObject, 'response', this._get('openedFolder').country.value)
      .subscribe(
        (res) => {
          this.l.close('modalFolder');
          this.l.toast('Folder <b>' + name + '</b> successfully created', null, 5000, '', 'success');
          this.reloadFolder();
        },
        (error) => {
          this.l.toast('Error during the creation of the folder <b>' + name + '</b>', null, 5000, '', 'danger');
        }
      );
  };
  handleCreateFolder = () => {
    const name = this.folderCreationForm.controls.folderInput.value;
    const newFolderObject: ModelsPostObject = {
      description: '',
      is_folder: true,
      metadata: {},
      size: 0,
      title: name,
    };
    this.filesService
      .postFile(this._get('openedFolder').id, newFolderObject, 'response', this._get('openedFolder').country.value)
      .subscribe(
        (res) => {
          this.l.close('modalFolder');
          this.l.toast('Folder <b>' + name + '</b> successfully created', null, 5000, '', 'success');
          this.reloadFolder();
        },
        (error) => {
          this.l.toast('Error during the creation of the folder <b>' + name + '</b>', null, 5000, '', 'danger');
        }
      );
  };
  handlePermanentlyDeleteMultiple = () => {
    const permanentlyDeleteHandleFunction = (item) => {
      return this.filesService.permanentlyDelete(item.id, item.country.value);
    };
    const permanentlyDeleteEndCallback = (item) => {
      this.l.toast('Item(s) successfully deleted ', null, 5000, '', 'success');
      return this.reloadFolder();
    };
    const permanentlyDeleteErrorCallBack = (item) => {
      this.l.toast('Impossible to permanently delete <b>' + _.get(item, 'title') + '</b>', null, 5000, '', 'danger');
    };
    const permanentlyDeleteSuccessCallback = (item) => {
      return undefined;
    };
    this.bulkAction(
      permanentlyDeleteHandleFunction,
      permanentlyDeleteEndCallback,
      permanentlyDeleteErrorCallBack,
      permanentlyDeleteSuccessCallback
    );
  };
  handleRestoreMultiple = () => {
    const restoreHandleFunction = (item) => {
      return this.filesService.restore(_.get(item, 'id'), this.activeCountry.value);
    };
    const restoreEndCallback = (item) => {
      this.l.toast('Item(s) successfully restored ', null, 5000, '', 'success');
      return this.reloadFolder();
    };
    const restoreErrorCallBack = (item) => {
      this.l.toast('Impossible to restore <b>' + _.get(item, 'title') + '</b>', null, 5000, '', 'danger');
    };
    const restoreSuccessCallback = (item) => {
      return undefined;
    };
    this.bulkAction(restoreHandleFunction, restoreEndCallback, restoreErrorCallBack, restoreSuccessCallback);
  };
  handleTrashMultiple = () => {
    const restoreHandleFunction = (item) => {
      return this.filesService.trash(_.get(item, 'id'), this.activeCountry.value);
    };
    const restoreEndCallback = (item) => {
      this.l.toast('Item(s) successfully trashed ', null, 5000, '', 'success');
      return this.reloadFolder();
    };
    const restoreErrorCallBack = (item) => {
      this.l.toast('Impossible to trash <b>' + _.get(item, 'title') + '</b>', null, 5000, '', 'danger');
    };
    const restoreSuccessCallback = (item) => {
      return undefined;
    };

    this.bulkAction(restoreHandleFunction, restoreEndCallback, restoreErrorCallBack, restoreSuccessCallback);
  };

  updateUploadToast = (title) => {
    this.uploadToastTitle.next(title);
  };
  handleNewFiles = () => {
    this.uploadingFiles.next(
      _.concat(
        this.uploadingFiles.value,
        this.createTempFiles(_.get(this, 'fileUploadForm.controls.fileInput.value'), _.get(this, 'fileUploadForm.controls.team.value'))
      )
    );
    const onUploadToastClose = () => {
      let pendingFolders = _.filter(this.uploadingFiles.value, (o) => {
        return o.status === Status.Pending || o.status === Status.NotStarted;
      });

      if (!pendingFolders.length) {
        this.uploadingFiles.next([]);
        this.isUploadToastOpened.next(false);
        return;
      }
      let text =
        pendingFolders.length > 1
          ? 'Upload are not completed. Do you want to cancel all downloads ?'
          : 'Upload is not completed. Do you want to cancel ?';
      this.l.customConfirm(text, () => {
        this.uploadingFiles.next([]);
        this.isUploadToastOpened.next(false);
      });
    };
    if (!this.isUploadToastOpened.value) {
      if (this.uploadToast) {
        this.appRef.detachView(this.uploadToast.hostView);
        this.uploadToast.destroy();
      }
      this.uploadToast = this.l.toast(
        null,
        null,
        null,
        this.uploadToastTitle,
        'information',
        [onUploadToastClose],
        this.isUploadToastOpened,
        this.uploadingFiles
      );
    }

    this.updateUploadToast('Uploading files . . .   ');
    this.isUploadToastOpened.next(true);
    const proceedUpload = (uploadId, file, country) => {
      return new Observable<any>((observer) => {
        return this.filesService.upload(uploadId, file, 'body', country).subscribe(
          (res) => {
            observer.next(Math.round(res));
          },
          () => {
            observer.error();
          }
        );
      });
    };
    const notStartedFiles = (uploadingFiles) => {
      return _.filter(uploadingFiles.value, (o) => {
        return o.status === Status.NotStarted;
      });
    };
    const preUpload = (fileToBeUploaded) => {
      return new Promise((resolve, reject) => {
        const newFileObject = {
          description: '',
          is_folder: false,
          metadata: {},
          size: fileToBeUploaded.size,
          title: fileToBeUploaded.title,
          team_id: fileToBeUploaded.teamId,
        };
        return this.filesService.postFile(fileToBeUploaded.parent_id, newFileObject, 'response', fileToBeUploaded.country.value).subscribe(
          (res) => {
            let uploadId = res.headers.get('Location');
            uploadId = uploadId.slice(15, uploadId.length);
            resolve(uploadId);
          },
          () => {
            reject();
          }
        );
      });
    };
    const upload = () => {
      let fileToUpload = _.first(notStartedFiles(this.uploadingFiles));
      if (!fileToUpload) {
        this.reloadFolder();
        const plural = this.uploadingFiles.value.length > 1 ? 's' : '';
        this.updateUploadToast(this.uploadingFiles.value.length + ' file' + plural + ' uploaded');
        return;
      }
      _.set(fileToUpload, 'status', Status.Uploading);
      preUpload(fileToUpload)
        .then((response) => {
          _.set(fileToUpload, 'uploadId', response);
          _.set(fileToUpload, 'reportChunkProgress', 0);
          this.updateUploadToast('Uploading files . . .   ');
          proceedUpload(fileToUpload.uploadId, fileToUpload.file, fileToUpload.country.value)
            .subscribe((res) => {
              _.set(fileToUpload, 'reportChunkProgress', res);
              this.updateUploadToast('Uploading files . . .   ');
              if (res === 100) {
                _.set(fileToUpload, 'status', Status.WaitingForRefresh);
                upload();
              }
            },
            (error) => {
              fileToUpload.status = Status.Error;
              this.updateUploadToast('Uploading files . . .   ');
              upload();
            });
        })
        .catch((error) => {
          this.updateUploadToast('Uploading files . . .   ');

          _.set(fileToUpload, 'status', Status.Error);
          upload();
        });
    };
    upload();
  };
  createTempFiles(filesToUpload, teamId) {
    let array = [...filesToUpload].map((el) => {
      return (el = {
        description: '',
        is_folder: false,
        reportChunkProgress: 0,
        status: Status.NotStarted,
        metadata: {},
        size: el.size,
        title: el.name,
        parent_id: this._get('openedFolder').id,
        parent_folder: this._get('openedFolder'),
        file: el,
        teamId: teamId,
        country: this.activeCountry,
      });
    });
    console.log(array);
    return array;
  }
  closePannel = () => {
    this._set('activeItem', undefined);
    _.set(this.shareService, 'usersSelected', []);
    this.l.close('modalFilesInfos');
    this.l.close('modalFilesShare');
    _.set(this.l.fmPannelIsOpened, this.activeTab.value, false);
  };

  // init
  getRootFolder() {
    return createShortItemObject('root', 'Storage location - ' + this.activeCountry.label, this.activeCountry, ItemType.Folder, 0, {});
  }

  reloadFolder() {
    this.openFolder(this._get('openedFolder'), this._get('openedPage'));
  }
  listFolder(folder, page) {
    const sortBy = this.getSortByType() + ',' + this.getSortByDirection();
    const limit = this._get('limit');
    let filters = this._get('filters');
    if (this.activeTab.value !== TabsName.DeletedFiles) {
      filters = filters + ',parent="' + folder.id + '"';
    }
    return this.filesService.listFiles(sortBy, filters, limit, page, folder.country.value).subscribe(
      (res) => {
        res.data.forEach((el) => {
          _.set(el, 'country', folder.country);
        });

        this._set('page', page);
        this._setAsync('displayedFiles', res.data);
      },
      (error) => {
        console.log(error);
      }
    );
  }
  initCountries() {
    let countries = [];
    let fileSystems = this.localService.getFromLocalStorage('user', 'file_systems');
    fileSystems = _.sortBy(fileSystems, (o) => {
      return o.accessState !== FileSystemState.Local;
    });
    fileSystems.forEach((el) => {
      if (el.accessState !== FileSystemState.Disabled) {
        countries.push({ label: el.name, value: el.value });
      }
    });
    this.countriesDataSource = countries;
    this.activeCountry = _.nth(countries, 0);
  }

  retrieveFolders = (folder) => {
    return new Promise((resolve) => {
      const sortBy = this.getSortByType() + ',' + this.getSortByDirection();
      const limit = 100;
      let filters = this._get('filters');
      if (this.activeTab.value !== TabsName.DeletedFiles) {
        filters = filters + ',parent="' + folder.id + '"' + ',is_folder=true';
      }
      const func = () => {
        return this.filesService.listFiles(sortBy, filters, limit, undefined, folder.country.value);
      };
      return this.infiniteListingService.infiniteListing(func, folder.country.value).then((res) => {
        let newRes = [];
        _.get(res, 'data').forEach((el) => {
          newRes.push({ label: _.get(el, 'title'), value: _.get(el, 'id') });
        });
        const path = this._getAsync('path');
        if (_.last(path).id !== this.getRootFolder().id) {
          newRes.unshift({ label: _.nth(path, -2).title + ' (parent folder)', value: _.nth(path, -2).id });
        }
        resolve(newRes);
      });
    });
  };
  retrieveTeams = () => {
    return new Promise((resolve) => {
      return this.teamsService.retrieveTeams(this.activeCountry.value).then((res: Array<any>) => {
        let newRes = [];
        res.forEach((el) => {
          newRes.push({ label: _.get(el, 'name'), value: _.get(el, '_key') });
        });
        resolve(newRes);
      });
    });
  };
  getAutocompleteData = () => {
    return this.shareService.getAutocompleteData();
  };
  handleRetrieveAll = () => {
    this.page = constructObject(1);
    this.openedPage = constructObject(1);
    this.storedPages = constructObject({});
    this._setAsync('displayedFiles', undefined);
    this.retrieveTeams().then((teams) => {
      this.teamsDataSource.next(teams);
    });
    this.shareService.listTeamsMembers(this.activeCountry.value);
    this.shareService.listUsergroups(this.activeCountry.value);

    this.openFolder(this.getRootFolder(), 1);
    this.retrieveGroups();
  };
  retrieveGroups = () => {
    this.groupsManagerService.listGroups(this.activeTab.value, this.activeCountry).then((res: Array<any>) => {
      this._setAsync('displayedGroups', _.get(res, 'data'));

      let groupDataSource = [];
      _.get(res, 'data', []).forEach((el) => {
        groupDataSource.push({ value: _.get(el, 'id'), label: _.get(el, 'name') });
      });

      this._setAsync('groupDataSource', groupDataSource);
    });
  };

  // path
  retrievePath(folder) {
    const country = folder.country ? folder.country : this.activeCountry;
    const pageDescription = createShortItemObject(null, PagesPublicName.FileManager, country, ItemType.Other, 0, {});
    const tabDescription = createShortItemObject(null, this.activeTab.label, country, ItemType.Other, 0, {});
    const countryDescription = createShortItemObject('root', 'Storage location - ' + country.label, country, ItemType.Other, 0, {});
    return this.filesService.getPathFromFileId(folder.id, country.value).subscribe((res) => {
      let editedParts = _.get(res, 'parts').map((el) => {
        return createShortItemObject(el.id, el.name, country, ItemType.Folder, 0, {});
      });
      let path = [pageDescription, tabDescription, countryDescription, ...editedParts];
      this._setAsync('path', path);
    });
  }

  // General function
  bulkAction = (handleFunction, endCallback, errorCallBack, successCallback, confirmText?) => {
    let items = this._get('bulkSelection');
    items.forEach((item) => {
      handleFunction(item).subscribe(
        () => {
          successCallback(item);
        },
        () => {
          errorCallBack(item);
        },
        () => {
          if (item === _.last(items)) {
            endCallback(item);
          }
        }
      );
    });
  };

  canPerform(action: Action) {
    switch (action) {
      case Action.UploadSelect:
        if (this.isMyFiles() && _.includes(availableFeatures, Features.UploadFile)) return true;
        break;
      case Action.TrashMultiple:
        if (
          _.includes(availableFeatures, Features.DeleleMultipleFiles) &&
          _.includes(availableFeatures, Features.DeleteFile) &&
          this._get('bulkSelection').length
        )
          return true;
        break;
      case Action.CreateFolder:
        if (this.isMyFiles()) return true;
        break;
      case Action.CreateGroup:
        if (this.isMyFiles()) return true;
        break;
      case Action.MoveSelect:
        if (this.isMyFiles() && this._get('bulkSelection').length) return true;
        break;
      case Action.AddToGroupSelect:
        if (!this.isMyDeletedFiles() && this._get('bulkSelection').length) return true;
        break;
      case Action.DeletePermanently:
      case Action.Restore:
        if (this.isMyDeletedFiles() && this._get('bulkSelection').length) return true;
        break;
      default:
        return false;
    }
  }
  getTooltip(action: Action) {
    switch (action) {
      case Action.Upload:
        if (!this.isMyFiles()) return "You can't perform this action in this tab";
        if (!_.includes(availableFeatures, Features.UploadFile)) return "You don't have the permission to upload";
        return 'Select file(s) to upload';
      case Action.TrashMultiple:
        if (!_.includes(availableFeatures, Features.DeleleMultipleFiles) || !_.includes(availableFeatures, Features.DeleteFile))
          return "You don't have the permission to trash multiple files";
        if (!this._get('bulkSelection').length) return 'Select at least one item to trash';
        return 'Trash all selected files and folders. Trashed items can be found in the deleted files tab';
      case Action.CreateFolder:
        if (!this.isMyFiles()) return "You can't perform this action in this tab";
        return 'Add a new folder to organize your files';
      case Action.MoveSelect:
        if (!this.isMyFiles()) return "You can't perform this action in this tab";
        if (!this._get('bulkSelection').length) return 'Select at least one item to move';
        return 'Move all selected files and folders to a destination folder';
      case Action.AddToGroupSelect:
        if (!this.isMyFiles() && !this.isSharedFiles()) return "You can't perform this action in this tab";
        if (!this._get('bulkSelection').length) return 'Select at least one item to add to a group';
        return 'Add all selected files and folders to a destination group';
      case Action.CreateGroup:
        if (!this.isMyFiles()) return "You can't perform this action in this tab";
        return 'Create a group with your files and folders. A group is used in a project to perform analyses';
      case Action.DeletePermanently:
        if (!this.isMyDeletedFiles()) return "You can't perform this action in this tab";
        if (!this._get('bulkSelection').length) return 'Select at least one item to delete permanently';
        return "Delete selected items permanently. This action can't be undone";
      case Action.Restore:
        if (!this.isMyDeletedFiles()) return "You can't perform this action in this tab";
        if (!this._get('bulkSelection').length) return 'Select at least one item to restore';
        return 'Restore selected items. These item will appear at the root of your files tab';
      default:
        return '';
    }
  }
  _get(variableName) {
    return _.get(this, [variableName, this.activeTab.value]);
  }
  _getByCountry(variableName) {
    return _.get(this, [variableName, this.activeCountry.value]);
  }
  _getAsyncByCountry(variableName) {
    return _.get(this, [variableName, this.activeCountry.value, 'value']);
  }
  _set(variableName, value, tab?) {
    _.set(this, [variableName, tab ? tab : this.activeTab.value], value);
  }
  _setAsync(variableName, value, tab?) {
    _.get(this, [variableName, tab ? tab : this.activeTab.value]).next(value);
  }
  _setByCountry(variableName, value, country?) {
    _.set(this, [variableName, country ? country : this.activeCountry.value], value);
  }
  _setAsyncByCountry(variableName, value, country?) {
    _.get(this, [variableName, country ? country : this.activeCountry.value]).next(value);
  }
  _getAsync(variableName) {
    return _.get(this, [variableName, this.activeTab.value, 'value']);
  }
  isMyFiles() {
    return this.activeTab.value === TabsName.MyFiles;
  }
  isMyDeletedFiles() {
    return this.activeTab.value === TabsName.DeletedFiles;
  }
  isSharedFiles() {
    return this.activeTab.value === TabsName.SharedFiles;
  }
}
