<div>
  <i [ngClass]="icon" aria-hidden="true"></i>
  <span>{{ label }}</span>
</div>
<ng-container *ngIf="type === 'dropdown'">
  <div #overlayOrigin="cdkOverlayOrigin" cdkOverlayOrigin></div>
  <mims-dropdown
    [width]="300"
    [overlayOrigin]="overlayOrigin"
    [opened]="dropdownOpen"
    [closeOnClickOutside]="false"
    (toggled)="onDropDownToggled($event)"
  >
    <ng-content></ng-content>
  </mims-dropdown>
</ng-container>
