<div class="list">
    <div class="list-container  d-flex flex-nowrap align-items-center justify-content-between">
        <div class="mx-0 px-0 ">
            <span class="px-4 ">{{ getName() }}</span>
        </div>
        <div class=" mx-0 px-0 ">
            <button (click)='handleJoin()' class="my-auto mx-2 btn-dark btn">
                join
            </button>
        </div>
    </div>
</div>