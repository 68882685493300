import { Component, Input, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { ProjectsService } from 'src/app/api/projects.service';
import { PermissionsHttpService } from 'src/app/shared/permissions-http-service/permissions-http.service';
import {DomSanitizer} from '@angular/platform-browser';
import * as _ from 'lodash';

@Component({
  selector: 'solution-doc',
  styleUrls: ['./solution.component.scss'],
  templateUrl: './solution.component.html',
})

export class SolutionDocComponent implements OnInit {
  @Input() solutionId?;
  canCreateProject = false
  solution = {};
  atlassianId = "";
  video = "";
  executeRoute = [];

  constructor(
    private router: Router, 
    private route: ActivatedRoute,
    private permissions: PermissionsHttpService,
    private projectsService: ProjectsService, 
    private sanitizer: DomSanitizer ) {
    this.permissions
    .checkPermission({
      permissions: {

        createProject:{}
      },
    })
    .subscribe((res) => {
      this.canCreateProject = res.createProject;
    });
  }


  ngOnInit() {
    if (!this.solutionId) _.set(this, 'solutionId', this.route.snapshot.paramMap.get('solutionId'));

    this.projectsService.getSolution(this.solutionId).subscribe((res) => {
      this.solution = res
      this.atlassianId = this.solution["spec"]["documentation"]["atlassian"]
      this.video = this.solution["spec"]["documentation"]["video"]
      this.executeRoute =  _.get(this.solution["spec"]["documentation"], 'route', []);
    });
  }

  getSolution() {
    return this.solution
  }

  getAtlassianID() {
    return this.atlassianId
  }

  getVideo() {
    return this.sanitizer.bypassSecurityTrustResourceUrl(this.video);
  }

  createProject() {
    this.router.navigate(this.executeRoute);
  }
}
