import { Component, Input, OnInit } from '@angular/core';
import { ArticleModel, CategoryModel, KnowledgeSearchService, SearchResultCategoriesDTO, SearchResultDTO, SemanticModel } from '../knowledge-search.service';
import { Formulation } from '../formulation';
import { ProjectsService, RawInputTypes } from 'src/app/api/projects.service';
import { LayoutOrganizationService } from 'src/app/api/layout-organization.service';
import { PagesPublicName } from 'src/app/shared/helpers/pagesPublicName';
import moment from 'moment';
import * as _ from 'lodash';
import { Observable } from 'rxjs';
import { DomSanitizer } from '@angular/platform-browser';
import { LocalService } from 'src/app/api/local-storage.service';
import { map, mergeMap, switchMap } from 'rxjs/operators';


@Component({
  selector: 'app-knowledge-search-results',
  templateUrl: './knowledge-search-results.component.html',
  styleUrls: ['./knowledge-search-results.component.scss'],
})
export class KnowledgeSearchResultsComponent implements OnInit {
  @Input() projectId?;
  page = 1;
  _ = _;
  pageSize = 5;
  Formulation = Formulation;
  PagesPublicName = PagesPublicName;
  moment = moment;
  inputId;
  url;
  txtFile;
  selectedCluster = 0;

  currentArticle$: Observable<ArticleModel> = null;
  semantics: SemanticModel[] = null;
  categories: CategoryModel[] = null;
  selectedSemantic: SemanticModel = null;
  journals: SearchResultDTO['top_journals'] = [];

  constructor(
    public knowledgeSearchService: KnowledgeSearchService,
    public projectsService: ProjectsService,
    public layout: LayoutOrganizationService,
    public _DomSanitizer: DomSanitizer,
    public localService: LocalService
  ) {}

  ngOnInit() {
    this.knowledgeSearchService.resetFilters();
    this.knowledgeSearchService.search();

    this.currentArticle$ = this.knowledgeSearchService.retrievedResult.pipe(
      mergeMap((res) => this.knowledgeSearchService.fetchArticle(res.semantics[0].article))
    );

    this.knowledgeSearchService.retrievedResult.subscribe((res) => {
      this.categories = mapCategoryDtoToModel(res?.categories);
      this.semantics = this.wrapWithEntityTags(res?.semantics || []);
      this.selectedSemantic = this.semantics.length ? this.semantics[0] : null;
      this.journals = res?.top_journals;
    });
  }
  formatArticleToTxt(article) {
    const title = article.title;
    const authors = this.stringifyAuthors(article);
    const journal = _.get(article, 'journal');
    const date = moment.unix(article.publication_date).format('YYYY MMM D');
    const type = article.type;
    const id = article.pmcid;
    const url = article.url;

    return title + '\n' + authors + '\n' + journal + '\n' + date + '\n' + type + '\n' + id + '\n' + url;
  }

  stringifyAuthors(article) {
    return article.authors.join(', ');
  }

  exportArticlesToText() {
    // get articles for selected triple
    const articles = _.get(this.knowledgeSearchService, 'retrievedResult.value[' + this.selectedCluster + '][0].articles');

    // transform articles to text
    let fileContent = '';
    articles.forEach((article) => {
      if (fileContent.length) {
        fileContent += '\n' + '\n';
      }
      fileContent += this.formatArticleToTxt(article);
    });

    // start text file download
    let element = document.createElement('a');
    element.setAttribute('href', `data:'text/plain';charset=utf-8,${encodeURIComponent(fileContent)}`);
    element.setAttribute('download', 'articles.txt');
    document.body.appendChild(element);
    element.click();
    document.body.removeChild(element);
  }

  keepOrder = (a, b) => {
    return a;
  };
  open = (value) => {
    window.open(value);
  };
  getRelevancyScore = (semantic: SemanticModel) => {
    return semantic.relevancy;
  };

  getArticleCountInCluster = (cluster) => {
    let articleArray = [];
    cluster.forEach((triple) => {
      triple.articles.forEach((article) => {
        articleArray.push(article.pmcid);
      });
    });
    articleArray = _.uniq(articleArray);
    return articleArray.length;
  };

  getArticlesLength = () => {
    let clusters = this.getClusters();
    let articleArray = [];
    clusters.forEach((cluster) => {
      cluster.forEach((triple) => {
        triple.articles.forEach((article) => {
          articleArray.push(article.pmcid);
        });
      });
    });
    articleArray = _.uniq(articleArray);
    return articleArray.length;
  };
  removeIntervention(array) {
    const result = _.cloneDeep(array);
    if (this.knowledgeSearchService.selectedFormulation === Formulation.Therapy) {
      const index = _.findIndex(result, (o) => {
        return o === 'intervention' || o === 'Intervention';
      });
      if (index > -1) {
        result.splice(index, 1);
      }
    }
    return result;
  }
  removeTripleArticle(array) {
    const result = _.cloneDeep(array);
    if (this.knowledgeSearchService.selectedFormulation === Formulation.Article) {
      const index = _.findIndex(result, (o) => {
        return o === 'article';
      });
      if (index > -1) {
        result.splice(index, 1);
      }
    }
    return result;
  }
  showFocus(semantic: SemanticModel) {
    let focusArray = semantic.search_focus;
    focusArray = this.removeIntervention(focusArray);
    focusArray = this.removeTripleArticle(focusArray);
    return _.toLower(_.join(focusArray, ', '));
  }
  getArticlesArray = (cluster) => {
    let articleArray = [];
    cluster.forEach((triple) => {
      triple.articles.forEach((article) => {
        articleArray.push(article.pmcid);
      });
    });
    return _.uniq(articleArray);
  };
  getTriplesArray = (article) => {
    return Object.values(article);
  };
  getFirstClusterArticle = (cluster) => {
    return _.first(cluster[0].articles);
  };
  getFirstClusterTriple = (cluster) => {
    return cluster[0];
  };
  getClusterTripleSubject = (cluster) => {
    return cluster[0].subject;
  };
  getClusterTriplePredicate = (cluster) => {
    return cluster[0].predicate;
  };
  getClusterTripleObject = (cluster) => {
    return cluster[0].object;
  };
  getClusters = () => {
    return _.get(this.knowledgeSearchService, 'retrievedResult.value', {});
  };
  getClusterLength = () => {
    return this.semantics?.length;
  };
  onPageChange = (page: number) => {
    const selected = this.semantics.slice((page - 1) * this.pageSize)[0];
    this.handleSemanticClick(selected);
  };
  save = () => {
    this.createProject();
  };
  createProjectTitle = () => {
    let title = 'Search | ' + this.knowledgeSearchService.getFormulationFullName(this.knowledgeSearchService.selectedFormulation) + ' - ';
    switch (this.knowledgeSearchService.selectedFormulation) {
      case Formulation.Keywords:
        title = title + this.knowledgeSearchService.getQueryParamAsString(this.knowledgeSearchService.getQueryParams());
        break;
      case Formulation.Article:
        title = title + this.knowledgeSearchService.getQueryParamAsString(this.knowledgeSearchService.getQueryParams());
        break;
      case Formulation.Therapy:
        title =
          title +
          'In [' +
          this.knowledgeSearchService.getQueryParams()['k0'] +
          '], what is the effet of [' +
          this.knowledgeSearchService.getQueryParams()['k1'] +
          '] on [' +
          this.knowledgeSearchService.getQueryParams()['k2'] +
          '] compared with [' +
          this.knowledgeSearchService.getQueryParams()['k3'] +
          '] ? ';
        break;
      case Formulation.Prevention:
        title =
          title +
          'For [' +
          this.knowledgeSearchService.getQueryParams()['k0'] +
          '], does the use of [' +
          this.knowledgeSearchService.getQueryParams()['k1'] +
          '] reduce the future risk of [' +
          this.knowledgeSearchService.getQueryParams()['k2'] +
          '] compared with [' +
          this.knowledgeSearchService.getQueryParams()['k3'] +
          '] ? ';
        break;
      case Formulation.Diagnosis:
        title =
          title +
          'Is [' +
          this.knowledgeSearchService.getQueryParams()['k0'] +
          '], more accurate in diagnosing [' +
          this.knowledgeSearchService.getQueryParams()['k1'] +
          '] compared with [' +
          this.knowledgeSearchService.getQueryParams()['k2'] +
          '] ? ';
        break;
      case Formulation.Prognosis:
        title =
          title +
          'Does [' +
          this.knowledgeSearchService.getQueryParams()['k0'] +
          '], influence [' +
          this.knowledgeSearchService.getQueryParams()['k1'] +
          '] in patients who have [' +
          this.knowledgeSearchService.getQueryParams()['k2'] +
          '] ? ';
        break;
      case Formulation.Etiology:
        title =
          title +
          'Are [' +
          this.knowledgeSearchService.getQueryParams()['k0'] +
          '], who have [' +
          this.knowledgeSearchService.getQueryParams()['k1'] +
          '] at [' +
          this.knowledgeSearchService.getQueryParams()['k2'] +
          '] risk for/of [' +
          this.knowledgeSearchService.getQueryParams()['k3'] +
          '] compared with [' +
          this.knowledgeSearchService.getQueryParams()['k4'] +
          '] with/without [' +
          this.knowledgeSearchService.getQueryParams()['k5'] +
          '] ? ';
        break;
      default:
        break;
    }

    return title;
  };
  success() {
    this.layout.toast('Query successfully saved', null, 8000, '', 'success');
  }
  failure() {
    this.layout.toast('Impossible to save the query', null, 8000, '', 'warning');
  }
  createProject = () => {
    let title = this.createProjectTitle();
    if (this.getProjectId(this.knowledgeSearchService.getQueryParams())) {
      let country = this.getProjectCountry(this.knowledgeSearchService.getQueryParams());
      this.updateQuery(this.getProjectId(this.knowledgeSearchService.getQueryParams()), country);
    } else {
      let country = this.localService.getFromLocalStorage('user', 'account_country');
      this.projectsService.createProject({ title, description: 'COVsight query', kind: 'research' }, country).subscribe(
        (res) => {
          this.setProjectId(res.id);
          this.setProjectCountry(country);
          this.addnewInput(res.id, [], country).subscribe(
            () => {
              this.layout.toast('New project created', null, 8000, '', 'success');
              this.success();
            },
            () => {
              this.failure();
            }
          );
        },
        () => this.failure()
      );
    }
  };
  setProjectId(id) {
    let newObject = _.set(_.clone(this.knowledgeSearchService.getQueryParams()), 'id', id);
    this.knowledgeSearchService.setQueryParams(newObject);
  }
  setProjectCountry(country) {
    let newObject = _.set(_.clone(this.knowledgeSearchService.getQueryParams()), 'country', country);
    this.knowledgeSearchService.setQueryParams(newObject);
  }
  getProjectId(queryParams) {
    return _.get(queryParams, 'id', undefined);
  }
  getProjectCountry(queryParams) {
    return _.get(queryParams, 'country', this.localService.getFromLocalStorage('user', 'account_country'));
  }
  retrieveInput(projectId, country?) {
    return new Observable((observer) => {
      this.projectsService.projectGet(projectId, country ? country : undefined).subscribe(
        (res) => {
          observer.next(
            _.find(res.input_list, (o) => {
              return o.value === 'query';
            })
          );
        },
        (error) => {
          observer.error(error);
        }
      );
    });
  }
  formatAuthor(author) {
    return _.replace(_.words(author, /[^,]+/g), ',', '');
  }
  getFocus(cluster) {
    return _.toLower(_.join(_.get(this.getFirstClusterTriple(cluster), 'search_focus'), ', '));
  }

  addnewInput(projectId, savedQueries, country) {
    return new Observable((observer) => {
      let newSavedQueries = savedQueries;
      let query = _.omit(this.knowledgeSearchService.getQueryParams(), 'id');
      query = _.omit(query, 'country');
      newSavedQueries.push({
        date: moment().unix(),
        formulation: this.knowledgeSearchService.selectedFormulation,
        query: query,
      });
      let tags = {
        savedQueries: newSavedQueries,
      };
      this.projectsService.appendInput(projectId, RawInputTypes.String, 'query', undefined, tags, country).subscribe(
        (res) => {
          observer.next(res.id);
        },
        (error) => {
          observer.error(error);
        }
      );
    });
  }
  isNew(date) {
    if (date > moment().unix() - 31 * 24 * 60 * 60) {
      return true;
    } else {
      return false;
    }
  }

  updateQuery(projectId, country) {
    this.retrieveInput(projectId, country).subscribe(
      (res) => {
        let formerInputId = _.get(res, 'id');
        this.projectsService.removeInput(projectId, formerInputId, country).subscribe(
          (res1) => {
            this.addnewInput(projectId, _.get(res, 'tags.savedQueries', []), country).subscribe(
              (res2) => {
                this.inputId = res2;
                this.success();
                this.projectsService.projectPatch(projectId, this.createProjectTitle(), 'title', country).subscribe();
              },
              (err2) => {
                this.failure();
              }
            );
          },
          (err1) => {
            this.failure();
          }
        );
      },
      (err) => {
        this.failure();
      }
    );
  }

  getCurrentArticle() {
    this.currentArticle$ = null;

    this.currentArticle$ = this.knowledgeSearchService.fetchArticle(this.selectedSemantic.article);
  }

  getUniqueArticleLength() {
    return [...new Set(this.semantics?.map(({ article }) => article) || [])].length;
  }

  handleSemanticClick(semantic: SemanticModel) {
    this.selectedSemantic = semantic;
    this.getCurrentArticle();
  }

  wrapWithEntityTags(semantics: SemanticModel[]) {
    return semantics.map((semantic) => ({
      ...semantic,
      sentence: semantic.linked_entities.reduce((sentence, entity) => {
        const color = this.knowledgeSearchService.colorForCategory(entity[1]);

        if (!color) {
          return sentence;
        }

        return sentence.replace(
          new RegExp('\\b' + entity[0].replace(/[-\/\\^$*+?.()|[\]{}]/g, '\\$&') + '\\b', 'gi'),
          `<span style="background-color:${color}4D;border-radius: 2px;padding: 1px;border: 1px solid rgba(0, 0, 0, 0.1);">${entity[0]}</span>`
        );
      }, semantic.sentence),
    }));
  }
}

const mapCategory = (id: string, categories: SearchResultCategoriesDTO['categories']) => {
  const category = categories[id];

  return {
    id: id,
    name: category.name,
    count: category.triple_count,
    children: category.children.reduce((acc, next) => acc.concat(mapCategory(next, categories)), []),
  };
};

const mapCategoryDtoToModel = (dto?: SearchResultCategoriesDTO): CategoryModel[] => {
  if (!dto) {
    return [];
  }

  return dto.roots.reduce((acc, next) => acc.concat(mapCategory(next, dto.categories)), []);
};

