import { EventEmitter, Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import * as _ from 'lodash';
import { LocalService } from 'src/app/api/local-storage.service';
import { LayoutOrganizationService } from 'src/app/api/layout-organization.service';
import { FileSystemState } from '../login/login-http-service/login-http.service';
import moment from 'moment';
import { TeamsHttpService } from './teams-http.service';

export enum TeamsActions {
  Retrieve,
}
export enum TeamActions {
  Rename,
  Delete,
  Manage,
  Exit,
  Open,
  Create,
  Retrieve,
  AttachSubscription,
}
export enum UserActions {
  Delete,
  Remove,
  Retrieve,
  ChangePermission,
  Add,
  AddNew,
  Activate,
  Deactivate,
}
export function formatSelectUser(res, selectedTeam) {
  let newRes = _.cloneDeep(res);
  newRes = _.omitBy(newRes, (value, key) => {
    return (
      _.includes(key, 'kind') ||
      _.includes(key, 'links') ||
      _.includes(key, 'first_name') ||
      _.includes(key, 'last_name') ||
      _.includes(key, 'file_hosting_country')
    );
  });
  newRes = _.omitBy(newRes, (value, key) => value === null || value === '');
  const getReadableValue = function (key, value) {
    if ((key === 'last_activity_time' || key === 'created_on') && _.isNumber(value)) {
      if (value === 0) {
        return 'Unknown date';
      }
      return moment(value * 1000).fromNow();
    }
    if (_.isBoolean(value)) {
      return value ? 'Yes' : 'No';
    }
    if (_.isObject(value)) {
      let newvalue = _.keysIn(value);
      newvalue = _.join(newvalue, ' ');
      return newvalue;
    }
    return value;
  };
  const getReadableKey = function (key) {
    if (key === 'external_user_ids') {
      key = 'external_storage';
    }
    let name = _.split(key, '_');
    name = _.join(name, ' ');
    return name;
  };
  Object.entries(newRes).forEach(([key, value]) => {
    let _key = key;
    let _value = value;
    delete newRes[key];
    _.set(newRes, getReadableKey(_key), getReadableValue(_key, _value));
    // _.set(newRes, 'role', _.get(res, 'role'));
  });
  if (_.includes(_.get(selectedTeam, 'owner_user_ids', []), newRes.id)) {
    _.set(newRes, 'role', 'Team manager');
  } else {
    _.set(newRes, 'role', 'Member');
  }
  return newRes;
}
export function formatTeam(res) {
  let newRes = _.cloneDeep(res);
  _.set(newRes, 'team.users', []);
  _.get(newRes, 'team.user_ids').forEach((element, index) => {
    let user = {};
    _.set(user, 'id', element);
    _.set(user, 'email', _.nth(_.get(newRes, 'user_emails', []), index));
    if (_.includes(_.get(newRes, 'team.owner_user_ids', []), element)) {
      _.set(user, 'role', 'Team manager');
    } else {
      _.set(user, 'role', 'Member');
    }
    newRes.team.users.push(user);
  });
  return newRes.team;
}
@Injectable()
export class TeamsService {
  selectedUser = new BehaviorSubject<any>(undefined);
  formattedSelectedUser = new BehaviorSubject<any>(undefined);

  selectedTeam = undefined;
  formattedSelectedTeam = undefined;

  countryDataSource;
  selectedCountry;

  teams = new BehaviorSubject<any>(undefined);

  teamsEvent: EventEmitter<any> = new EventEmitter();
  teamEvent: EventEmitter<any> = new EventEmitter();

  constructor(private localService: LocalService, private layout: LayoutOrganizationService, private teamHttpService: TeamsHttpService) {
    this.teamsEvent.subscribe((event) => {
      this.handleTeamsEvent(event);
    });
    this.teamEvent.subscribe((event) => {
      this.handleTeamEvent(event);
    });
  }

  // MANAGER EVENT
  handleTeamsEvent = (event) => {
    let action = _.get(event, 'action');
    switch (action) {
      case TeamsActions.Retrieve:
        this.retrieveTeams();
        break;
      default:
        break;
    }
  };
  resetSelectedTeam=() =>{
    this.selectedTeam = undefined;
  }
  retrieveTeams = (country?) => {
    return new Promise((resolve) => {
      return this.teamHttpService.getTeams(country? country : this.selectedCountry.value).subscribe(
        (res) => {
          this.teams.next(res);
          resolve(res);
        },
        () => {
          this.layout.toast(
            '<b>Error</b> while retrieving the list of teams from <b>' + country ? country.label : this.selectedCountry.label + '</b>',
            null,
            8000,
            '',
            'danger'
          );
        }
      );
    });
  };
  navigateToTeams() {
    this.selectedTeam = undefined;
    this.formattedSelectedTeam = undefined;
    this.teams.next(undefined);
    this.teamsEvent.emit({ action: TeamsActions.Retrieve });
  }
  setCountry(country) {
    this.selectedTeam = undefined;
    this.formattedSelectedTeam = undefined;
    this.selectedCountry = country;
    this.teams.next(undefined);
    this.teamsEvent.emit({ action: TeamsActions.Retrieve });
  }
  initCountryDataSource() {
    this.countryDataSource = _.reject(this.localService.getFromLocalStorage('user', 'file_systems'), (o) => {
      return o.accessState === FileSystemState.Disabled;
    }).map((el) => {
      let _el = {};
      _.set(_el, 'label', el.name);
      _.set(_el, 'value', el.value);
      return _el;
    });
    this.selectedCountry = _.nth(this.countryDataSource, 0);
  }
  initSelectedCountry(value?) {
    if (!value) this.selectedCountry = _.nth(this.countryDataSource, 0);
    else
      this.selectedCountry = _.find(this.countryDataSource, (o) => {
        return o.value === value;
      });
  }
  canCreateTeam() {
    return _.isEqual(this.selectedCountry, _.nth(this.countryDataSource, 0));
  }

  // TEAM EVENT
  handleTeamEvent = (event) => {
    let team = _.get(event, 'team');
    let action = _.get(event, 'action');
    let id = _.get(event, 'id');
    let value = _.get(event, 'value');
    let user = _.get(event, 'user');

    switch (action) {
      case TeamActions.Retrieve:
        this.handleRetrieveTeam(id);
        break;
      case TeamActions.AttachSubscription:
        this.handleChangeSubscription(team, value).then(() => {
          this.teamsEvent.emit({ action: TeamsActions.Retrieve });
        });
        break;
      case TeamActions.Open:
        this.selectedTeam = undefined
        this.formattedSelectedTeam = undefined;
        this.handleRetrieveTeam(id);
        break;
      case TeamActions.Create:
        this.handleNewTeam().then(() => {
          this.teamsEvent.emit({ action: TeamsActions.Retrieve });
        });
        break;
      case TeamActions.Rename:
        this.handleRename(team, value).then(() => {
          this.teamsEvent.emit({ action: TeamsActions.Retrieve });
        });
        break;
      case TeamActions.Delete:
        this.handleDelete(team).then(() => {
          this.teamsEvent.emit({ action: TeamsActions.Retrieve });
        });
        break;
      case TeamActions.Exit:
        this.navigateToTeams();
        break;
      case TeamActions.Manage:
        this.handleManage(team);
        break;
      default:
        break;
    }
  };

  handleNewTeam = () => {
    return new Promise((resolve) => {
      this.teamHttpService.createTeam(this.selectedCountry.value).subscribe(
        () => {
          this.layout.toast('New team successfully <b> created </b>', null, 5000, '', 'success');
          resolve(true);
        },
        () => {
          this.layout.toast('<b>Error</b> while creating a team', null, 8000, '', 'danger');
        }
      );
    });
  };
  handleRename = (team, value) => {
    return new Promise((resolve) => {
      _.set(team, 'name', value);
      return this.teamHttpService.updateTeam(_.get(team, '_key'), team, this.selectedCountry.value).subscribe(
        () => {
          resolve(true);
        },
        (error) => {
          this.layout.toast('<b>Error</b> while renaming the teams  <b>' + _.get(team, 'name') + '</b>', null, 8000, '', 'danger');
        }
      );
    });
  };
  handleDelete = (team) => {
    return new Promise((resolve) => {
      let name = _.get(team, 'name', '');
      let text = 'Team <b>"' + name + '"</b>  will be deleted. Are you sure you want to do this ?';
      return this.layout.customConfirm(text, () => {
        this.teamHttpService.deleteTeam(_.get(team, '_key', ''), this.selectedCountry.value).subscribe(
          () => {
            this.layout.toast('Team <b>"' + name + '"</b> successfully <b>deleted</b>', null, 5000, null, 'success');
            resolve(true);
          },
          () => {
            this.layout.toast('<b>Error</b> while deleting the team  <b>"' + name + '"</b>', null, 8000, null, 'danger');
          }
        );
      });
    });
  };
  handleManage = (team) => {
    this.selectedTeam = team;
    this.layout.open('modalManageSubscription');
  };
  hasSubscription = (team) => {
    return _.get(team, 'subscription_id.length', 0) > 0;
  };

  handleChangeSubscription = (team, value) => {
    return new Promise((resolve) => {
      let newTeam = _.cloneDeep(team);
      _.set(newTeam, 'subscription_id', value);
      return this.teamHttpService.updateTeam(_.get(newTeam, '_key'), newTeam, this.selectedCountry.value).subscribe(
        () => {
          resolve(true);
        },
        () => {
          this.layout.toast('<b>Error</b> while <b>updating</b> the subscription', null, 8000, '', 'danger');
        }
      );
    });
  };

  handleRetrieveTeam(id) {
    return this.teamHttpService.getTeam2(id, this.selectedCountry.value).subscribe((res) => {
      this.selectedTeam = res.team;
      this.formattedSelectedTeam = formatTeam(res);
    });
  }

  // USER EVENT
  handleUserEvent = (event) => {
    let team = _.get(event, 'team');
    let action = _.get(event, 'action');
    let value = _.get(event, 'value');
    let user = _.get(event, 'user');
    let id = _.get(event, 'id');

    switch (action) {
      case UserActions.Remove:
        this.handleRemoveUserById(team, id).then(() => {
          this.handleTeamEvent({ id: team._key, action: TeamActions.Retrieve });
        });
        break;
      case UserActions.ChangePermission:
        this.handleChangePermission(user, team, value).then(() => {
          this.handleTeamEvent({ id: team._key, action: TeamActions.Retrieve });
          this.handleUserEvent({ id: user.id, action: UserActions.Retrieve });
        });
        break;
      case UserActions.Deactivate:
        this.handleDeactivateUser(user).then(() => {
          this.handleTeamEvent({ id: team._key, action: TeamActions.Retrieve });
        });
        break;
      case UserActions.Activate:
        this.handleActivateUser(user);
        break;
      case UserActions.Add:
        this.handleAddUserById(team, id).then(() => {
          this.handleTeamEvent({ id: team._key, action: TeamActions.Retrieve });
        });
        break;
      case UserActions.AddNew:
        this.handleInvitation(team, value).then(() => {
          this.handleTeamEvent({ id: team._key, action: TeamActions.Retrieve });
        });
        break;
      case UserActions.Retrieve:
        this.teamHttpService.getUser(id, this.selectedCountry.value).subscribe((res) => {
          this.selectedUser.next(res);
          this.formattedSelectedUser.next(formatSelectUser(res, this.selectedTeam));
          this.layout.open('modalUserCard');
        });
        break;
      default:
        break;
    }
  };
  handleInvitation = (team, value) => {
    return new Promise((resolve) => {
      this.layout.open('modalNewMemberAddition');
      let body = value;
      let country = _.get(body, 'file_hosting_country.value');
      _.set(body, 'file_hosting_country', country);
      _.set(body, 'ontological_domain', 'Human');
      this.teamHttpService.submitUser(body, country).subscribe(
        (res) => {
          this.layout.toast('A invitation to join Mimsomic has been sucessfully sent to ' + body.email, null, 5000, '', 'success');
          let newUserId = _.get(res, 'user_id');
          this.layout.close();
          this.handleAddUserById(team, newUserId).then(() => {
            resolve(true);
          });
        },
        (error) => {
          if (_.get(error, 'error.message')  === 'The server encountered a internal error, please contact staff') {
            this.layout.toast('Internal error. Impossible to send invitation to <b>' + body.email + '</b>', null, 5000, '', 'danger');
          } else if (_.get(error, 'error.message')  === 'One account with that e-mail address already exists') {
            this.layout.toast('One account with the email address <b>' + body.email + '</b> already exists', null, 5000, '', 'warning');
          } else {
            this.layout.toast(_.get(error, 'error.message') , null, 5000, '', 'danger');
          }
          this.layout.close();
        }
      );
    });
  };
  handleAddUserById = (team, memberId) => {
    return new Promise((resolve) => {
      if (!_.includes(_.get(team, 'user_ids', []), memberId)) {
        let newBody = _.cloneDeep(team);
        newBody.user_ids.push(memberId);
        return this.teamHttpService.updateTeam(team._key, newBody, this.selectedCountry.value).subscribe(
          () => {
            this.layout.toast('Member <b>successfully added</b> to the team', null, 5000, '', 'success');
            resolve(true);
          },
          (error) => {
            this.layout.toast(_.get(error, 'error.message') , null, 5000, '', 'danger');
          }
        );
      } else {
        this.layout.toast('Member <b>already part</b> of the team', null, 5000, '', 'warning');
      }
    });
  };
  handleRemoveUserById = (team, memberId) => {
    return new Promise((resolve) => {
      let newBody = _.cloneDeep(team);
      let usersIds = _.get(newBody, 'user_ids', []);
      let ownerUserIds = _.get(newBody, 'owner_user_ids', []);
      if (_.includes(usersIds, memberId)) {
        usersIds = _.without(usersIds, memberId);
        _.set(newBody, 'user_ids', usersIds);
        ownerUserIds = _.without(ownerUserIds, memberId);
        _.set(newBody, 'owner_user_ids', ownerUserIds);

        return this.teamHttpService.updateTeam(team._key, newBody, this.selectedCountry.value).subscribe(
          () => {
            this.layout.toast('Member <b>successfully added</b> to the team', null, 5000, '', 'success');
            resolve(true);
          },
          (error) => {
            this.layout.toast(_.get(error, 'error.message') , null, 5000, '', 'danger');
          }
        );
      } else {
        this.layout.toast('User <b>already removed from the team</b>', null, 5000, '', 'danger');
      }
    });
  };
  handleChangePermission(user, team, role) {
    return new Promise((resolve) => {
      let newTeam = _.cloneDeep(team);
      let newArray = _.get(newTeam, 'owner_user_ids');
      if (role === 'Team Manager' && !newArray.includes(user.id)) {
        newArray.push(user.id);
      } else if (role === 'Member' && newArray.includes(user.id)) {
        newArray = _.without(newArray, user.id);
      }
      _.set(newTeam, 'owner_user_ids', newArray);
      return this.teamHttpService.updateTeam(_.get(newTeam, '_key'), newTeam, this.selectedCountry.value).subscribe(
        () => {
          this.layout.toast('Role successfully updated', null, 8000, '', 'success');
          resolve(true);
        },
        (error) => {
          this.layout.toast(_.get(error, 'error.message') , null, 5000, '', 'danger');
        }
      );
    });
  }
  handleDeactivateUser(user) {
    return new Promise((resolve) => {
      return this.teamHttpService.deactivateUser(user.id, this.selectedCountry.value).subscribe(
        (res) => {
          this.layout.toast('User successfully deactivated', null, 8000, '', 'success');
          resolve(true);
        },
        (error) => {
          this.layout.toast(_.get(error, 'error.message') , null, 5000, '', 'danger');
        }
      );
    });
  }
  handleActivateUser(user) {
    return new Promise((resolve) => {
      return this.teamHttpService.activateUser(user.id, this.selectedCountry.value).subscribe(
        (res) => {
          this.layout.toast('User successfully activated', null, 8000, '', 'success');
          resolve(true);
        },
        (error) => {
          this.layout.toast(_.get(error, 'error.message') , null, 5000, '', 'danger');
        }
      );
    });
  }
}
